import _ from "lodash";

// ENV name variables
export const DEVELOPMENT_ENV = "development";
export const TEST_ENV = "test";
export const STAGING_ENV = "staging";
export const PRODUCTION_ENV = "production";

/**
 * EXTERNAL CONFIG
 * This configuration can be overwritten using the config.json files.
 */
export let API = {
  BASE_URL: undefined,
  OAUTH_GRANT_PASSWORD: "/oauth",
  FIRST_AUTH_TOKEN: "Basic ZnJvbnRlbmQ6ZnJvbnRlbmQ=",
  OAUTH_HYBRID: "/hybridauth",
  OAUTH_GOOGLE_POPUP: "/hybridauth/login/Google",
  OAUTH_GRAPH_POPUP: "/hybridauth/login/MicrosoftGraph",
  MYSELF: "/myself",
  USER_MANAGER: "/user-manager",
  PASSWORD_RECOVERY: "/password-recovery",
  ROLES: "/role",
  PERMISSIONS: "/permission",
  RESOURCES: "/resource",
  USERS: "/user",
  CUSTOMER: "/",
  ANNOUNCEMENT: "/announcement",
  GEOGRAPHICAL_REFERENCE: "/geographical-reference",
  ECONOMIC_ACTIVITY: "/economic-activity",
  COMPANY_SIZE: "/company-size",
  TOPIC: "/topic",
  FUNDING_TYPE: "/funding-type",
  APPLICANT_COMPANY_FORM: "/applicant-company-form",
  ELIGIBLE_COST: "/eligible-cost",
  PROMOTING_INSTITUTION: "/promoting-institution",
  ATTACHMENT_MANAGER: "/attachment-manager",
  USER_REGISTRATION: "/user-registration",
  ACTIVATE_USER: "/activate-user",
  UPDATE_LIST: "/update-list",
  FILTER_TOOLTIPS: "/filter-tooltips",
  TENANT: "/tenant",
  CONTACT: "/contact-form",
  EXPORT_ANNOUNCEMENT_REPORT: "/export-announcement-report",
  EXPORT_ANNOUNCEMENT_PDF: "/export-announcement-pdf",
  FILTER_ANNOUNCEMENT: "/filter-announcement",
  OAUTH_BVS: "/hybridauth/callbackUrl/Bvs",
};

let loaded = false;

/**
 * This function expose a setter in order to config the application in the very early phase.
 * (after change it the application in auto-restarted)
 */
export const setConfig: (cfg: Record<string, unknown>) => void = (config) => {
  API = _.merge(API, config);
  loaded = true;
};

export const isLoaded: () => boolean = () => loaded;
