import React, { useEffect, useState } from "react";
import { Input } from "formik-semantic-ui-react";
import CurrencyInput from "react-currency-input-field";
import { ErrorMessage } from "formik";
import { CurrencyInputProps } from "./types";

export const CurrencyInputFormik: React.FC<CurrencyInputProps> = ({
  field,
  setFieldValue = undefined,
  placeholder = undefined,
  label = undefined,
  readOnly = false,
  prefix = "€ ",
  onChange = undefined,
  onBlur = undefined,
  decimalsLimit = 2,
  disabled = false,
  className = undefined,
  updateState,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [value, setValue] = useState(field.value);

  /**
   * Update the local value when the field value is changed from outside the component.
   */
  useEffect(() => {
    setValue(field.value);
  }, [field.value]);

  return (
    <div className={className}>
      <Input name={field.name} label={label}>
        <CurrencyInput
          className="currency_input_formik"
          name={field.name}
          placeholder={placeholder}
          readOnly={readOnly}
          allowDecimals={true}
          decimalsLimit={decimalsLimit}
          prefix={prefix}
          value={field.value ? field.value : field.value == "0" ? "0" : ""}
          onValueChange={(value, name, values) => {
            setValue(values?.value || "");
            setFieldValue &&
              setFieldValue(
                field.name,
                values?.float ? values.float : values?.float === 0 ? 0 : null
              );
            onChange && onChange(value || "", name, values);
            if (updateState)
              updateState(
                values?.float ? values.float : values?.float === 0 ? 0 : null,
                field.name
              );
          }}
          onBlur={(event) => onBlur && onBlur(event)}
          disabled={disabled}
          onKeyPress={(event) => {
            if (event.code === "Enter") {
              const element = document.querySelector(
                `[name="${field.name}"]`
              ) as HTMLElement;
              if (element) element.blur();
              event.preventDefault();
            }
          }}
        />
      </Input>
      <ErrorMessage name={field.name} component="span" />
    </div>
  );
};
