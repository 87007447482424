import { UserAction, UserActionConstants } from "pages/User/reducer/actions";
import { RoleResponse, User } from "services/user/types";

export type UserProfileState = {
  isLoading: boolean;
  user: User;
  roles: Array<RoleResponse> | [];
};

export const initialUserProfileState: UserProfileState = {
  isLoading: false,
  user: {
    id: null,
    displayName: null,
    firstName: null,
    lastName: null,
    email: null,
    role: null,
    username: null,
    tenant: null,
    isBlocked: undefined,
    active: undefined,
  },
  roles: [],
};

export function userProfileReducer(
  state: UserProfileState,
  action: UserAction
): UserProfileState {
  const { type } = action;

  switch (type) {
    case UserActionConstants.SET_USER:
      return { ...state, user: action.payload.user };
    case UserActionConstants.SET_ROLE_LIST:
      return { ...state, roles: action.payload.roles };
    case UserActionConstants.SET_USER_ACTIVE:
      return {
        ...state,
        user: {
          ...state.user,
          active: action.payload.userActive,
        },
      };
    case UserActionConstants.SET_USER_BLOCKED:
      return {
        ...state,
        user: {
          ...state.user,
          isBlocked: action.payload.userBlocked,
        },
      };
    default:
      return state;
  }
}
