import React, { useState, useEffect } from "react";
import { FileThumbTypes, FileTypes } from "./types";
import { Icon } from "semantic-ui-react";
import { AttachmentTypes } from "pages/Announcement/types";
import { useFormikContext } from "formik";
import { AnnouncementValuesTypes } from "pages/Announcement/types";
import { deleteAttachment } from "services/attachments";
import { useConfirm } from "material-ui-confirm";
import { toast } from "react-toastify";

export const FileThumb: React.FC<FileThumbTypes> = ({
  file,
  index,
  startDownload,
  fileFile,
}) => {
  const [fileType, setFileType] = useState<boolean>();
  const { values, setFieldValue } = useFormikContext<AnnouncementValuesTypes>();
  const confirm = useConfirm();

  useEffect(() => {
    setFileType(isFileType(file));
  }, []);

  const isFileType = (file: FileTypes | AttachmentTypes): file is FileTypes => {
    return (file as FileTypes).name !== undefined;
  };

  const formatBytes = (bytes: number | undefined) => {
    return bytes ? Math.round(bytes / 1024).toFixed(0) + " kb" : null;
  };

  const previewFile = (action: string) => {
    if (fileType && fileFile) {
      fileFile.arrayBuffer().then((arrayBuffer) => {
        const blob = new Blob([new Uint8Array(arrayBuffer)], {
          type: file.type,
        });
        const fileUrl = window.URL.createObjectURL(blob);
        const tempLink = document.createElement("a");
        tempLink.href = fileUrl;
        if (action === "download")
          tempLink.setAttribute("download", fileFile.name);
        if (action === "preview") tempLink.setAttribute("target", "_blank");
        tempLink.click();
        let link;
        if (file.filename)
          link = document.querySelector(`[href="${tempLink}"]`);
        link ? link.remove() : null;
      });
    }
  };

  const confirmThenDeleteAttachment = () => {
    confirm({
      description:
        "Questa azione comporta l'eliminazione definitiva dell'allegato.",
      title: "Sei sicuro?",
      confirmationText: "Conferma",
      cancellationText: "Cancella",
      confirmationButtonProps: { className: "button--confirm yes" },
      cancellationButtonProps: { className: "button--confirm no" },
    })
      .then(() => {
        if (file.id) {
          deleteAttachment(file.id).then(() => {
            const attachments = values.attachments;
            if (file.id) {
              const index = attachments.findIndex((a) => a.id === file.id);
              if (index || index === 0) attachments.splice(index, 1);
              setFieldValue("attachments", attachments);
            }
          });
        }
      })
      .catch(() => {
        toast.error("Errore durante la rimozione dell'allegato");
      });
  };

  return (
    <>
      {fileType && (
        <div className="upload__item">
          <div className="upload__left">
            <Icon name="file alternate" />
            <h4 className="file__name">{file.name}</h4>
          </div>
          <div className="upload__right">
            <div>{formatBytes(file.size)}</div>
            {file.type?.indexOf("xls") === -1 &&
              file.type?.indexOf("doc") === -1 &&
              file.type?.indexOf("ppt") !== -1 && (
                <div
                  className="upload__preview"
                  onClick={() => {
                    previewFile("preview");
                  }}
                >
                  <Icon name="external alternate" />
                </div>
              )}
            {(file.type?.indexOf("xls") !== -1 ||
              file.type?.indexOf("doc") !== -1) && (
              <div
                className="upload__download"
                onClick={() => {
                  previewFile("download");
                }}
              >
                <Icon name="download" />
              </div>
            )}
            <div
              className="upload__delete"
              onClick={() => {
                if (index || index === 0) {
                  const files = values?.attachmentsToUpload;
                  files.splice(index, 1);
                  setFieldValue
                    ? setFieldValue("attachmentToUpdate", files)
                    : null;
                }
              }}
            ></div>
          </div>
        </div>
      )}
      {!fileType && (
        <div className="upload__item">
          <div className="upload__left">
            <Icon name="file alternate" />
            <h4 className="file__name">{file.filename}</h4>
          </div>
          <div className="upload__right">
            {file.extension?.indexOf("xls") === -1 &&
              file.extension?.indexOf("doc") === -1 && (
                <div
                  className="upload__remove"
                  onClick={() => {
                    if (startDownload && file.id)
                      startDownload(file.id, "preview");
                  }}
                >
                  <Icon name="external alternate" />
                </div>
              )}
            <div
              className="upload__remove"
              onClick={() => {
                if (startDownload && file.id)
                  startDownload(file.id, "download");
              }}
            >
              <Icon name="download" />
            </div>
            <div
              className="upload__remove"
              onClick={confirmThenDeleteAttachment}
            >
              Rimuovi
            </div>
          </div>
        </div>
      )}
    </>
  );
};
