import React, { useContext, useEffect, useReducer } from "react";
import { Field, Form, Input, SubmitButton } from "formik-semantic-ui-react";
import { ErrorMessage, Formik } from "formik";
import {
  Button,
  Grid,
  Header,
  Icon,
  Select,
  Checkbox,
} from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import * as Yup from "yup";
import { useConfirm } from "material-ui-confirm";

// Functions
import { focusOnError } from "utils/function/focusOnError";

// Types
import { UserProfileFormProps } from "./types";
import { UserPageActionConstants } from "pages/User/reducer/actions";
import { FE_ROUTES } from "utils/global/globalCostants";
import { useNavigate } from "react-router-dom";
import { ROLES } from "utils/global/globalCostants";
import { getRolesList, getApplicationTenenatList } from "services/user";
import { initialUserState, userReducer } from "pages/User/reducer/reducer";
import { userPasswordRecovery } from "services/passwordRecovery";
import { isThatRole } from "utils/function/acl";
import { AppContext } from "pages/App";
import { User } from "services/user/types";
import { patchUser } from "services/user";

const UserProfileForm: React.FC<UserProfileFormProps> = ({
  onSubmit,
  initialValues,
  mode,
  userId,
}) => {
  const [userState, userDispatch] = useReducer(userReducer, initialUserState);
  const { state: appState } = useContext(AppContext);
  useEffect(() => {
    getRolesList(userDispatch);
    if (
      isThatRole(ROLES.ADMIN, appState) ||
      isThatRole(ROLES.SUPERVISOR, appState)
    ) {
      getApplicationTenenatList(userDispatch);
    }
  }, []);

  const confirm = useConfirm();

  /**
   * The form validation schema.
   */
  const tenantValidation = (isThatRole(ROLES.ADMIN, appState) ||
    isThatRole(ROLES.SUPERVISOR, appState)) && {
    tenant: Yup.object()
      .typeError("Società è un campo obbligatorio")
      .required("Società è un campo obbligatorio")
      .label("Società"),
  };

  const validationSchema = Yup.object().shape({
    ...tenantValidation,
    firstName: Yup.string()
      .typeError("Nome è un campo obbligatorio")
      .required("Nome è un campo obbligatorio")
      .label("Nome"),
    lastName: Yup.string()
      .typeError("Cognome è un campo obbligatorio")
      .required("Cognome è un campo obbligatorio")
      .label("Cognome"),
    email: Yup.string()
      .typeError("E-mail è un campo obbligatorio")
      .required("E-mail è un campo obbligatorio")
      .label("Email"),
    role: Yup.object()
      .typeError("Ruolo è un campo obbligatorio")
      .required("Ruolo è un campo obbligatorio")
      .label("Ruolo"),
  });

  /**
   * The hook to navigate thorught the application.
   */
  const navigate = useNavigate();

  /**
   * Method used to manage the edit action for the current user.
   */
  const onEdit = () => {
    navigate(`${FE_ROUTES.UTENTI}/${UserPageActionConstants.MODIFY}/${userId}`);
  };

  const handleResetPassword = (values) => {
    confirm({
      description:
        "Sei sicuro di voler resettare la password per questo utente?",
      title: "Invio email reset password",
      confirmationText: "Resetta",
      cancellationText: "Annulla",
      confirmationButtonProps: { className: "button--confirm yes" },
      cancellationButtonProps: { className: "button--confirm no" },
    }).then(() => userPasswordRecovery(values, userDispatch));
  };

  const confirmBlockUser = (
    values: User,
    fieldName: string,
    toCheckOrNotToCheck: boolean | undefined,
    setFieldValue: (
      field: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any,
      shouldValidate?: boolean | undefined
    ) => void,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    action: string
  ) => {
    let confirmPayload = {};
    if (action === "block") {
      confirmPayload = {
        description: `Stai per eliminare ${values.firstName} ${values.lastName}. Vuoi proseguire?`,
        title: "Conferma eliminazione utente",
        confirmationText: "Conferma",
        cancellationText: "Cancella",
        confirmationButtonProps: { className: "button--confirm yes" },
        cancellationButtonProps: { className: "button--confirm no" },
      };
    }
    if (action === "activate") {
      confirmPayload = {
        description: `Stai per riattivare ${values.firstName} ${values.lastName}. Vuoi proseguire?`,
        title: "Conferma riattivazione utente",
        confirmationText: "Conferma",
        cancellationText: "Cancella",
        confirmationButtonProps: { className: "button--confirm yes" },
        cancellationButtonProps: { className: "button--confirm no" },
      };
    }
    confirm(confirmPayload)
      .then(() => {
        setFieldValue(fieldName, toCheckOrNotToCheck, true);
        const data = { isBlocked: toCheckOrNotToCheck };
        if (userId) patchUser(data, userId, true, userDispatch);
      })
      .catch(() => {
        /* ... */
      });
  };

  const handleSubmit = (validateForm) => {
    validateForm().then((errs) => {
      if (errs && Object.keys(errs).length > 0) {
        focusOnError(errs);
      }
    });
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue, validateForm, setFieldTouched }) => (
          <>
            <Form
              id="userProfileForm"
              className={
                mode === UserPageActionConstants.VIEW ? "view" : undefined
              }
            >
              <Grid padded>
                <Grid.Row>
                  <Grid.Column>
                    <Header as="h2">
                      <FormattedMessage
                        id="profile.userData"
                        defaultMessage="Dati utente banca"
                      />
                    </Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <Input
                      name="firstName"
                      id="firstName"
                      placeholder="Nome"
                      readOnly={mode === UserPageActionConstants.VIEW}
                      label={
                        <label>
                          <FormattedMessage
                            id="user.firstName"
                            defaultMessage="Nome"
                          />
                          *
                        </label>
                      }
                    />
                    <ErrorMessage name="firstName" component="span" />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Input
                      name="lastName"
                      id="lastName"
                      placeholder="Cognome"
                      readOnly={mode === UserPageActionConstants.VIEW}
                      label={
                        <label>
                          <FormattedMessage
                            id="user.lastName"
                            defaultMessage="Cognome"
                          />
                          *
                        </label>
                      }
                    />
                    <ErrorMessage name="lastName" component="span" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <Input
                      name="email"
                      id="email"
                      placeholder="E-mail"
                      readOnly={mode === UserPageActionConstants.VIEW}
                      label={
                        <label>
                          <>
                            <FormattedMessage
                              id="profile.email"
                              defaultMessage="E-mail"
                            />
                          </>
                          *
                        </label>
                      }
                    />
                    <ErrorMessage name="email" component="span" />
                  </Grid.Column>
                  {(isThatRole(ROLES.ADMIN, appState) ||
                    isThatRole(ROLES.SUPERVISOR, appState)) && (
                    <Grid.Column width={8}>
                      <Field name="tenant">
                        {({ field, meta: { touched, error } }) => (
                          <div className="field">
                            <label
                              className={
                                error && touched ? "invalid-field-label" : ""
                              }
                            >
                              <FormattedMessage
                                id="user.business"
                                defaultMessage="Società"
                              />
                              *
                            </label>
                            <Select
                              name={field.name}
                              id="tenant"
                              options={userState.applicationTenantOptions}
                              className={
                                error && touched ? "invalid-field" : undefined
                              }
                              onBlur={() => setFieldTouched(field.name)}
                              placeholder="Società"
                              search={true}
                              loading={userState.isLoadingApplicationTenant}
                              disabled={
                                userState.isLoadingApplicationTenant ||
                                (!isThatRole(ROLES.SUPERVISOR, appState) &&
                                  !isThatRole(ROLES.ADMIN, appState)) ||
                                mode === UserPageActionConstants.VIEW
                              }
                              onChange={(event, data) => {
                                setFieldValue(
                                  field.name,
                                  userState.applicationTenantOptions.find(
                                    (val) => val.value === data.value
                                  )
                                );
                              }}
                              value={values.tenant?.value}
                              noResultsMessage={"Nessun risultato trovato"}
                            />
                          </div>
                        )}
                      </Field>
                      <ErrorMessage
                        name="tenant"
                        component="span"
                        className="invalid-field-error"
                      />
                    </Grid.Column>
                  )}
                </Grid.Row>
                {(mode === UserPageActionConstants.MODIFY ||
                  mode === UserPageActionConstants.VIEW) && (
                  <Grid.Column width={8}>
                    <Button
                      type="button"
                      onClick={() => handleResetPassword(values)}
                      disabled={
                        mode === UserPageActionConstants.VIEW ||
                        initialValues.isBlocked
                      }
                      loading={userState.isResetPasswordLoading}
                    >
                      <div>
                        <FormattedMessage
                          id="profile.resetPassword"
                          defaultMessage="Resetta password"
                        />
                      </div>
                    </Button>
                  </Grid.Column>
                )}
                {mode !== UserPageActionConstants.NEW && (
                  <Grid.Row>
                    <Grid.Column>
                      <Field name="isBlocked">
                        {({ field }) => (
                          <>
                            <Checkbox
                              disabled={
                                mode === UserPageActionConstants.VIEW ||
                                !initialValues.active
                              }
                              toggle
                              name="isBlocked"
                              label={
                                initialValues.active
                                  ? values.isBlocked
                                    ? "Utente disattivato"
                                    : "Utente attivo"
                                  : "Utente non registrato / password errata"
                              }
                              checked={values.isBlocked}
                              onChange={(event, data) => {
                                const action = data.checked
                                  ? "block"
                                  : "activate";
                                confirmBlockUser(
                                  values,
                                  field.name,
                                  data.checked,
                                  setFieldValue,
                                  action
                                );
                              }}
                              className={
                                !initialValues.active ? "inactive" : undefined
                              }
                            />
                          </>
                        )}
                      </Field>
                    </Grid.Column>
                  </Grid.Row>
                )}
                <Grid.Row>
                  <Grid.Column>
                    <Header as="h3">
                      <FormattedMessage
                        id="user.role"
                        defaultMessage="Ruolo utente"
                      />
                    </Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Button.Group
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                        columnGap: "2rem",
                        rowGap: "2rem",
                      }}
                      className={mode === "create" ? "create" : "modify"}
                    >
                      <Button
                        type="button"
                        primary={values.role?.roleId === ROLES.BANK_OPERATOR}
                        checked={values.role?.roleId === ROLES.BANK_OPERATOR}
                        onClick={() => {
                          const roleObj = userState.roles.find(
                            (val) => val.roleId === ROLES.BANK_OPERATOR
                          );
                          setFieldValue("role", roleObj);
                        }}
                        className={`button--grid ${
                          isThatRole(ROLES.BANK_ADMIN) || values.tenant
                            ? "active"
                            : undefined
                        }`}
                      >
                        <Icon name="id badge" />
                        <FormattedMessage
                          id="user.bank_operator"
                          defaultMessage="Operatore"
                        />
                      </Button>
                      <Button
                        type="button"
                        primary={values.role?.roleId === ROLES.BANK_ADMIN}
                        checked={values.role?.roleId === ROLES.BANK_ADMIN}
                        onClick={() => {
                          const roleObj = userState.roles.find(
                            (val) => val.roleId === ROLES.BANK_ADMIN
                          );
                          setFieldValue("role", roleObj);
                        }}
                        className={`button--grid ${
                          isThatRole(ROLES.BANK_ADMIN) || values.tenant
                            ? "active"
                            : undefined
                        }`}
                      >
                        <Icon name="building" />
                        <FormattedMessage
                          id="user.bank_admin"
                          defaultMessage="Amministratore banca"
                        />
                      </Button>
                      {(isThatRole(ROLES.ADMIN, appState) ||
                        isThatRole(ROLES.SUPERVISOR, appState)) && (
                        <>
                          <Button
                            type="button"
                            primary={values.role?.roleId === ROLES.EDITOR}
                            checked={values.role?.roleId === ROLES.EDITOR}
                            onClick={() => {
                              const roleObj = userState.roles.find(
                                (val) => val.roleId === ROLES.EDITOR
                              );
                              setFieldValue("role", roleObj);
                            }}
                            className={`button--grid ${
                              values.tenant &&
                              values.tenant.text == "CliccaBandi"
                                ? "active"
                                : undefined
                            }`}
                          >
                            <Icon name="keyboard" />
                            <FormattedMessage
                              id="user.editor"
                              defaultMessage="Editore"
                            />
                          </Button>
                          <Button
                            type="button"
                            primary={values.role?.roleId === ROLES.SUPERVISOR}
                            checked={values.role?.roleId === ROLES.SUPERVISOR}
                            onClick={() => {
                              const roleObj = userState.roles.find(
                                (val) => val.roleId === ROLES.SUPERVISOR
                              );
                              setFieldValue("role", roleObj);
                            }}
                            className={`button--grid ${
                              values.tenant &&
                              values.tenant.text == "CliccaBandi"
                                ? "active"
                                : undefined
                            }`}
                          >
                            <Icon name="cogs" />
                            <FormattedMessage
                              id="user.admin"
                              defaultMessage="Amministratore NSA"
                            />
                          </Button>
                        </>
                      )}
                    </Button.Group>
                    <ErrorMessage
                      name="role"
                      component="span"
                      className="invalid-field-error"
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={8} textAlign={"left"}>
                    <Button
                      type="button"
                      icon
                      onClick={() => navigate(FE_ROUTES.UTENTI)}
                      className="button--underline position--left"
                    >
                      <Icon name="arrow left" />
                      <div className="button--text">
                        <FormattedMessage
                          id="profile.goBackToList"
                          defaultMessage="Torna all'elenco utenti"
                        />
                      </div>
                    </Button>
                  </Grid.Column>
                  <Grid.Column width={8} textAlign={"right"}>
                    {mode === UserPageActionConstants.VIEW && (
                      <Button
                        type="button"
                        icon
                        onClick={onEdit}
                        className="button--primary--negative position--right"
                      >
                        <FormattedMessage
                          id="profile.edit"
                          defaultMessage="Modifica"
                        />
                      </Button>
                    )}
                    {mode !== UserPageActionConstants.VIEW && (
                      <>
                        <SubmitButton
                          form="userProfileForm"
                          type="submit"
                          onClick={() => {
                            handleSubmit(validateForm);
                          }}
                          icon
                          className="button--primary position--right"
                        >
                          <FormattedMessage
                            id="profile.save"
                            defaultMessage="Salva informazioni"
                          />
                        </SubmitButton>
                      </>
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

export default UserProfileForm;
