import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import { Button, Grid, Header, Icon } from "semantic-ui-react";
import { Select } from "formik-semantic-ui-react";
import { userReducer, UserState, initialUserState } from "./reducer/reducer";
import { UserAction, UserPageActionConstants } from "./reducer/actions";
import { TableFilterContext } from "layout/StandardLayout";
import DataGrid from "components/DataGrid";
import { getUserList } from "services/user";
import { DataGridActionConstants } from "components/DataGrid/reducer/actions";
import { initialDataGridState } from "components/DataGrid/reducer/reducer";
import { UserFormatterParams } from "components/DataGrid/types";
import { FE_ROUTES } from "utils/global/globalCostants";
import { Link, useNavigate, useLocation } from "react-router-dom";
import TableFilterForm from "components/Forms/TableFilterForm";
import Tooltip from "@material-ui/core/Tooltip";
import { userTypeOptions } from "utils/function/announcement";
import { TableFilterFormActionConstants } from "components/Forms/TableFilterForm/reducer/actions";
import _ from "lodash";
import { TableFilterFormState } from "components/Forms/TableFilterForm/reducer/reducer";

export const defaultUserFilters: TableFilterFormState = {
  searchText: "",
  orderBy: "ascendingOrder",
  userType: "active",
};

export const UserContext = createContext<{
  state: UserState;
  dispatch: React.Dispatch<UserAction>;
}>({
  state: initialUserState,
  dispatch: () => null,
});

export type UserTypeModalProps = {
  open: boolean;
};

const User: React.FC = () => {
  const {
    tableFilterState,
    tableFilterDispatch,
    dataGridState,
    dataGridDispatch,
    route,
    setRoute,
  } = useContext(TableFilterContext);

  const [state, dispatch] = useReducer(userReducer, initialUserState);

  const [firstRender, setFirstRender] = useState<boolean>(true);

  /**
   * The hook to navigate through the application.
   */
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * Method used to manage the navigation to the new customer form.
   */
  const navigateToCreateUser = () => {
    /** Navigate to the creation page */
    navigate(`${FE_ROUTES.UTENTI}/${UserPageActionConstants.NEW}`);
  };

  useEffect(() => {
    const refPath = location.pathname.replaceAll("/", "");
    if (route != refPath) {
      tableFilterDispatch({
        type: TableFilterFormActionConstants.SET_FILTERS,
        payload: defaultUserFilters,
      });
      setRoute(refPath);
    } else {
      getUserList(
        tableFilterState,
        dataGridState.paginationData,
        dataGridDispatch,
        dispatch
      );
    }
  }, []);

  /**
   * Gets user list on pagination data change.
   */
  useEffect(() => {
    // Get user collection
    if (!firstRender) {
      getUserList(
        tableFilterState,
        dataGridState.paginationData,
        dataGridDispatch,
        dispatch
      );
    }
  }, [dataGridState.paginationData.page]);

  useEffect(() => {
    if (!firstRender) {
      if (
        dataGridState.paginationData.page !=
        initialDataGridState.paginationData.page
      )
        dataGridDispatch({
          type: DataGridActionConstants.RESET_PAGINATION,
        });
      else {
        getUserList(
          tableFilterState,
          dataGridState.paginationData,
          dataGridDispatch,
          dispatch
        );
      }
    } else {
      setFirstRender(false);
    }
  }, [tableFilterState]);

  /**
   * Manages page selection change.
   * @param data
   */
  const handleSelectPage = (data: string | number) => {
    if (dataGridDispatch) {
      dataGridDispatch({
        type: DataGridActionConstants.SET_TABLE_PAGE_NUMBER,
        payload: {
          pageNumber: data,
        },
      });
    }
  };

  const resetPaginationAndFilters = () => {
    tableFilterDispatch({
      type: TableFilterFormActionConstants.SET_FILTERS,
      payload: defaultUserFilters,
    });
  };

  const UserTypeFilter: JSX.Element = (
    <Grid.Column className="userType">
      <FormattedMessage
        id="userfilter.userType"
        defaultMessage="Tipologia di utente"
      />
      <Select
        name="userType"
        options={userTypeOptions}
        clearable
        onChange={(event, { value }) => {
          tableFilterDispatch({
            type: TableFilterFormActionConstants.SET_USER_TYPE,
            payload: {
              userType: _.isString(value) ? value : null,
            },
          });
        }}
      />
    </Grid.Column>
  );

  return (
    <>
      <div className="user-container">
        <Helmet>
          <body className="user list" />
          <title>CliccaBandi.it - Elenco utenti</title>
          <meta name="description" content="user list page" />
        </Helmet>
        <div className="user__content">
          <Header className="userHeader" dividing>
            <Grid>
              <Grid.Row className="first-row">
                <Grid.Column>
                  <h1>
                    <FormattedMessage
                      id="user.title"
                      defaultMessage="Elenco utenti"
                    />
                  </h1>
                </Grid.Column>
                <Grid.Column className="new_user__buttons">
                  <Button type="button" onClick={navigateToCreateUser}>
                    <div>
                      <Icon name="plus" />
                      <FormattedMessage
                        id="add.new.user"
                        defaultMessage="Aggiungi utente"
                      />
                    </div>
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <TableFilterForm
              searchFieldPlaceHolder={
                <FormattedMessage
                  id="user.username"
                  defaultMessage="Nome utente"
                />
              }
              defaultFilters={tableFilterState}
              resetPagination={resetPaginationAndFilters}
              UserTypeFilter={UserTypeFilter}
            />
          </Header>

          <div className="userSectionBody">
            <DataGrid
              isLoading={dataGridState.isLoading}
              elements={state.userList}
              columns={[
                {
                  key: "displayName",
                  name: "",
                  formatter: ({ data }: UserFormatterParams) => {
                    return (
                      <Tooltip
                        title={data.displayName ? data.displayName : ""}
                        classes={{
                          popper: "isGrey",
                        }}
                        arrow
                        placement="top"
                        enterDelay={800}
                      >
                        <div className="list_title">{data.displayName}</div>
                      </Tooltip>
                    );
                  },
                },
                {
                  key: "action",
                  name: "",
                  formatter: ({ data }: UserFormatterParams) => {
                    const userDetailLink = `${FE_ROUTES.UTENTI}/view/${data.id}`;
                    return (
                      <Tooltip
                        title="Modifica"
                        classes={{
                          popper: "isGrey",
                        }}
                        arrow
                      >
                        <Link to={userDetailLink}>
                          <Icon name="pencil alternate" />
                        </Link>
                      </Tooltip>
                    );
                  },
                },
              ]}
              totalItems={state.userList.length}
              page={dataGridState.paginationData.page}
              onPageSelect={handleSelectPage}
              paginate
              pageSize={dataGridState.paginationData.page_size}
              pageCount={dataGridState.paginationData.page_count}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default User;
