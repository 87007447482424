import React from "react";
import ClassicExtended from "ckeditor5-build-classic-extended";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { RichTextEditorTypes } from "./types";
import _ from "lodash";

export const RichTextEditor: React.FC<RichTextEditorTypes> = ({
  data,
  placeholder,
  setFieldValue,
  field,
}) => {
  return (
    <div
      id="editor"
      className={
        field && (field.name === "description" || field.name === "note")
          ? "taller"
          : undefined
      }
    >
      <CKEditor
        editor={ClassicExtended}
        data={data}
        config={{
          toolbar: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "alignment",
            "|",
            "indent",
            "outdent",
            "|",
            "imageUpload",
            "blockQuote",
            "insertTable",
            "undo",
            "redo",
          ],
        }}
        // link={{
        //   addTargetToExternalLinks: true,
        // }}
        onReady={(editor) => {
          if (editor) {
            const data = editor.getData();
            if (!data) {
              editor.setData(placeholder);
            }
          }
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          let newData = _.clone(data);
          if (data.includes("<a")) {
            const allLinks = data.match(/<a href="(.+?)">/gm);
            if (allLinks) {
              for (let i = allLinks.length - 1; i >= 0; i--) {
                let newTagA = "";
                if (!allLinks[i].includes("https")) {
                  const indexLink = allLinks[i].indexOf('"') + 1;
                  const newTagA =
                    allLinks[i].slice(0, indexLink) +
                    "https://" +
                    allLinks[i].slice(indexLink);
                  newData = newData.replace(allLinks[i], newTagA);
                  editor.setData(newData);
                } else {
                  newTagA = allLinks[i];
                }
                if (!newTagA.includes("_blank")) {
                  const targetIndex =
                    data.indexOf(newTagA) + newTagA.length - 1;
                  newData =
                    newData.slice(0, targetIndex) +
                    ' target="_blank"' +
                    newData.slice(targetIndex);
                }
              }
            }
          }
          if (setFieldValue && field) setFieldValue(field.name, newData);
        }}
        onBlur={() => {
          //console.log(editor);
        }}
        onFocus={(event, editor) => {
          if (editor.getData().includes(placeholder)) {
            editor.setData("");
          }
        }}
      />
    </div>
  );
};
