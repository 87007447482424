import React, { useContext } from "react";
import { Modal, Form, Icon, Button } from "semantic-ui-react";
import { Formik } from "formik";
import { Field } from "formik-semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { FilterEditContext } from "pages/FilterEdit";
import { FilterEditActionConstants } from "pages/FilterEdit/reducer/actions";
import { RichTextEditor } from "components/RichTextEditor";
import { TooltipFormValuesType } from "./types";
import {
  updateFilterTooltip,
  createFilterTooltip,
  deleteFilterTooltip,
} from "services/tooltips";
import { FormikHelpers } from "formik";
import { TooltipEditTypes } from "./types";
import { filterTranslation } from "utils/function/computeFilters";

export const TooltipEdit: React.FC<TooltipEditTypes> = ({ action, id }) => {
  const context = useContext(FilterEditContext);
  const handleSubmit = (
    values: TooltipFormValuesType,
    formikHelpers: FormikHelpers<TooltipFormValuesType>
  ) => {
    const payload = context.state.tooltip;
    payload.content = values.tooltip;
    if (action === "edit")
      updateFilterTooltip(payload, formikHelpers, context.dispatch);
    if (action === "create" && id) {
      payload.filterReference = id;
      createFilterTooltip(payload, formikHelpers, context.dispatch);
    }
  };

  return (
    <>
      <Modal
        closeIcon
        open={context.state.modalOpen}
        onClose={() =>
          context.dispatch({
            type: FilterEditActionConstants.SET_MODAL_OPEN,
            payload: { modalOpen: false },
          })
        }
        className="tooltip__edit"
      >
        <Modal.Header>Tooltip editor</Modal.Header>
        <Modal.Content>
          <Formik
            initialValues={{ tooltip: context.state.tooltip.content }}
            onSubmit={(values, formikHelpers) => {
              handleSubmit(values, formikHelpers);
            }}
          >
            {({ setFieldValue, submitForm, isSubmitting }) => (
              <Form>
                <Field name="tooltip">
                  {({ field }) => (
                    <>
                      <label>
                        {context.state.filterTreeData[0].id
                          ? filterTranslation[
                              context.state.filterTreeData[0].id
                            ]
                          : ""}
                      </label>
                      <RichTextEditor
                        data={field.value}
                        placeholder="Inserire i contenuti"
                        setFieldValue={setFieldValue}
                        field={field}
                      />
                    </>
                  )}
                </Field>
                <Button
                  className="button--primary--negative"
                  loading={isSubmitting}
                  type="submit"
                  onClick={submitForm}
                >
                  <Icon name="check" />
                  <FormattedMessage
                    id="filterEdit.save"
                    defaultMessage="Salva"
                  />
                </Button>
                {context.state.tooltip.id && (
                  <Button
                    className="button--primary--negative"
                    onClick={() => {
                      deleteFilterTooltip(
                        context.state.tooltip.id || "",
                        context.dispatch
                      );
                    }}
                    loading={context.state.tooltipLoading}
                  >
                    <Icon name="delete" />
                    <FormattedMessage
                      id="filterEdit.delete"
                      defaultMessage="Elimina"
                    />
                  </Button>
                )}
              </Form>
            )}
          </Formik>
        </Modal.Content>
      </Modal>
    </>
  );
};
