import React, {
  createContext,
  useReducer,
  useEffect,
  useState,
  useContext,
} from "react";
import { AppContext } from "pages/App";
import { Outlet, useLocation, useParams, Link } from "react-router-dom";
import _ from "lodash";
import { Icon, Image } from "semantic-ui-react";
import { Breadcrumbs } from "@mui/material";

//Constants
import { LOCAL_STORAGE } from "utils/global/globalCostants";

// Layout
import Footer from "layout/Footer";

// Utils
import {
  announcementReducer,
  AnnouncementState,
  initialAnnouncementState,
} from "pages/Announcement/reducer/reducer";
import { AnnouncementAction } from "pages/Announcement/reducer/actions";
import { composeBreadcrumbs } from "utils/function/composeBreadcrumbs";

export const ElencoBandiContext = createContext<{
  state: AnnouncementState;
  dispatch: React.Dispatch<AnnouncementAction>;
}>({
  state: initialAnnouncementState,
  dispatch: () => null,
});

const ElencoBandiLayout: React.FC = () => {
  const [state, dispatch] = useReducer(
    announcementReducer,
    initialAnnouncementState
  );
  const location = useLocation();
  const { action, id } = useParams();
  const [breadcrumbs, setBreadcrumbs] = useState<string>("[]");
  const { state: appState } = useContext(AppContext);

  useEffect(() => {
    composeBreadcrumbs(id, action);
    const getBreadcrumbsIfNotNull = localStorage.getItem(
      LOCAL_STORAGE.BREADCRUMBS
    );
    if (!_.isNull(getBreadcrumbsIfNotNull))
      setBreadcrumbs(getBreadcrumbsIfNotNull);
  }, [location]);

  return (
    <ElencoBandiContext.Provider value={{ state, dispatch }}>
      <div className="elencoBandilayout__container">
        <Image className="wave_bg" src={appState.waveBg} />
        <div className="scroll__container">
          {localStorage.getItem(LOCAL_STORAGE.BREADCRUMBS) && (
            <Breadcrumbs separator="›">
              {_.map(JSON.parse(breadcrumbs), (chunk, index) =>
                !_.isUndefined(chunk.link) ? (
                  <Link to={chunk.link} key={`breadcrumbs_${index}`}>
                    {!_.isEmpty(chunk.name) ? chunk.name : <Icon name="home" />}
                  </Link>
                ) : (
                  <div key={`breadcrumbs_${index}`}>
                    {!_.isEmpty(chunk.name) ? chunk.name : <Icon name="home" />}
                  </div>
                )
              )}
            </Breadcrumbs>
          )}
          <Outlet />
        </div>
        <Footer />
      </div>
    </ElencoBandiContext.Provider>
  );
};
export default ElencoBandiLayout;
