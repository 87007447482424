import _ from "lodash";
import { BreadcrumbItem } from "pages/App/types";
import { LOCAL_STORAGE } from "utils/global/globalCostants";

export const composeBreadcrumbs = (
  id: string | undefined,
  action: string | undefined
): void => {
  const locationArray: Array<string> = location.pathname.split("/");
  const customArrayPath: Array<string> = _.clone(locationArray);
  if (_.isEmpty(locationArray[locationArray.length - 1]))
    customArrayPath.splice(locationArray.length - 1, 1);
  if (action) {
    switch (action) {
      case "view":
        customArrayPath[_.indexOf(customArrayPath, action)] = "Visualizza";
        break;
      case "edit":
        customArrayPath[_.indexOf(customArrayPath, action)] = "Modifica";
        break;
      case "new":
        customArrayPath[_.indexOf(customArrayPath, action)] = "Crea";
        break;
    }
  }
  if (id) {
    customArrayPath.splice(_.indexOf(customArrayPath, id), 1);
  }
  let link: string | undefined = "";
  const breadcrumbsArray: Array<BreadcrumbItem> = _.map(
    customArrayPath,
    (element, index) => {
      const obj: BreadcrumbItem = {
        link: undefined,
        name: "",
      };
      if (customArrayPath.length > 1) {
        if (index === 0) {
          link = "/";
        } else if (index < customArrayPath.length - 1) {
          link += `${locationArray[index]}/`;
        } else {
          link = undefined;
        }
      } else {
        link = undefined;
      }
      obj["name"] = element;
      if (element && index === 1)
        obj["name"] = _.join(
          _.map(element.split("-"), (s, index) => {
            if (index === 0) return _.upperFirst(s);
            return s;
          }),
          " "
        );
      obj["link"] = link;
      return obj;
    }
  );
  if (breadcrumbsArray)
    if (breadcrumbsArray)
      localStorage.setItem(
        LOCAL_STORAGE.BREADCRUMBS,
        JSON.stringify(breadcrumbsArray)
      );
};
