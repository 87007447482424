import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Grid, Header, Segment } from "semantic-ui-react";

// Images
// import email from "assets/images/email.svg";

// Form
import PasswordRecoveryForm from "../../components/Forms/PasswordRecoveryForm";

// Types
import { PasswordRecoveryState } from "./types";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { FE_ROUTES } from "utils/global/globalCostants";

const PasswordRecovery: React.FC = () => {
  /**
   * That state keeps the information about the current registration process.
   * Basically it's empty until the registration process will be succesful.
   */
  const [response, setResponse] = useState<PasswordRecoveryState>({
    sent: false,
    email: null,
  });

  return (
    <div className="password-framework">
      <Helmet>
        <title>CliccaBandi - Recupero password</title>
        <meta name="description" content="password recovery page" />
      </Helmet>
      {!response.sent && (
        <Grid className="password-framework__container">
          <Grid.Row className="access__form">
            <Grid.Column>
              <Segment>
                <PasswordRecoveryForm setResponse={setResponse} />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
      {response.sent && (
        <Grid className="password-framework__container">
          <Grid.Row textAlign="center">
            <Grid.Column>
              <Header>
                <FormattedMessage
                  id="passwordRecovery.thank"
                  defaultMessage="Richiesta inviata"
                />
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row textAlign="center" className="access__form">
            <Grid.Column>
              <Segment>
                <div className="password-recovery__email-icon">
                  {/*<Image src={email} />*/}
                </div>

                <span className="password-recovery__title--succesful">
                  <FormattedMessage
                    id="passwordRecovery.succesful"
                    defaultMessage="La richiesta di recupero della password è avvenuta correttamente! {newLine} Controlla tua la casella di posta {email}."
                    values={{
                      email: <span>{response.email}</span>,
                      newLine: <br />,
                    }}
                  />
                </span>
                <Segment.Inline>
                  <Button
                    type="button"
                    className="button--to-dashboard"
                    content={
                      <div>
                        <Link to={FE_ROUTES.MAIN_ROUTE}>
                          <FormattedMessage
                            id="toDashboard"
                            defaultMessage="Torna alla dashboard"
                          />
                        </Link>
                      </div>
                    }
                  />
                </Segment.Inline>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </div>
  );
};

export default PasswordRecovery;
