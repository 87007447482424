import _ from "lodash";
import { FormattedMessage } from "react-intl";
import { ANNOUNCEMENT_STATUS } from "utils/global/globalCostants";
import React from "react";

export const formatStatusValue = (
  statusValue: string | null | undefined
): string => {
  let formattedStatus = "n/d";
  if (!_.isEmpty(statusValue)) {
    if (statusValue === ANNOUNCEMENT_STATUS.APERTO) {
      formattedStatus = "Aperto";
    } else if (statusValue === ANNOUNCEMENT_STATUS.CHIUSO) {
      formattedStatus = "Chiuso";
    } else if (statusValue === ANNOUNCEMENT_STATUS.IN_PROGRAMMAZIONE) {
      formattedStatus = "In programmazione";
    }
  }
  return formattedStatus;
};

export const orderByOptions = [
  {
    text: (
      // eslint-disable-next-line react/react-in-jsx-scope
      <FormattedMessage id="orderBy.earlier" defaultMessage="I più recenti" />
    ),
    value: "earliest",
  },
  {
    text: (
      // eslint-disable-next-line react/react-in-jsx-scope
      <FormattedMessage id="orderBy.oldest" defaultMessage="I meno recenti" />
    ),
    value: "oldest",
  },
  {
    text: (
      // eslint-disable-next-line react/react-in-jsx-scope
      <FormattedMessage
        id="user.ascendingOrder"
        defaultMessage="Ordinamento crescente (a-z)"
      />
    ),
    value: "ascendingOrder",
  },
  {
    text: (
      // eslint-disable-next-line react/react-in-jsx-scope
      <FormattedMessage
        id="user.descendingOrder"
        defaultMessage="Ordinamento decrescente (z-a)"
      />
    ),
    value: "descendingOrder",
  },
];

export const exportValueOptions = [
  { key: "excel", text: "Excel", value: "excel" },
  // { key: "csv", text: "CSV", value: "csv" },
];

export const userTypeOptions = [
  {
    text: "Utenti attivi",
    value: "active",
  },
  {
    text: "Utenti disattivati",
    value: "blocked",
  },
  {
    text: "Utenti non registrati / password errata",
    value: "inactive",
  },
  {
    text: "Tutti",
    value: "allTogether",
  },
];

export const formatFinancedContribute = (
  minFinancedContribute,
  maxFinancedContribute
) => {
  const defaultPhrase = "N/A";

  const nf = new Intl.NumberFormat("it-IT", {
    minimumFractionDigits: 2,
    style: "currency",
    currency: "EUR",
  });

  const minMessage = !_.isEmpty(minFinancedContribute) ? (
    <FormattedMessage
      id="announcementDetail.from"
      defaultMessage="Fino a {minAmount}"
      values={{
        minAmount: nf.format(minFinancedContribute),
      }}
    />
  ) : null;

  const maxMessage = !_.isEmpty(maxFinancedContribute) ? (
    <FormattedMessage
      id="announcementDetail.to"
      defaultMessage="Fino a {maxAmount}"
      values={{
        maxAmount: nf.format(maxFinancedContribute),
      }}
    />
  ) : null;

  const ObjResponse =
    minMessage || maxMessage ? (
      <>
        {minMessage ? <div>{minMessage}</div> : null}
        {maxMessage ? <div>{maxMessage}</div> : null}
      </>
    ) : null;
  return ObjResponse || defaultPhrase;
};
