import React, { useContext } from "react";
import { Grid } from "semantic-ui-react";
import { Form, Input, SubmitButton } from "formik-semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { ErrorMessage, Formik } from "formik";

// Types
import { HybridAuthFormProps, HybridAuthFormValues } from "./types";
import { AppContext } from "pages/App";

const LoginForm: React.FC<HybridAuthFormProps> = ({ onSubmit }) => {
  const { state } = useContext(AppContext);

  const initialFormValues: HybridAuthFormValues = {
    nome: "Giordano",
    cognome: "Moreni",
    email: "g.moreni@novigo-consulting.it",
    codfiliale: "123,456,789",
    datetime: "202305111004000",
    token: "94411c3803438e0ccd823549012eec9e",
  };

  return (
    <Formik
      initialValues={initialFormValues}
      onSubmit={(value: HybridAuthFormValues) => onSubmit(value)}
    >
      {() => (
        <Form className="login__form">
          <Grid padded textAlign="left">
            <Grid.Row textAlign="left">
              <Grid.Column>
                <Input
                  name="nome"
                  placeholder="Nome*"
                  label={
                    <>
                      <FormattedMessage id="nome" defaultMessage="Nome" />
                      {"*"}
                    </>
                  }
                />
                <ErrorMessage name="nome" component="span" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row textAlign="left">
              <Grid.Column>
                <Input
                  name="cognome"
                  placeholder="Cognome*"
                  label={
                    <>
                      <FormattedMessage id="cognome" defaultMessage="Cognome" />
                      {"*"}
                    </>
                  }
                />
                <ErrorMessage name="cognome" component="span" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row textAlign="left">
              <Grid.Column>
                <Input
                  name="email"
                  placeholder="Email*"
                  label={
                    <>
                      <FormattedMessage id="email" defaultMessage="Email" />
                      {"*"}
                    </>
                  }
                />
                <ErrorMessage name="email" component="span" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row textAlign="left">
              <Grid.Column>
                <Input
                  name="codfiliale"
                  placeholder="Codice filiale*"
                  label={
                    <>
                      <FormattedMessage
                        id="codfiliale"
                        defaultMessage="Codice filiale"
                      />
                      {"*"}
                    </>
                  }
                />
                <ErrorMessage name="codfiliale" component="span" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row textAlign="left">
              <Grid.Column>
                <Input
                  name="datetime"
                  placeholder="Data*"
                  label={
                    <>
                      <FormattedMessage id="datetime" defaultMessage="Data" />
                      {"*"}
                    </>
                  }
                />
                <ErrorMessage name="datetime" component="span" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row textAlign="left">
              <Grid.Column>
                <Input
                  name="token"
                  placeholder="Token*"
                  label={
                    <>
                      <FormattedMessage id="token" defaultMessage="Token" />
                      {"*"}
                    </>
                  }
                />
                <ErrorMessage name="token" component="span" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <SubmitButton
                  className="login--button"
                  content={
                    <FormattedMessage
                      id="login.submit"
                      defaultMessage="Accedi"
                    />
                  }
                  disabled={state.isUserLoading || state.isBlocked}
                  loading={state.isUserLoading}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
