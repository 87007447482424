import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { Image } from "semantic-ui-react";
import RegisterForm from "../../components/Forms/RegisterForm";

// app context
import { AppContext } from "pages/App";

const Register: React.FC = () => {
  const { state } = useContext(AppContext);

  return (
    <div className="register-container">
      <Helmet>
        <body className="register" />
        <title>CliccaBandi - Register</title>
        <meta name="description" content="register page" />
      </Helmet>
      <Image size="large" src={state.logo} />
      <div className="register-form-container">
        <RegisterForm />
      </div>
    </div>
  );
};

export default Register;
