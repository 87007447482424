export enum FirstLoginActionConstants {
  SET_ACTIVATION_STATUS = "FirstLogin/SET_ACTIVATION_STATUS",
  SET_IS_LOADING = "FirstLogin/SET_IS_LOADING",
  SET_ACTIVATION_TOKEN = "FirstLogin/SET_ACTIVATION_TOKEN",
}

export type FirstLoginAction =
  | {
      type: FirstLoginActionConstants.SET_ACTIVATION_STATUS;
      payload: { status: boolean };
    }
  | {
      type: FirstLoginActionConstants.SET_IS_LOADING;
      payload: { isLoading: boolean };
    }
  | {
      type: FirstLoginActionConstants.SET_ACTIVATION_TOKEN;
      payload: { token: string | null };
    };
