import React, { useContext } from "react";
import { Button, Grid } from "semantic-ui-react";
import { Field, Form, Input, SubmitButton } from "formik-semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { ErrorMessage, Formik } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import _ from "lodash";

// Types
import { LoginFormProps, LoginFormValues } from "./types";
import { CONFIG } from "utils/global/globalCostants";
import { AppContext } from "pages/App";
import { PasswordInputFormik } from "components/PasswordInputFormik";

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit }) => {
  const { state } = useContext(AppContext);

  const initialFormValues: LoginFormValues = {
    username: "",
    password: "",
  };

  return (
    <Formik
      initialValues={initialFormValues}
      onSubmit={(value) => onSubmit(value)}
      validationSchema={Yup.object().shape({
        username: Yup.string()
          .label("E-mail")
          .required("E-mail è richiesto")
          .nullable(),
        password: Yup.string()
          .label("Password")
          .required("Password è richiesto")
          .nullable(),
      })}
    >
      {({ setFieldValue, handleBlur }) => (
        <Form className="login__form" autocomplete="off">
          <Grid padded textAlign="left">
            <Grid.Row textAlign="left">
              <Grid.Column>
                <Input
                  name="username"
                  placeholder="E-mail*"
                  label={
                    <>
                      <FormattedMessage
                        id="app.email"
                        defaultMessage="E-Mail"
                      />
                      {"*"}
                    </>
                  }
                />
                <ErrorMessage name="username" component="span" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Field name="password">
                  {({ field }) => (
                    <PasswordInputFormik
                      placeholder={"Password*"}
                      label="Password*"
                      type="password"
                      setFieldValue={setFieldValue}
                      field={field}
                      onBlur={handleBlur}
                    />
                  )}
                </Field>
              </Grid.Column>
            </Grid.Row>
            {!_.isUndefined(state.remainingAttempts) && (
              <Grid.Row className="user--blocked">
                <Grid.Column>
                  <div>
                    Questa utenza è stata disattivata dagli amministratori.{" "}
                    <br /> Non è possibile eseguire l&apos;accesso.
                  </div>
                </Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row className="login__password-row" columns={2}>
              <Grid.Column className="login__password-recovery">
                <div className={state.isBlocked ? "user--blocked" : undefined}>
                  <Link to={state.isBlocked ? "#" : "/password-recovery"}>
                    <FormattedMessage
                      id="passwordRecovery"
                      defaultMessage="Hai dimenticato la password?"
                    />
                  </Link>
                </div>
              </Grid.Column>
            </Grid.Row>
            {state.isBlocked && (
              <Grid.Row className="user--blocked">
                <Grid.Column>
                  <div>
                    Questa utenza è stata disattivata dagli amministratori.{" "}
                    <br /> Non è possibile eseguire l&apos;accesso.
                  </div>
                </Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row>
              <Grid.Column textAlign="center">
                <SubmitButton
                  className="login--button"
                  content={
                    <FormattedMessage
                      id="login.submit"
                      defaultMessage="Accedi"
                    />
                  }
                  disabled={state.isUserLoading || state.isBlocked}
                  loading={state.isUserLoading}
                />
              </Grid.Column>
            </Grid.Row>
            {CONFIG.OFFICE_LOGIN_ACTIVE && (
              <Grid.Row>
                <Button
                  className="microsoft-button"
                  content="Login Office 365"
                  type="button"
                  icon="microsoft"
                  fluid
                  labelPosition="left"
                />
              </Grid.Row>
            )}
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
