const downloadDocument = (res): void => {
  const contentDisposition = res.headers["content-disposition"];
  const filename = contentDisposition.match(`filename="(.*)"`)[1];
  const newBlob = new Blob([res.data], { type: res.data.type });
  const fileUrl = window.URL.createObjectURL(newBlob);
  const tempLink = document.createElement("a");
  tempLink.href = fileUrl;
  tempLink.setAttribute("download", filename);
  tempLink.click();
};

export default downloadDocument;
