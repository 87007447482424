import { AxiosResponse } from "axios";
import React from "react";
import { toast } from "react-toastify";
import standardClient from "services/client/standardRequestClient";
import { API } from "utils/global/backendRoutes";
import { PaginateObject } from "utils/types/paginateObject";
import qs from "qs";
import _ from "lodash";
import {
  FilterAnnouncementAction,
  FilterAnnouncementActionConstants,
} from "components/Forms/FilterAnnouncementForm/reducer/actions";
import {
  FilterEditAction,
  FilterEditActionConstants,
} from "pages/FilterEdit/reducer/actions";
import { CustomerObjectResponse } from "services/customer/types";
import { SelectValueLabelObject, SelectValueTextObject } from "utils/types";
import { FilterTreeDataObj, FilterTableType } from "pages/FilterEdit/types";
import { FilterResponseObj, FilterResponse } from "services/announcement/types";
import { filterTranslation } from "utils/function/computeFilters";
import { StandardResponseError } from "services/client/types";

export const getCustomers = (
  filterAnnouncementDispatch: React.Dispatch<FilterAnnouncementAction>,
  customer: string | undefined,
  callback: (options: SelectValueLabelObject[]) => void
): void => {
  let params = {};
  params = {
    filter: [
      customer && {
        type: "like",
        field: "businessName",
        value: `%${customer}%`,
      },
    ].filter((o) => o),
    pageSize: 10,
  };
  const orderByParams = {
    field: "businessName",
    type: "field",
    direction: "asc",
  };
  params["order-by"] = [orderByParams];

  const paramsSerializer = qs.stringify(params);

  standardClient({
    url: `${API.CUSTOMER}?${paramsSerializer}`,
    method: "GET",
  })
    .then(({ data }: AxiosResponse<PaginateObject<CustomerObjectResponse>>) => {
      const customers = data._embedded.customer;
      if (customers.length > 0) {
        filterAnnouncementDispatch({
          type: FilterAnnouncementActionConstants.SET_CUSTOMER_LIST,
          payload: { customerList: customers },
        });
        if (callback) {
          callback(
            customers.map(
              (customer): SelectValueLabelObject => ({
                value: customer.id,
                label: customer.businessName,
              })
            )
          );
        }
      } else {
        callback([]);
      }

      // filterAnnouncementDispatch({
      //   type: FilterAnnouncementActionConstants.SET_IS_LOADING_CUSTOMER,
      //   payload: { isLoadingCustomer: false },
      // });
    })
    .catch(() => {
      toast.error("Errore durante il caricamento dei clienti");
    });
};

export const getFilterOptions = (
  endpoint: string,
  filterAnnouncementDispatch: React.Dispatch<FilterAnnouncementAction>
): void => {
  const orderByPayload = {
    "order-by": [
      {
        field: "position",
        type: "field",
        direction: "asc",
      },
    ],
  };

  standardClient({
    url: `${endpoint}?${qs.stringify(orderByPayload)}`,
    method: "GET",
  })
    .then(({ data }: AxiosResponse<PaginateObject<FilterResponseObj>>) => {
      let filterList;
      switch (endpoint) {
        case API.GEOGRAPHICAL_REFERENCE:
          filterList = data._embedded.geographical_reference;
          break;
        case API.ECONOMIC_ACTIVITY:
          filterList = data._embedded.economic_activity;
          break;
        case API.COMPANY_SIZE:
          filterList = data._embedded.company_size;
          break;
        case API.TOPIC:
          filterList = data._embedded.topic;
          break;
        case API.FUNDING_TYPE:
          filterList = data._embedded.funding_type;
          break;
        case API.APPLICANT_COMPANY_FORM:
          filterList = data._embedded.applicant_company_form;
          break;
        case API.ELIGIBLE_COST:
          filterList = data._embedded.eligible_cost;
          break;
        case API.PROMOTING_INSTITUTION:
          filterList = data._embedded.promoting_institution;
          break;
        default:
          filterList = [];
      }

      /* Transform server response into select options */
      const selectOptions: Array<SelectValueTextObject> =
        generateSelectOptions(filterList);

      let action: FilterAnnouncementAction = {
        type: FilterAnnouncementActionConstants.SET_OPTIONS,
        payload: { options: [] },
      };
      let isLoadingAction: FilterAnnouncementAction = {
        type: FilterAnnouncementActionConstants.SET_IS_LOADING,
        payload: { isLoading: false },
      };

      switch (endpoint) {
        case API.GEOGRAPHICAL_REFERENCE:
          action = {
            type: FilterAnnouncementActionConstants.SET_GEOGRAPHIC_REFERENCE_OPTIONS,
            payload: { geographicReferenceOptions: selectOptions },
          };
          isLoadingAction = {
            type: FilterAnnouncementActionConstants.SET_IS_LOADING_GEOGRAPHIC_REFERENCE,
            payload: { isLoadingGeoReference: false },
          };
          break;
        case API.ECONOMIC_ACTIVITY:
          action = {
            type: FilterAnnouncementActionConstants.SET_ECONOMIC_ACTIVITY_OPTIONS,
            payload: { economicActivityOptions: selectOptions },
          };
          isLoadingAction = {
            type: FilterAnnouncementActionConstants.SET_IS_LOADING_ECONOMIC_ACTIVITY,
            payload: { isLoadingEconomicActivity: false },
          };
          break;
        case API.COMPANY_SIZE:
          action = {
            type: FilterAnnouncementActionConstants.SET_COMPANY_SIZE_OPTIONS,
            payload: { companySizeOptions: selectOptions },
          };
          isLoadingAction = {
            type: FilterAnnouncementActionConstants.SET_IS_LOADING_COMPANY_SIZE,
            payload: { isLoadingCompanySize: false },
          };
          break;
        case API.TOPIC:
          action = {
            type: FilterAnnouncementActionConstants.SET_TOPIC_OPTIONS,
            payload: { topicOptions: selectOptions },
          };
          isLoadingAction = {
            type: FilterAnnouncementActionConstants.SET_IS_LOADING_TOPIC,
            payload: { isLoadingTopic: false },
          };
          break;
        case API.FUNDING_TYPE:
          action = {
            type: FilterAnnouncementActionConstants.SET_FUNDING_TYPE_OPTIONS,
            payload: { fundingTypeOptions: selectOptions },
          };
          isLoadingAction = {
            type: FilterAnnouncementActionConstants.SET_IS_LOADING_FUNDING_TYPE,
            payload: { isLoadingFundingType: false },
          };
          break;
        case API.APPLICANT_COMPANY_FORM:
          action = {
            type: FilterAnnouncementActionConstants.SET_APPLICANT_COMPANY_FORM_OPTIONS,
            payload: { applicantCompanyFormOptions: selectOptions },
          };
          isLoadingAction = {
            type: FilterAnnouncementActionConstants.SET_IS_LOADING_APPLICANT_COMPANY_FORM,
            payload: { isLoadingApplicantCompanyForm: false },
          };
          break;
        case API.ELIGIBLE_COST:
          action = {
            type: FilterAnnouncementActionConstants.SET_ELIGIBLE_COST_OPTIONS,
            payload: { eligibleCostOptions: selectOptions },
          };
          isLoadingAction = {
            type: FilterAnnouncementActionConstants.SET_IS_LOADING_ELIGIBLE_COST,
            payload: { isLoadingEligibleCost: false },
          };
          break;
        case API.PROMOTING_INSTITUTION:
          action = {
            type: FilterAnnouncementActionConstants.SET_PROMOTING_INSTITUTION_OPTIONS,
            payload: { promotingInstitutionOptions: selectOptions },
          };
          isLoadingAction = {
            type: FilterAnnouncementActionConstants.SET_IS_LOADING_PROMOTING_INSTITUTION,
            payload: { isLoadingPromotingInstitution: false },
          };
          break;
      }

      filterAnnouncementDispatch(action);
      filterAnnouncementDispatch(isLoadingAction);
    })
    .catch(() => {
      toast.error("Errore durante il caricamento dei filtri");
      let isLoadingAction: FilterAnnouncementAction = {
        type: FilterAnnouncementActionConstants.SET_IS_LOADING,
        payload: { isLoading: false },
      };

      switch (endpoint) {
        case API.GEOGRAPHICAL_REFERENCE:
          isLoadingAction = {
            type: FilterAnnouncementActionConstants.SET_IS_LOADING_GEOGRAPHIC_REFERENCE,
            payload: { isLoadingGeoReference: false },
          };
          break;
        case API.ECONOMIC_ACTIVITY:
          isLoadingAction = {
            type: FilterAnnouncementActionConstants.SET_IS_LOADING_ECONOMIC_ACTIVITY,
            payload: { isLoadingEconomicActivity: false },
          };
          break;
        case API.COMPANY_SIZE:
          isLoadingAction = {
            type: FilterAnnouncementActionConstants.SET_IS_LOADING_COMPANY_SIZE,
            payload: { isLoadingCompanySize: false },
          };
          break;
        case API.TOPIC:
          isLoadingAction = {
            type: FilterAnnouncementActionConstants.SET_IS_LOADING_TOPIC,
            payload: { isLoadingTopic: false },
          };
          break;
        case API.FUNDING_TYPE:
          isLoadingAction = {
            type: FilterAnnouncementActionConstants.SET_IS_LOADING_FUNDING_TYPE,
            payload: { isLoadingFundingType: false },
          };
          break;
        case API.APPLICANT_COMPANY_FORM:
          isLoadingAction = {
            type: FilterAnnouncementActionConstants.SET_IS_LOADING_APPLICANT_COMPANY_FORM,
            payload: { isLoadingApplicantCompanyForm: false },
          };
          break;
        case API.ELIGIBLE_COST:
          isLoadingAction = {
            type: FilterAnnouncementActionConstants.SET_IS_LOADING_ELIGIBLE_COST,
            payload: { isLoadingEligibleCost: false },
          };
          break;
        case API.PROMOTING_INSTITUTION:
          isLoadingAction = {
            type: FilterAnnouncementActionConstants.SET_IS_LOADING_PROMOTING_INSTITUTION,
            payload: { isLoadingPromotingInstitution: false },
          };
          break;
      }
      filterAnnouncementDispatch(isLoadingAction);
    });
};

const generateSelectOptions = (listValues: Array<FilterResponse>) => {
  let listOptions: Array<SelectValueTextObject> = [];
  if (listValues.length > 0) {
    listOptions = listValues.map((listVal): SelectValueTextObject => {
      return { value: listVal.id, text: listVal.description };
    });
  }
  return listOptions;
};

export const getFilterValues = (
  endpoint: string,
  filterEditDispatch: React.Dispatch<FilterEditAction>,
  setFilterLength: React.Dispatch<React.SetStateAction<number>>,
  loadingPage: boolean
): Promise<void> => {
  if (loadingPage)
    filterEditDispatch({
      type: FilterEditActionConstants.SET_IS_LOADING,
      payload: { isLoading: true },
    });
  const orderByPayload = {
    "order-by": [
      {
        field: "position",
        type: "field",
        direction: "asc",
      },
    ],
  };

  return standardClient({
    url: `/${endpoint}?${qs.stringify(orderByPayload)}`,
    method: "GET",
  })
    .then(({ data }: AxiosResponse<PaginateObject<FilterResponseObj>>) => {
      const slashEndpoint = "/" + endpoint;
      let filterList;
      switch (slashEndpoint) {
        case API.GEOGRAPHICAL_REFERENCE:
          filterList = data._embedded.geographical_reference;
          break;
        case API.ECONOMIC_ACTIVITY:
          filterList = data._embedded.economic_activity;
          break;
        case API.COMPANY_SIZE:
          filterList = data._embedded.company_size;
          break;
        case API.TOPIC:
          filterList = data._embedded.topic;
          break;
        case API.FUNDING_TYPE:
          filterList = data._embedded.funding_type;
          break;
        case API.APPLICANT_COMPANY_FORM:
          filterList = data._embedded.applicant_company_form;
          break;
        case API.ELIGIBLE_COST:
          filterList = data._embedded.eligible_cost;
          break;
        case API.PROMOTING_INSTITUTION:
          filterList = data._embedded.promoting_institution;
          break;
        default:
          filterList = [];
      }
      const { filterTreeData, formInitialValues, listValues } =
        generateInitialValues(filterList, endpoint);
      filterList = listValues;
      setFilterLength(filterList.length);
      filterEditDispatch({
        type: FilterEditActionConstants.SET_FILTER_TREE_DATA,
        payload: { filterTreeData: filterTreeData },
      });
      filterEditDispatch({
        type: FilterEditActionConstants.SET_FILTER_VALUES,
        payload: { filterValues: filterList },
      });
      filterEditDispatch({
        type: FilterEditActionConstants.SET_INITIAL_FORM_TREE_VALUES,
        payload: { initialFormTreeValues: formInitialValues },
      });
      if (loadingPage)
        filterEditDispatch({
          type: FilterEditActionConstants.SET_IS_LOADING,
          payload: { isLoading: false },
        });
    })
    .catch(() => {
      toast.error("Errore durante il caricamento dei valori");
      filterEditDispatch({
        type: FilterEditActionConstants.SET_IS_LOADING,
        payload: { isLoading: false },
      });
    });
};

const generateInitialValues = (
  listValues: Array<FilterResponse>,
  endpoint: string
) => {
  /** Prepare filterTreeData */
  const filterTreeData: Array<FilterTreeDataObj> = [];
  const treeDataObj = { title: "" };
  const filterName = composeName(endpoint);
  const filterTitle = filterTranslation[filterName];
  treeDataObj["title"] = filterTitle;
  treeDataObj["expanded"] = true;
  treeDataObj["id"] = filterName;
  treeDataObj["children"] = [];
  /** Prepare form data */
  const formInitialValues = {};
  formInitialValues[filterName] = filterTranslation[filterName];
  if (listValues.length > 0) {
    listValues.forEach((listVal) => {
      _.unset(listVal, "_links");
      formInitialValues[listVal.code] = listVal.description;
      const child = {
        title: listVal.description,
        id: listVal.code,
        isSubmitting: false,
        deletable: listVal.isDeletable,
      };
      treeDataObj["children"].push(child);
    });
  }
  filterTreeData.push(treeDataObj);
  return { filterTreeData, formInitialValues, listValues };
};

const composeName = (endpoint: string) => {
  let splitId = endpoint.split("-");
  splitId = _.map(splitId, (word, index) => {
    if (index > 0) {
      return word.slice(0, 1).toUpperCase() + word.slice(1);
    }
    return word;
  });
  return splitId.join("");
};

/**
 * Update a filter record.
 * @param content
 * @param endpoint
 * @param filterEditDispatch
 * @param setFilterLength
 * @param loadingPage
 * @returns
 */
export const updateFilter = (
  content: Partial<FilterResponse>,
  endpoint: string,
  filterEditDispatch: React.Dispatch<FilterEditAction>,
  setFilterLength: React.Dispatch<React.SetStateAction<number>>,
  loadingPage?: boolean
): Promise<void> => {
  const { id, ...rest } = content;

  return standardClient({
    url: `${endpoint}/${id}`,
    method: "PUT",
    data: {
      ...rest,
    },
  })
    .then(() => {
      toast.success("Attributo modificato correttamente");
      if (!_.isNil(loadingPage))
        getFilterValues(
          endpoint,
          filterEditDispatch,
          setFilterLength,
          loadingPage
        );
    })
    .catch((error: AxiosResponse<StandardResponseError>) => {
      console.log(error);
    });
};

/**
 * Create a filter record.
 * @param content
 * @param endpoint
 * @param filterEditDispatch
 * @param setFilterLength
 * @param loadingPage
 * @returns
 */
export const createFilterRecord = (
  content: Partial<FilterResponse>,
  endpoint: string,
  filterEditDispatch: React.Dispatch<FilterEditAction>,
  setFilterLength: React.Dispatch<React.SetStateAction<number>>,
  updatedList: Array<FilterTableType>,
  loadingPage?: boolean
): Promise<void> =>
  standardClient({
    url: `/${endpoint}`,
    method: "POST",
    data: {
      ...content,
    },
  })
    .then(({ data }: AxiosResponse<FilterResponse>) => {
      toast.success("Attributo creato correttamente");
      const newElemIndex = _.findIndex(updatedList, (elem) => {
        return elem.code == data.code;
      });
      if (newElemIndex) {
        updatedList[newElemIndex].id = data.id;
      }
      updateFilterCollection(
        endpoint,
        updatedList,
        filterEditDispatch,
        setFilterLength,
        loadingPage,
        undefined,
        false
      );
    })
    .catch(() => {
      toast.error("Errore durante la creazione dell'attributo");
      if (!_.isNil(loadingPage))
        getFilterValues(
          endpoint,
          filterEditDispatch,
          setFilterLength,
          loadingPage
        );
    });

/**
 * Delete a filter record.
 * @param content
 * @param endpoint
 * @param filterEditDispatch
 * @param setFilterLength
 * @param loadingPage
 * @returns
 */
export const deleteFilterRecord = (
  content: Partial<FilterResponse>,
  endpoint: string,
  filterEditDispatch: React.Dispatch<FilterEditAction>,
  setFilterLength: React.Dispatch<React.SetStateAction<number>>,
  updatedList: Array<FilterTableType>,
  loadingPage?: boolean
): Promise<void> => {
  const id = content.id;

  return standardClient({
    url: `/${endpoint}/${id}`,
    method: "DELETE",
  })
    .then(() => {
      toast.success("Attributo eliminato correttamente");
      updateFilterCollection(
        endpoint,
        updatedList,
        filterEditDispatch,
        setFilterLength,
        loadingPage,
        undefined,
        false
      );
    })
    .catch(() => {
      toast.error("Errore durante l'eliminazione dell'attributo");
    });
};

/**
 * Update filter collection (call to RPC).
 * @param payload
 * @param filterEditDispatch
 * @param setFilterLength
 * @param loadingPage
 * @returns
 */

export const updateFilterCollection = (
  id: string,
  payload: Array<FilterTableType>,
  filterEditDispatch: React.Dispatch<FilterEditAction>,
  setFilterLength: React.Dispatch<React.SetStateAction<number>>,
  loadingPage?: boolean,
  previousFilterTreeState?: Array<FilterTreeDataObj>,
  cheersToTheUser?: boolean
): Promise<void> => {
  return standardClient({
    url: API.UPDATE_LIST,
    method: "PUT",
    data: {
      referral: _.upperFirst(composeName(id)),
      list: payload,
    },
  })
    .then(() => {
      if (cheersToTheUser) toast.success("Attributi riordinati correttamente");
      if (!_.isNil(loadingPage))
        getFilterValues(id, filterEditDispatch, setFilterLength, loadingPage);
    })
    .catch(() => {
      if (cheersToTheUser)
        toast.error("Errore durante il riordino degli attributi");
      if (previousFilterTreeState) {
        filterEditDispatch({
          type: FilterEditActionConstants.SET_FILTER_TREE_DATA,
          payload: { filterTreeData: previousFilterTreeState },
        });
      }
    });
};
