import React, { useEffect, useReducer, useState, createContext } from "react";
import { Helmet } from "react-helmet";
import { Grid, Header, Form, Icon, Button } from "semantic-ui-react";
import { Formik, ErrorMessage, getIn } from "formik";
import { Field, Input } from "formik-semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import {
  filterEditReducer,
  initialFilterEditState,
  FilterEditState,
} from "./reducer/reducer";
import { FilterEditAction } from "./reducer/actions";
import _ from "lodash";
import { getFilterValues } from "services/filters";
import { PropagateLoader } from "react-spinners";
import SortableTree from "react-sortable-tree";
import { FilterEditActionConstants } from "./reducer/actions";
import {
  ActionButtonsTypes,
  FilterTreeDataObj,
  FilterTreeFormType,
} from "./types";
import {
  updateFilter,
  createFilterRecord,
  deleteFilterRecord,
  updateFilterCollection,
} from "services/filters";
import { getFilterTooltips } from "services/tooltips";
import { FilterResponse } from "services/announcement/types";
import Tooltip from "@material-ui/core/Tooltip";
import { TooltipEdit } from "components/TooltipEdit";

export const FilterEditContext = createContext<{
  state: FilterEditState;
  dispatch: React.Dispatch<FilterEditAction>;
}>({
  state: initialFilterEditState,
  dispatch: () => null,
});

const FilterEdit: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { action, id } = useParams();
  const [state, dispatch] = useReducer(
    filterEditReducer,
    initialFilterEditState
  );
  const [filterLength, setFilterLength] = useState<number>(0);
  const [latestNode, setLatestNode] = useState<FilterTreeDataObj>({
    title: "",
  });

  useEffect(() => {
    if (id) {
      getFilterValues(id, dispatch, setFilterLength, true);
      getFilterTooltips(id, dispatch);
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(state.filterTreeData)) {
      if (
        state.filterTreeData[0].children &&
        state.filterTreeData[0].children.length > 0
      ) {
        if (state.filterTreeData[0].children?.length > filterLength) {
          const nodeInput: HTMLElement | null = document.querySelector(
            `.field .ui.input input[name=${latestNode.id}]`
          );
          if (nodeInput) {
            nodeInput.classList.add("isActive");
            nodeInput.focus();
          }
          setFilterLength(state.filterTreeData[0].children?.length);
        }
      }
    }
  }, [state.filterTreeData]);

  const actionButtons: Array<ActionButtonsTypes> = [
    {
      iconName: "i cursor",
      tooltipLabel: "Modifica",
    },
    {
      iconName: "plus",
      tooltipLabel: "Inserisci",
    },
    {
      iconName: "trash alternate",
      tooltipLabel: "Elimina",
    },
  ];

  const saveButtons: Array<ActionButtonsTypes> = [
    {
      iconName: "save",
      tooltipLabel: "Salva",
    },
    {
      iconName: "close",
      tooltipLabel: "Annulla",
    },
  ];

  const tooltipButtons: Array<ActionButtonsTypes> = [
    state.tooltip.content
      ? {
          iconName: "comment",
          tooltipLabel: "Modifica tooltip",
        }
      : {
          iconName: "plus",
          tooltipLabel: "Aggiungi tooltip",
        },
  ];

  const handleClick = (
    index: number,
    parent: FilterTreeDataObj,
    treeIndex: number,
    node: FilterTreeDataObj
  ) => {
    if (index === 2 && !node.deletable) {
      console.log("Not allowed");
      return;
    }
    if (index === 0) {
      const nodeInput: HTMLElement | null = document.querySelector(
        `.field .ui.input input[name=${node.id}]`
      );
      if (nodeInput) {
        nodeInput.classList.add("isActive");
        nodeInput.focus();
        dispatch({
          type: FilterEditActionConstants.SET_FILTER_TREE_DATA,
          payload: { filterTreeData: [parent] },
        });
      }
    }
    if (index === 1 && parent.children) {
      const newNode = {
        title: "",
        id: "new" + (filterLength + 1),
      };
      parent.children.splice(treeIndex, 0, newNode);
      setLatestNode(newNode);
      dispatch({
        type: FilterEditActionConstants.SET_FILTER_TREE_DATA,
        payload: { filterTreeData: [parent] },
      });
    }
    if (index === 2 && parent.children) {
      const filterEntry = _.find(state.filterValues, (entry) => {
        return entry.code === node.id;
      });
      if (filterEntry && id) {
        const payload: Partial<FilterResponse> = {};
        payload["id"] = filterEntry.id;
        if (parent.children) {
          parent.children[treeIndex - 1].isSubmitting = true;
        }
        const filterValues = state.filterValues;
        filterValues.splice(treeIndex - 1, 1);
        for (let i = treeIndex - 1; i < filterValues.length; i++) {
          filterValues[i].position = i;
        }
        dispatch({
          type: FilterEditActionConstants.SET_FILTER_VALUES,
          payload: { filterValues: filterValues },
        });
        deleteFilterRecord(
          payload,
          id,
          dispatch,
          setFilterLength,
          filterValues,
          false
        ).finally(() => {
          if (parent.children) {
            parent.children[treeIndex - 1].isSubmitting = false;
          }
        });
      }
    }
  };

  const handleSave = (
    parent: FilterTreeDataObj,
    treeIndex: number,
    node: FilterTreeDataObj,
    setFieldValue: (
      field: string,
      value: unknown,
      shouldValidate?: boolean
    ) => void,
    values: FilterTreeFormType,
    setFieldError: (field: string, message: string | undefined) => void,
    index?: number
  ): void => {
    const filterEntry = _.find(state.filterValues, (entry) => {
      return entry.code === node.id;
    });
    const nodeInput: HTMLElement | null = document.querySelector(
      `.field .ui.input input[name=${node.id}]`
    );
    if (!index || index === 0) {
      if (node.id && _.isEmpty(values[node.id])) {
        return setFieldError(node.id, "Il campo è richiesto");
      }
      if (parent.children) {
        parent.children[treeIndex - 1].isSubmitting = true;
        if (node.id) {
          parent.children[treeIndex - 1].title = values[node.id];
        }
      }
      dispatch({
        type: FilterEditActionConstants.SET_FILTER_TREE_DATA,
        payload: { filterTreeData: [parent] },
      });
      const payload: Partial<FilterResponse> = {};
      payload["description"] = node.title;
      if (_.isEmpty(filterEntry)) {
        const codeArray = node.title.split(" ");
        let code = "";
        const length = codeArray.length;
        if (length === 1) {
          code += codeArray[0].slice(0, 5);
        } else {
          let j = 0;
          const sliceLength = Math.floor(5 / length);
          while (j < length) {
            if (j === length - 1) {
              const charsReminder = 5 - _.size(code);
              for (let k = 0; k < charsReminder; k++) {
                if (codeArray[j][k]) {
                  const alphanumeric = /[a-z0-9]+$/i.test(codeArray[j][k]);
                  if (alphanumeric) {
                    code += codeArray[j][k];
                  } else continue;
                } else continue;
              }
            } else {
              for (let i = 0; i < sliceLength; i++) {
                if (codeArray[j][i]) {
                  const alphanumeric = /[a-z0-9]+$/i.test(codeArray[j][i]);
                  if (alphanumeric) {
                    code += codeArray[j][i];
                  } else continue;
                } else continue;
              }
            }
            j++;
          }
        }
        code += filterLength;
        payload["code"] = code;
        payload["position"] = filterLength;
        payload["isDeletable"] = true;
        const filterValues = state.filterValues;
        const newFilter = {
          code: code,
          description: node.title,
          position: treeIndex - 1,
          id: filterLength.toFixed(),
        };
        filterValues.splice(treeIndex - 1, 0, newFilter);
        for (let i = treeIndex; i < filterValues.length; i++) {
          filterValues[i].position = i;
        }
        dispatch({
          type: FilterEditActionConstants.SET_FILTER_VALUES,
          payload: { filterValues: filterValues },
        });
        if (id)
          createFilterRecord(
            payload,
            id,
            dispatch,
            setFilterLength,
            filterValues,
            false
          )
            .then(() => {
              if (parent.children) {
                const currentNode = parent.children[treeIndex - 1];
                parent.children.splice(treeIndex - 1, 1);
                const updatedNode = {
                  title: currentNode.title,
                  id: code,
                  isSubmitting: true,
                  deletable: true,
                };
                parent.children.splice(treeIndex - 1, 0, updatedNode);
                if (node.id) {
                  parent.children[treeIndex - 1].title = values[node.id];
                }
                dispatch({
                  type: FilterEditActionConstants.SET_FILTER_TREE_DATA,
                  payload: { filterTreeData: [parent] },
                });
                setFieldValue(`${updatedNode.id}`, updatedNode.title);
                if (latestNode.id) setFieldValue(latestNode.id, "");
              }
            })
            .finally(() => {
              if (parent.children)
                parent.children[treeIndex - 1].isSubmitting = false;
              if (nodeInput) {
                nodeInput.classList.remove("isActive");
                nodeInput.blur();
              }
            });
      } else {
        const entryId: string | undefined = _.result(filterEntry, "id");
        if (entryId && id) {
          payload["id"] = entryId;
          payload["code"] = filterEntry?.code;
          updateFilter(payload, id, dispatch, setFilterLength, false).finally(
            () => {
              if (nodeInput) {
                nodeInput.classList.remove("isActive");
                nodeInput.blur();
              }
            }
          );
        }
      }
    }
    if (index === 1 && parent.children) {
      if (parent.children[treeIndex - 1].id)
        setFieldValue(parent.children[treeIndex - 1].id || "", "");
      if (_.isEmpty(filterEntry)) {
        parent.children.splice(treeIndex - 1, 1);
        setFilterLength(parent.children.length);
        dispatch({
          type: FilterEditActionConstants.SET_FILTER_TREE_DATA,
          payload: { filterTreeData: [parent] },
        });
      } else {
        const nodeInput: HTMLElement | null = document.querySelector(
          `.field .ui.input input[name=${node.id}]`
        );
        if (nodeInput) {
          nodeInput.classList.remove("isActive");
          nodeInput.blur();
        }
        if (state.filterTreeData && state.filterTreeData[0].children) {
          parent.children[treeIndex - 1].title =
            state.filterTreeData[0].children[treeIndex - 1].title;
          if (node.id)
            setFieldValue(
              node.id,
              state.filterTreeData[0].children[treeIndex - 1].title,
              true
            );
        }
        dispatch({
          type: FilterEditActionConstants.SET_FILTER_TREE_DATA,
          payload: { filterTreeData: [parent] },
        });
      }
    }
  };

  return (
    <FilterEditContext.Provider value={{ state, dispatch }}>
      <Helmet>
        <title>{"Cliccabandi.it - Modifica Filtro"}</title>
        <meta name="description" content="filtri edit page" />
      </Helmet>
      <div className="filterEdit__content">
        <Header className="filterEditHeader" dividing>
          <Grid>
            <Grid.Row className="first-row">
              <Grid.Column>
                <h1>
                  <FormattedMessage
                    id="filterEdit.title"
                    defaultMessage="Modifica filtro"
                  />
                </h1>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Header>
        <div className="filterEditSectionBody">
          {state.isLoading ? (
            <div className="reloader">
              <PropagateLoader color={"var(--c-secondary)"} />
            </div>
          ) : (
            <div style={{ height: "auto" }}>
              <Formik
                initialValues={state.initialFormTreeValues}
                onSubmit={() => {
                  console.log("Submitting...");
                }}
              >
                {({
                  setFieldValue,
                  values,
                  setFieldError,
                  errors,
                  resetForm,
                }) => (
                  <Form>
                    <SortableTree
                      treeData={state.filterTreeData}
                      onChange={(treeData) => {
                        dispatch({
                          type: FilterEditActionConstants.SET_FILTER_TREE_DATA,
                          payload: { filterTreeData: treeData },
                        });
                      }}
                      getNodeKey={({ node }) => node.id}
                      isVirtualized={false}
                      maxDepth={2}
                      onMoveNode={({
                        node,
                        prevTreeIndex,
                        nextTreeIndex,
                        treeData,
                      }) => {
                        let filterValues = state.filterValues;
                        const filterEntry = _.find(
                          state.filterValues,
                          (entry) => {
                            return entry.code === node.id;
                          }
                        );
                        if (filterEntry) {
                          filterValues.splice(prevTreeIndex - 1, 1);
                          filterValues.splice(
                            nextTreeIndex - 1,
                            0,
                            filterEntry
                          );
                        }
                        filterValues = _.map(filterValues, (value, index) => {
                          value.position = index;
                          return value;
                        });
                        dispatch({
                          type: FilterEditActionConstants.SET_FILTER_VALUES,
                          payload: { filterValues: filterValues },
                        });
                        const prevState = treeData;
                        prevState[0].children.splice(nextTreeIndex - 1, 1);
                        prevState[0].children.splice(
                          prevTreeIndex - 1,
                          0,
                          node
                        );
                        if (id) {
                          updateFilterCollection(
                            id,
                            state.filterValues,
                            dispatch,
                            setFilterLength,
                            false,
                            prevState,
                            true
                          );
                        }
                      }}
                      canDrag={({ treeIndex }) => {
                        if (treeIndex === 0) return false;
                        return true;
                      }}
                      canDrop={({ nextParent }) => {
                        if (_.isNull(nextParent)) {
                          return false;
                        }
                        return true;
                      }}
                      generateNodeProps={({ node, parentNode, treeIndex }) => ({
                        title: (
                          <Field name={node.id}>
                            {({ field }) => (
                              <>
                                <Input
                                  name={field.name}
                                  value={field.value}
                                  onChange={(evt, data) => {
                                    setFieldValue(field.name, data.value);
                                  }}
                                  onKeyPress={(event) => {
                                    if (event.charCode === 13) {
                                      dispatch({
                                        type: FilterEditActionConstants.SET_FILTER_TREE_DATA,
                                        payload: {
                                          filterTreeData: [parentNode],
                                        },
                                      });
                                      handleSave(
                                        parentNode,
                                        treeIndex,
                                        node,
                                        setFieldValue,
                                        values,
                                        setFieldError
                                      );
                                      event.target.blur();
                                      event.preventDefault();
                                    }
                                  }}
                                  onClick={() => {
                                    if (getIn(errors, field.name)) {
                                      resetForm();
                                    }
                                  }}
                                  loading={node.isSubmitting}
                                ></Input>
                                <ErrorMessage
                                  name={field.name}
                                  component="span"
                                />
                              </>
                            )}
                          </Field>
                        ),
                        buttons: [
                          node.id !== state.filterTreeData[0].id
                            ? !_.isEmpty(
                                document.querySelector(
                                  `.field .ui.input input[name=${node.id}]`
                                )
                              ) &&
                              document
                                .querySelector(
                                  `.field .ui.input input[name=${node.id}]`
                                )
                                ?.classList.contains("isActive")
                              ? _.map(saveButtons, (button, index) => {
                                  return (
                                    <Tooltip
                                      key={`saveTooltip_${index}`}
                                      title={
                                        button.tooltipLabel
                                          ? button.tooltipLabel
                                          : ""
                                      }
                                      arrow
                                    >
                                      <Button
                                        key={`saveButton_${index}`}
                                        onClick={() =>
                                          handleSave(
                                            parentNode,
                                            treeIndex,
                                            node,
                                            setFieldValue,
                                            values,
                                            setFieldError,
                                            index
                                          )
                                        }
                                      >
                                        <Icon name={button.iconName} />
                                      </Button>
                                    </Tooltip>
                                  );
                                })
                              : _.map(actionButtons, (button, index) => {
                                  return button.tooltipLabel ? (
                                    <Tooltip
                                      key={`actionTooltip_${index}`}
                                      title={
                                        index === 2 && !node.deletable
                                          ? "Attributo non eliminabile"
                                          : button.tooltipLabel
                                      }
                                      classes={{
                                        popper:
                                          index === 2 && !node.deletable
                                            ? "isDisabled"
                                            : undefined,
                                      }}
                                      disableTouchListener
                                      disableFocusListener
                                      arrow
                                    >
                                      <Button
                                        key={`actionButton_${index}`}
                                        onClick={() =>
                                          handleClick(
                                            index,
                                            parentNode,
                                            treeIndex,
                                            node
                                          )
                                        }
                                        className={
                                          index === 2 && !node.deletable
                                            ? "isDisabled"
                                            : undefined
                                        }
                                      >
                                        <Icon name={button.iconName} />
                                      </Button>
                                    </Tooltip>
                                  ) : (
                                    <Button
                                      key={`button_${index}`}
                                      onClick={() =>
                                        handleClick(
                                          index,
                                          parentNode,
                                          treeIndex,
                                          node
                                        )
                                      }
                                      className={
                                        index === 2 && !node.deletable
                                          ? "isDisabled"
                                          : undefined
                                      }
                                    >
                                      <Icon name={button.iconName} />
                                    </Button>
                                  );
                                })
                            : _.map(tooltipButtons, (button, index) => {
                                return (
                                  <>
                                    <Tooltip
                                      key={`tooltipTooltip_${index}`}
                                      title={
                                        button.tooltipLabel
                                          ? button.tooltipLabel
                                          : ""
                                      }
                                      classes={{
                                        popper: "isTooltip",
                                      }}
                                      arrow
                                    >
                                      <Button
                                        key={`tooltipButton_${index}`}
                                        onClick={() =>
                                          dispatch({
                                            type: FilterEditActionConstants.SET_MODAL_OPEN,
                                            payload: { modalOpen: true },
                                          })
                                        }
                                      >
                                        <Icon name={button.iconName} />
                                      </Button>
                                    </Tooltip>
                                    <TooltipEdit
                                      action={
                                        state.tooltip.content
                                          ? "edit"
                                          : "create"
                                      }
                                      id={id}
                                    />
                                  </>
                                );
                              }),
                        ],
                      })}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          )}
        </div>
      </div>
    </FilterEditContext.Provider>
  );
};

export default FilterEdit;
