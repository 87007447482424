import React, { useEffect, useState } from "react";
import { ErrorMessage } from "formik";
import { PasswordInputProps } from "./types";
import { Icon } from "semantic-ui-react";
import { Input } from "formik-semantic-ui-react";

export const PasswordInputFormik: React.FC<PasswordInputProps> = ({
  field,
  setFieldValue = undefined,
  placeholder = undefined,
  label = undefined,
  onChange = undefined,
  onBlur = undefined,
}) => {
  const [value, setValue] = useState(field.value);
  /**
   * Update the local value when the field value is changed from outside the component.
   */
  useEffect(() => {
    setValue(field.value);
  }, [field.value]);

  const [inputFieldType, setInputFieldType] = useState("password");

  const togglePassword = () => {
    if (inputFieldType === "password") {
      setInputFieldType("text");
    } else {
      setInputFieldType("password");
    }
  };

  return (
    <>
      <Input
        name={field.name}
        type={inputFieldType}
        autocomplete="off"
        icon={
          inputFieldType === "password" ? (
            <Icon name="eye" className="link" onClick={togglePassword} />
          ) : (
            <Icon name="eye slash" className="link" onClick={togglePassword} />
          )
        }
        placeholder={placeholder}
        label={label}
        value={value}
        onChange={(event, data) => {
          setValue(data.value || "");
          setFieldValue && setFieldValue(field.name, data.value);
        }}
        onBlur={(event) => onBlur && onBlur(event)}
      />
      <ErrorMessage name="password" component="span" />
    </>
  );
};
