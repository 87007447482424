import React, { useEffect, useState } from "react";
import { AnnouncementContainerProps } from "./types/types";
import { Card, Icon, Image } from "semantic-ui-react";
import { FE_ROUTES } from "utils/global/globalCostants";
import { Link } from "react-router-dom";
import { formatStatusValue } from "utils/function/announcement";
import newIcon from "assets/images/icon/new.svg";
import { setNewAnnouncement } from "utils/function/setNewAnnouncement";

const AnnouncementContainer: React.FC<AnnouncementContainerProps> = ({
  announcementValue,
}) => {
  const [isNew, setIsNew] = useState<boolean>(false);

  const geographicalReference =
    announcementValue.geographicalReference.length > 0
      ? announcementValue.geographicalReference.map((val) => val.description)
      : ["Nazionale"];

  useEffect(() => {
    setIsNew(
      setNewAnnouncement(
        announcementValue.createdAt,
        announcementValue.openingDate
      )
    );
  }, []);

  return (
    <Card>
      <div className="content">
        <div className="header">
          {announcementValue.title}
          {isNew && <Image src={newIcon} />}
        </div>
        <div className="ui sub header">Riferimento geografico</div>
        <div className="ui sub content">{geographicalReference.join(", ")}</div>
        <div className="ui sub header">Stato</div>
        <div className="ui sub content">
          {formatStatusValue(announcementValue.status)}
        </div>
      </div>
      <div className="content">
        <div className="description">
          {announcementValue?.previewDescription
            ? announcementValue?.previewDescription
            : ""}
        </div>
        <div className="cta">
          <Link to={`${FE_ROUTES.ELENCO_BANDI}/view/${announcementValue.id}`}>
            Scopri il bando
          </Link>
          <Icon name="angle right" />
        </div>
      </div>
    </Card>
  );
};

export default AnnouncementContainer;
