import { Announcement } from "../../Announcement/types";

export enum AnnouncementDetailActionConstants {
  SET_ANNOUNCEMENT = "AnnouncementDetail/SET_ANNOUNCEMENT",
  SET_IS_RELOADING = "AnnouncementDetail/SET_RELOADING",
  SET_IS_LOADING = "AnnouncementDetail/SET_IS_LOADING",
  SET_IS_LOADING_ATTACHMENT = "AnnouncementDetail/SET_IS_LOADING_ATTACHMENT",
  SET_IS_LOADING_PDF = "AnnouncementDetail/SET_IS_LOADING_PDF",
  SET_LOADING_INDEX = "AnnouncementDetail/SET_LOADING_INDEX",
}

export type AnnouncementDetailAction =
  | {
      type: AnnouncementDetailActionConstants.SET_ANNOUNCEMENT;
      payload: { announcement: Announcement | null };
    }
  | {
      type: AnnouncementDetailActionConstants.SET_IS_RELOADING;
      payload: { isReloading: boolean };
    }
  | {
      type: AnnouncementDetailActionConstants.SET_IS_LOADING;
      payload: { isLoading: boolean };
    }
  | {
      type: AnnouncementDetailActionConstants.SET_IS_LOADING_ATTACHMENT;
      payload: { isLoadingAttachment: boolean };
    }
  | {
      type: AnnouncementDetailActionConstants.SET_IS_LOADING_PDF;
      payload: { isLoadingPdf: boolean };
    }
  | {
      type: AnnouncementDetailActionConstants.SET_LOADING_INDEX;
      payload: { loadingIndex: number | null };
    };
