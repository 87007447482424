import React, { useEffect, useReducer } from "react";
import { Helmet } from "react-helmet";
import { Grid, Header, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { API } from "utils/global/backendRoutes";
import DataGrid from "components/DataGrid";
import { FE_ROUTES } from "utils/global/globalCostants";
import { FilterFormatterParams } from "components/DataGrid/types";
import { computeFilters } from "utils/function/computeFilters";
import {
  filterManagementReducer,
  initialFilterManagementState,
} from "./reducer/reducer";
import { FilterManagementActionConstants } from "./reducer/actions";
import Tooltip from "@material-ui/core/Tooltip";

const FilterManagement: React.FC = () => {
  const [state, dispatch] = useReducer(
    filterManagementReducer,
    initialFilterManagementState
  );

  const endpointList = [
    API.ECONOMIC_ACTIVITY,
    API.ELIGIBLE_COST,
    API.COMPANY_SIZE,
    API.PROMOTING_INSTITUTION,
    API.GEOGRAPHICAL_REFERENCE,
    API.TOPIC,
    API.FUNDING_TYPE,
    API.APPLICANT_COMPANY_FORM,
  ];

  useEffect(() => {
    dispatch({
      type: FilterManagementActionConstants.SET_FILTER_LIST,
      payload: { filters: computeFilters(endpointList) },
    });
  }, []);

  /**
   * Manages page selection change.
   * @param data
   */
  // const handleSelectPage = (data: string | number) => {
  //   dataGridDispatch({
  //     type: DataGridActionConstants.SET_TABLE_PAGE_NUMBER,
  //     payload: {
  //       pageNumber: data,
  //     },
  //   });
  // };

  return (
    <>
      <div className="filter-management-container">
        <Helmet>
          <body className="filter list" />
          <title>CliccaBandi.it - Gestione filtri</title>
          <meta name="description" content="gestione list page" />
        </Helmet>
        <div className="filter_management__content">
          <Header className="filterManagementHeader" dividing>
            <Grid>
              <Grid.Row className="first-row">
                <Grid.Column>
                  <h1>
                    <FormattedMessage
                      id="filterManagement.title"
                      defaultMessage="Gestione filtri"
                    />
                  </h1>
                </Grid.Column>
                <Grid.Column className="buttons"></Grid.Column>
              </Grid.Row>
            </Grid>
            {/* <TableFilterForm
              searchFieldPlaceHolder={
                <FormattedMessage
                  id="announcement.announcementTitle"
                  defaultMessage="Ricerca per titolo"
                />
              }
              defaultOrderValue={"earliest"}
              onSubmit={onSubmit}
            /> */}
          </Header>

          <div className="announcementManagementSectionBody">
            <DataGrid
              isLoading={false}
              elements={state.filters}
              columns={[
                {
                  key: "title",
                  name: "",
                  formatter: ({ data }: FilterFormatterParams) => {
                    return (
                      <Tooltip
                        title={data.title ? data.title : ""}
                        classes={{
                          popper: "isGrey",
                        }}
                        arrow
                        placement="top"
                        enterDelay={800}
                      >
                        <div className="list_title">{data.title}</div>
                      </Tooltip>
                    );
                  },
                },
                {
                  key: "action",
                  name: "",
                  formatter: ({ data }: FilterFormatterParams) => {
                    const filterLink = `${FE_ROUTES.GESTIONE_FILTRI}/edit/${data.link}`;
                    return (
                      <Tooltip
                        title="Modifica"
                        classes={{
                          popper: "isGrey",
                        }}
                        arrow
                      >
                        <Link to={filterLink}>
                          <Icon name="pencil alternate" />
                        </Link>
                      </Tooltip>
                    );
                  },
                },
              ]}
              totalItems={8}
              page={1}
              onPageSelect={() => console.log("Page")}
              paginate={false}
              pageSize={8}
              pageCount={1}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterManagement;
