import { Announcement } from "../../Announcement/types";
import {
  AnnouncementDetailAction,
  AnnouncementDetailActionConstants,
} from "./actions";

export type AnnouncementDetailState = {
  announcement: Announcement | null;
  isReloading: boolean;
  isLoading: boolean;
  isLoadingAttachment: boolean;
  isLoadingPdf: boolean;
  loadingIndex: number | null;
};

export const initialAnnouncementDetailState: AnnouncementDetailState = {
  announcement: null,
  isReloading: false,
  isLoading: false,
  isLoadingAttachment: false,
  isLoadingPdf: false,
  loadingIndex: null,
};

export function announcementDetailReducer(
  state: AnnouncementDetailState,
  action: AnnouncementDetailAction
): AnnouncementDetailState {
  const { type } = action;

  switch (type) {
    case AnnouncementDetailActionConstants.SET_ANNOUNCEMENT:
      return { ...state, announcement: action.payload.announcement };
    case AnnouncementDetailActionConstants.SET_IS_RELOADING:
      return { ...state, isReloading: action.payload.isReloading };
    case AnnouncementDetailActionConstants.SET_IS_LOADING:
      return { ...state, isLoading: action.payload.isLoading };
    case AnnouncementDetailActionConstants.SET_IS_LOADING_ATTACHMENT:
      return {
        ...state,
        isLoadingAttachment: action.payload.isLoadingAttachment,
      };
    case AnnouncementDetailActionConstants.SET_IS_LOADING_PDF:
      return {
        ...state,
        isLoadingPdf: action.payload.isLoadingPdf,
      };
    case AnnouncementDetailActionConstants.SET_LOADING_INDEX:
      return {
        ...state,
        loadingIndex: action.payload.loadingIndex,
      };
    default:
      return state;
  }
}
