import { Announcement } from "../types";

export enum AnnouncementActionConstants {
  SET_ANNOUNCEMENT_LIST = "Announcement/SET_ANNOUNCEMENT_LIST",
  SET_ANNOUNCEMENT_IS_LOADING = "Announcement/SET_ANNOUNCEMENT_IS_LOADING",
  SET_ANNOUNCEMENT_GENERATING_EXPORT = "Announcement/SET_ANNOUNCEMENT_GENERATING_EXPORT",
  SET_ANNOUNCEMENT_ORDERBY = "Announcement/SET_ANNOUNCEMENT_ORDERBY",
  SET_ANNOUNCEMENT_PAGE_NUMBER = "Announcement/SET_ANNOUNCEMENT_PAGE_NUMBER",
  SET_ANNOUNCEMENT_PAGE_TOTAL_ITEMS = "Announcement/SET_ANNOUNCEMENT_PAGE_TOTAL_ITEMS",
  SET_ANNOUNCEMENT_PAGE_PAGE_COUNT = "Announcement/SET_ANNOUNCEMENT_PAGE_PAGE_COUNT",
  SET_ANNOUNCEMENT_FILTER_STATUS = "Announcement/SET_ANNOUNCEMENT_FILTER_STATUS",
  SET_ANNOUNCEMENT_FILTER_GEOGRAPHICAL_REFERENCE = "Announcement/SET_ANNOUNCEMENT_FILTER_GEOGRAPHICAL_REFERENCE",
  SET_ANNOUNCEMENT_FILTER_ECONOMIC_ACTIVITY = "Announcement/SET_ANNOUNCEMENT_FILTER_ECONOMIC_ACTIVITY",
  SET_ANNOUNCEMENT_FILTER_COMPANY_SIZE = "Announcement/SET_ANNOUNCEMENT_FILTER_COMPANY_SIZE",
  SET_ANNOUNCEMENT_FILTER_TOPIC = "Announcement/SET_ANNOUNCEMENT_FILTER_TOPIC",
  SET_ANNOUNCEMENT_FILTER_FUNDING_TYPE = "Announcement/SET_ANNOUNCEMENT_FILTER_FUNDING_TYPE",
  SET_ANNOUNCEMENT_FILTER_APPLICANT_COMPANY_FORM = "Announcement/SET_ANNOUNCEMENT_FILTER_APPLICANT_COMPANY_FORM",
  SET_ANNOUNCEMENT_FILTER_ELIGIBLE_COST = "Announcement/SET_ANNOUNCEMENT_FILTER_ELIGIBLE_COST",
  SET_ANNOUNCEMENT_FILTER_PROMOTING_INSTITUTION = "Announcement/SET_ANNOUNCEMENT_FILTER_PROMOTING_INSTITUTION",
  SET_ANNOUNCEMENT_FILTER_MINFINANCEDCONTRIBUTE = "Announcement/SET_ANNOUNCEMENT_FILTER_MINFINANCEDCONTRIBUTE",
  SET_ANNOUNCEMENT_FILTER_MAXFINANCEDCONTRIBUTE = "Announcement/SET_ANNOUNCEMENT_FILTER_MAXFINANCEDCONTRIBUTE",
  SET_ANNOUNCEMENT_FILTER_KEYWORD = "Announcement/SET_ANNOUNCEMENT_FILTER_PROMOTING_KEYWORD",
  RESET_FILTERS = "Announcement/RESET_FILTERS",
}

export type AnnouncementAction =
  | {
      type: AnnouncementActionConstants.SET_ANNOUNCEMENT_PAGE_NUMBER;
      payload: { pageNumber: number | string };
    }
  | {
      type: AnnouncementActionConstants.SET_ANNOUNCEMENT_PAGE_TOTAL_ITEMS;
      payload: { total_items: number };
    }
  | {
      type: AnnouncementActionConstants.SET_ANNOUNCEMENT_PAGE_PAGE_COUNT;
      payload: { page_count: number };
    }
  | {
      type: AnnouncementActionConstants.SET_ANNOUNCEMENT_IS_LOADING;
      payload: { isLoading: boolean };
    }
  | {
      type: AnnouncementActionConstants.SET_ANNOUNCEMENT_GENERATING_EXPORT;
      payload: { generatingReport: boolean };
    }
  | {
      type: AnnouncementActionConstants.SET_ANNOUNCEMENT_ORDERBY;
      payload: { orderBy: string | null };
    }
  | {
      type: AnnouncementActionConstants.SET_ANNOUNCEMENT_LIST;
      payload: { announcementList: Array<Announcement> };
    }
  | {
      type: AnnouncementActionConstants.SET_ANNOUNCEMENT_FILTER_STATUS;
      payload: { status: Array<string> };
    }
  | {
      type: AnnouncementActionConstants.SET_ANNOUNCEMENT_FILTER_GEOGRAPHICAL_REFERENCE;
      payload: { geographicalReference: Array<string> };
    }
  | {
      type: AnnouncementActionConstants.SET_ANNOUNCEMENT_FILTER_ECONOMIC_ACTIVITY;
      payload: { economicActivity: Array<string> };
    }
  | {
      type: AnnouncementActionConstants.SET_ANNOUNCEMENT_FILTER_COMPANY_SIZE;
      payload: { companySize: Array<string> };
    }
  | {
      type: AnnouncementActionConstants.SET_ANNOUNCEMENT_FILTER_TOPIC;
      payload: { topic: Array<string> };
    }
  | {
      type: AnnouncementActionConstants.SET_ANNOUNCEMENT_FILTER_FUNDING_TYPE;
      payload: { fundingType: Array<string> };
    }
  | {
      type: AnnouncementActionConstants.SET_ANNOUNCEMENT_FILTER_APPLICANT_COMPANY_FORM;
      payload: { applicantCompanyForm: Array<string> };
    }
  | {
      type: AnnouncementActionConstants.SET_ANNOUNCEMENT_FILTER_ELIGIBLE_COST;
      payload: { eligibleCost: Array<string> };
    }
  | {
      type: AnnouncementActionConstants.SET_ANNOUNCEMENT_FILTER_PROMOTING_INSTITUTION;
      payload: { promotingInstitution: Array<string> };
    }
  | {
      type: AnnouncementActionConstants.SET_ANNOUNCEMENT_FILTER_MINFINANCEDCONTRIBUTE;
      payload: { minFinancedContribute: string };
    }
  | {
      type: AnnouncementActionConstants.SET_ANNOUNCEMENT_FILTER_MAXFINANCEDCONTRIBUTE;
      payload: { maxFinancedContribute: string };
    }
  | {
      type: AnnouncementActionConstants.SET_ANNOUNCEMENT_FILTER_KEYWORD;
      payload: { keyword: string };
    }
  | {
      type: AnnouncementActionConstants.RESET_FILTERS;
    };
