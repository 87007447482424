import { MySelf } from "utils/types/mySelf";
import { FooterStateType, MenuItem } from "../types";
import { AppAction, appActionConstants } from "./actions";

export type AppState = {
  isAuthenticated: boolean | undefined;
  isUserLoading: boolean;
  mySelf: MySelf | undefined;
  isBlocked: boolean;
  remainingAttempts: number | undefined;
  footerState: FooterStateType;
  topbarState: Array<MenuItem>;
  logo: string;
  waveBg: string;
};

export const initialAppState: AppState = {
  isAuthenticated: undefined,
  isUserLoading: false,
  mySelf: undefined,
  isBlocked: false,
  remainingAttempts: undefined,
  footerState: {
    iconList: [],
    copyright: {
      client: "",
      text: "",
    },
    sponsor: [],
    items: [],
  },
  topbarState: [],
  logo: "",
  waveBg: "",
};

export function appReducer(state: AppState, action: AppAction): AppState {
  const { type } = action;

  switch (type) {
    case appActionConstants.SET_SESSION:
      return { ...state, isAuthenticated: true };
    case appActionConstants.SET_USER_LOADING:
      return { ...state, isUserLoading: action.payload.isLoading };
    case appActionConstants.REVOKE_SESSION:
      return { ...state, isAuthenticated: false, mySelf: undefined };
    case appActionConstants.SET_MYSELF:
      return { ...state, mySelf: action.payload.mySelf };
    case appActionConstants.SET_USER_BLOCKED:
      return { ...state, isBlocked: true };
    case appActionConstants.SET_REMAINING_ATTEMPTS:
      return { ...state, remainingAttempts: action.payload.remainingAttempts };
    case appActionConstants.SET_FOOTER_STATE:
      return { ...state, footerState: action.payload.footerState };
    case appActionConstants.SET_TOPBAR_STATE:
      return { ...state, topbarState: action.payload.topbarState };
    case appActionConstants.SET_LOGO:
      return { ...state, logo: action.payload.logo };
    case appActionConstants.SET_WAVE_BG:
      return { ...state, waveBg: action.payload.waveBg };
    default:
      return state;
  }
}
