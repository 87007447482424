import {
  TableFilterFormAction,
  TableFilterFormActionConstants,
} from "./actions";

export type TableFilterFormState = {
  orderBy: string | null;
  searchText: string | null;
  userType: string | null;
};

export const initialTableFilterFormState: TableFilterFormState = {
  orderBy: null,
  searchText: "",
  userType: null,
};

export function tableFilterFormReducer(
  state: TableFilterFormState,
  action: TableFilterFormAction
): TableFilterFormState {
  const { type } = action;

  switch (type) {
    case TableFilterFormActionConstants.SET_ORDER_BY:
      return {
        ...state,
        orderBy: action.payload.orderBy,
      };
    case TableFilterFormActionConstants.SET_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.payload.searchText,
      };
    case TableFilterFormActionConstants.SET_USER_TYPE:
      return {
        ...state,
        userType: action.payload.userType,
      };
    case TableFilterFormActionConstants.SET_FILTERS:
      return {
        ...state,
        searchText: action.payload.searchText,
        orderBy: action.payload.orderBy,
        userType: action.payload.userType,
      };
    default:
      return state;
  }
}
