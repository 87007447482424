import { FilterTableGridType } from "../types";
import { FilterTableType } from "pages/FilterEdit/types";
import {
  FilterManagementAction,
  FilterManagementActionConstants,
} from "./actions";

export type FilterManagementState = {
  filters: Array<FilterTableGridType>;
  isLoading: boolean;
  geographicReferenceOptions: Array<FilterTableType>;
  economicActivityOptions: Array<FilterTableType>;
  companySizeOptions: Array<FilterTableType>;
  topicOptions: Array<FilterTableType>;
  fundingTypeOptions: Array<FilterTableType>;
  applicantCompanyFormOptions: Array<FilterTableType>;
  eligibleCostOptions: Array<FilterTableType>;
  promotingInstitutionOptions: Array<FilterTableType>;
  isLoadingEconomicActivity: boolean;
  isLoadingGeoReference: boolean;
  isLoadingCompanySize: boolean;
  isLoadingTopic: boolean;
  isLoadingFundingType: boolean;
  isLoadingApplicantCompanyForm: boolean;
  isLoadingEligibleCost: boolean;
  isLoadingPromotingInstitution: boolean;
};

export const initialFilterManagementState: FilterManagementState = {
  filters: [],
  isLoading: true,
  isLoadingEconomicActivity: true,
  isLoadingGeoReference: true,
  isLoadingCompanySize: true,
  isLoadingTopic: true,
  isLoadingFundingType: true,
  isLoadingApplicantCompanyForm: true,
  isLoadingEligibleCost: true,
  isLoadingPromotingInstitution: true,
  geographicReferenceOptions: [],
  economicActivityOptions: [],
  companySizeOptions: [],
  topicOptions: [],
  fundingTypeOptions: [],
  applicantCompanyFormOptions: [],
  eligibleCostOptions: [],
  promotingInstitutionOptions: [],
};

export function filterManagementReducer(
  state: FilterManagementState,
  action: FilterManagementAction
): FilterManagementState {
  const { type } = action;

  switch (type) {
    case FilterManagementActionConstants.SET_FILTER_LIST:
      return {
        ...state,
        filters: action.payload.filters,
      };
    // case FilterManagementActionConstants.SET_GEOGRAPHIC_REFERENCE_OPTIONS:
    //   return {
    //     ...state,
    //     geographicReferenceOptions: action.payload.geographicReferenceOptions,
    //   };
    // case FilterManagementActionConstants.SET_ECONOMIC_ACTIVITY_OPTIONS:
    //   return {
    //     ...state,
    //     economicActivityOptions: action.payload.economicActivityOptions,
    //   };
    // case FilterManagementActionConstants.SET_COMPANY_SIZE_OPTIONS:
    //   return {
    //     ...state,
    //     companySizeOptions: action.payload.companySizeOptions,
    //   };
    // case FilterManagementActionConstants.SET_TOPIC_OPTIONS:
    //   return {
    //     ...state,
    //     topicOptions: action.payload.topicOptions,
    //   };
    // case FilterManagementActionConstants.SET_FUNDING_TYPE_OPTIONS:
    //   return {
    //     ...state,
    //     fundingTypeOptions: action.payload.fundingTypeOptions,
    //   };
    // case FilterManagementActionConstants.SET_APPLICANT_COMPANY_FORM_OPTIONS:
    //   return {
    //     ...state,
    //     applicantCompanyFormOptions: action.payload.applicantCompanyFormOptions,
    //   };
    // case FilterManagementActionConstants.SET_ELIGIBLE_COST_OPTIONS:
    //   return {
    //     ...state,
    //     eligibleCostOptions: action.payload.eligibleCostOptions,
    //   };
    // case FilterManagementActionConstants.SET_PROMOTING_INSTITUTION_OPTIONS:
    //   return {
    //     ...state,
    //     promotingInstitutionOptions: action.payload.promotingInstitutionOptions,
    //   };

    // //---- LOADING ACTIONS ----//

    // case FilterManagementActionConstants.SET_IS_LOADING:
    //   return {
    //     ...state,
    //     isLoading: action.payload.isLoading,
    //   };
    // case FilterManagementActionConstants.SET_IS_LOADING_COMPANY_SIZE:
    //   return {
    //     ...state,
    //     isLoadingCompanySize: action.payload.isLoadingCompanySize,
    //   };
    // case FilterManagementActionConstants.SET_IS_LOADING_ECONOMIC_ACTIVITY:
    //   return {
    //     ...state,
    //     isLoadingEconomicActivity: action.payload.isLoadingEconomicActivity,
    //   };
    // case FilterManagementActionConstants.SET_IS_LOADING_GEOGRAPHIC_REFERENCE:
    //   return {
    //     ...state,
    //     isLoadingGeoReference: action.payload.isLoadingGeoReference,
    //   };
    // case FilterManagementActionConstants.SET_IS_LOADING_TOPIC:
    //   return {
    //     ...state,
    //     isLoadingTopic: action.payload.isLoadingTopic,
    //   };
    // case FilterManagementActionConstants.SET_IS_LOADING_FUNDING_TYPE:
    //   return {
    //     ...state,
    //     isLoadingFundingType: action.payload.isLoadingFundingType,
    //   };
    // case FilterManagementActionConstants.SET_IS_LOADING_APPLICANT_COMPANY_FORM:
    //   return {
    //     ...state,
    //     isLoadingApplicantCompanyForm:
    //       action.payload.isLoadingApplicantCompanyForm,
    //   };
    // case FilterManagementActionConstants.SET_IS_LOADING_ELIGIBLE_COST:
    //   return {
    //     ...state,
    //     isLoadingEligibleCost: action.payload.isLoadingEligibleCost,
    //   };
    // case FilterManagementActionConstants.SET_IS_LOADING_PROMOTING_INSTITUTION:
    //   return {
    //     ...state,
    //     isLoadingPromotingInstitution:
    //       action.payload.isLoadingPromotingInstitution,
    //   };
    default:
      return state;
  }
}
