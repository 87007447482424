import { FilterAnnouncementFormValues } from "components/Forms/FilterAnnouncementForm/types";
import { Announcement } from "../types";
import { AnnouncementAction, AnnouncementActionConstants } from "./actions";
import { PaginationData } from "components/DataGrid/types";

export type FormValues = {
  orderByValue: string;
};

export type FilterType = {
  type: string;
  field: string;
  values?: string;
  value?: string;
  alias?: string;
};

export type AnnouncementState = {
  isLoading: boolean;
  generatingExport: boolean;
  announcementList: Array<Announcement>;
  filters: FilterAnnouncementFormValues;
  orderByValue: string | null;
  paginationData: PaginationData;
};

export const initialAnnouncementState: AnnouncementState = {
  isLoading: true,
  generatingExport: false,
  announcementList: [],
  filters: {
    status: [],
    geographicalReference: [],
    companySize: [],
    economicActivity: [],
    topic: [],
    fundingType: [],
    applicantCompanyForm: [],
    eligibleCost: [],
    promotingInstitution: [],
    minFinancedContribute: undefined,
    maxFinancedContribute: undefined,
    keyword: null,
  },
  orderByValue: null,
  paginationData: {
    page: 1,
    page_count: 0,
    page_size: 8,
    total_items: 0,
  },
};

export function announcementReducer(
  state: AnnouncementState,
  action: AnnouncementAction
): AnnouncementState {
  const { type } = action;

  switch (type) {
    case AnnouncementActionConstants.SET_ANNOUNCEMENT_LIST:
      return { ...state, announcementList: action.payload.announcementList };
    case AnnouncementActionConstants.SET_ANNOUNCEMENT_IS_LOADING:
      return { ...state, isLoading: action.payload.isLoading };
    case AnnouncementActionConstants.SET_ANNOUNCEMENT_GENERATING_EXPORT:
      return { ...state, generatingExport: action.payload.generatingReport };
    case AnnouncementActionConstants.SET_ANNOUNCEMENT_ORDERBY:
      return { ...state, orderByValue: action.payload.orderBy };
    case AnnouncementActionConstants.SET_ANNOUNCEMENT_PAGE_NUMBER:
      return {
        ...state,
        paginationData: {
          ...state.paginationData,
          page: action.payload.pageNumber,
        },
      };
    case AnnouncementActionConstants.SET_ANNOUNCEMENT_PAGE_TOTAL_ITEMS: {
      return {
        ...state,
        paginationData: {
          ...state.paginationData,
          total_items: action.payload.total_items,
        },
      };
    }
    case AnnouncementActionConstants.SET_ANNOUNCEMENT_PAGE_PAGE_COUNT: {
      return {
        ...state,
        paginationData: {
          ...state.paginationData,
          page_count: action.payload.page_count,
        },
      };
    }
    case AnnouncementActionConstants.SET_ANNOUNCEMENT_FILTER_STATUS:
      return {
        ...state,
        filters: {
          ...state.filters,
          status: action.payload.status,
        },
      };
    case AnnouncementActionConstants.SET_ANNOUNCEMENT_FILTER_GEOGRAPHICAL_REFERENCE:
      return {
        ...state,
        filters: {
          ...state.filters,
          geographicalReference: action.payload.geographicalReference,
        },
      };
    case AnnouncementActionConstants.SET_ANNOUNCEMENT_FILTER_ECONOMIC_ACTIVITY:
      return {
        ...state,
        filters: {
          ...state.filters,
          economicActivity: action.payload.economicActivity,
        },
      };
    case AnnouncementActionConstants.SET_ANNOUNCEMENT_FILTER_COMPANY_SIZE:
      return {
        ...state,
        filters: {
          ...state.filters,
          companySize: action.payload.companySize,
        },
      };
    case AnnouncementActionConstants.SET_ANNOUNCEMENT_FILTER_TOPIC:
      return {
        ...state,
        filters: {
          ...state.filters,
          topic: action.payload.topic,
        },
      };
    case AnnouncementActionConstants.SET_ANNOUNCEMENT_FILTER_FUNDING_TYPE:
      return {
        ...state,
        filters: {
          ...state.filters,
          fundingType: action.payload.fundingType,
        },
      };
    case AnnouncementActionConstants.SET_ANNOUNCEMENT_FILTER_APPLICANT_COMPANY_FORM:
      return {
        ...state,
        filters: {
          ...state.filters,
          applicantCompanyForm: action.payload.applicantCompanyForm,
        },
      };
    case AnnouncementActionConstants.SET_ANNOUNCEMENT_FILTER_ELIGIBLE_COST:
      return {
        ...state,
        filters: {
          ...state.filters,
          eligibleCost: action.payload.eligibleCost,
        },
      };
    case AnnouncementActionConstants.SET_ANNOUNCEMENT_FILTER_PROMOTING_INSTITUTION:
      return {
        ...state,
        filters: {
          ...state.filters,
          promotingInstitution: action.payload.promotingInstitution,
        },
      };
    case AnnouncementActionConstants.SET_ANNOUNCEMENT_FILTER_KEYWORD:
      return {
        ...state,
        filters: {
          ...state.filters,
          keyword: action.payload.keyword,
        },
      };
    case AnnouncementActionConstants.SET_ANNOUNCEMENT_FILTER_MINFINANCEDCONTRIBUTE:
      return {
        ...state,
        filters: {
          ...state.filters,
          minFinancedContribute: action.payload.minFinancedContribute,
        },
      };
    case AnnouncementActionConstants.SET_ANNOUNCEMENT_FILTER_MAXFINANCEDCONTRIBUTE:
      return {
        ...state,
        filters: {
          ...state.filters,
          maxFinancedContribute: action.payload.maxFinancedContribute,
        },
      };
    case AnnouncementActionConstants.RESET_FILTERS:
      return {
        ...state,
        filters: initialAnnouncementState.filters,
      };
    default:
      return state;
  }
}
