type DefaultValueType =
  | {
      direction: string | null;
      field: string | null;
    }
  | undefined;

type FilterDirectionType = {
  type: string;
  field: string | null;
  direction: string | null;
};

export const getOrderByObject = (
  orderBy: string | null,
  orderFieldValue: string,
  defaultValue: DefaultValueType
): Array<FilterDirectionType> => {
  let field = defaultValue?.field || null;
  let direction = defaultValue?.direction || null;
  switch (orderBy) {
    case "descendingOrder":
      field = orderFieldValue;
      direction = "desc";
      break;
    case "ascendingOrder":
      field = orderFieldValue;
      direction = "asc";
      break;
    case "oldest":
      field = "createdAt";
      direction = "asc";
      break;
    case "earliest":
      field = "createdAt";
      direction = "desc";
      break;
    case "latestArchived":
      field = "archivalDate";
      direction = "desc";
      break;
    case "oldestArchived":
      field = "archivalDate";
      direction = "asc";
      break;
    default:
      field;
      direction;
  }
  return [
    {
      type: "field",
      field,
      direction,
    },
  ];
};
