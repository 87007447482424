import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { Button, Header, Segment, Icon } from "semantic-ui-react";
import { isThatRole } from "utils/function/acl";
import { AppContext } from "pages/App";
import { ROLES } from "utils/global/globalCostants";
import { FE_ROUTES } from "utils/global/globalCostants";
import { Link } from "react-router-dom";

const HomePage: React.FC = () => {
  const { state } = useContext(AppContext);

  return (
    <div className="homepage-container">
      <Helmet>
        <body className="homepage" />
        <title>Homepage</title>
        <meta name="description" content="Description of Homepage" />
      </Helmet>
      <Segment textAlign="center">
        <Header className="homeHeader">
          <h1>Benvenuto in CliccaBandi</h1>
          <p>Seleziona l&apos;area a cui desideri accedere</p>
        </Header>

        <div className="homeTiles">
          {(isThatRole(ROLES.ADMIN, state) ||
            isThatRole(ROLES.SUPERVISOR, state) ||
            isThatRole(ROLES.EDITOR, state)) && (
            <Link to={FE_ROUTES.GESTIONE_BANDI}>
              <Segment className="homeTile tile">
                <Icon name="edit" />
                <h2 className="tileTitle">Gestione bandi</h2>
                <p className="tileDescription">
                  Nell&apos;area di Gestione bandi potrai creare nuovi bandi ed
                  effettuare modifiche ed altre operazioni a quelli già censiti
                </p>
                <Button primary>Gestisci</Button>
              </Segment>
            </Link>
          )}

          <Link to={FE_ROUTES.ELENCO_BANDI}>
            <Segment className="homeTile tile">
              <Icon name="search" />
              <h2 className="tileTitle">Ricerca Bandi</h2>
              <p className="tileDescription">
                Da qui puoi accedere alla pagina di ricerca dei bandi: potrai
                trovare bandi relativi al PNRR e alla finanza agevolata
              </p>
              <Button primary>Bandi</Button>
            </Segment>
          </Link>

          {(isThatRole(ROLES.ADMIN, state) ||
            isThatRole(ROLES.SUPERVISOR, state) ||
            isThatRole(ROLES.BANK_ADMIN, state)) && (
            <Link to={FE_ROUTES.UTENTI}>
              <Segment className="homeTile tile">
                <Icon name="user circle" />
                <h2 className="tileTitle">Lista utenti</h2>
                <p className="tileDescription">
                  Accedi all&apos;area utenti: qui potrai visualizzare
                  l&apos;elenco degli utenti abilitati all&apos;utilizzo del
                  portale, il loro ruolo e creare nuovi profili{" "}
                </p>
                <Button primary>Utenti</Button>
              </Segment>
            </Link>
          )}
        </div>
      </Segment>
    </div>
  );
};

export default HomePage;
