import React, { useContext } from "react";
import { AppContext } from "pages/App";
import { ContactType, supportOptions } from "./types";
import { sendMessage } from "services/contact";
import { Formik, ErrorMessage } from "formik";
import {
  TextArea,
  Form,
  Input,
  Select,
  SubmitButton,
} from "formik-semantic-ui-react";
import { FormattedMessage } from "react-intl";
import * as Yup from "yup";

const ContactForm: React.FC = () => {
  const { state: appState } = useContext(AppContext);
  const initialValues: ContactType = {
    firstName: appState.mySelf?.firstName,
    lastName: appState.mySelf?.lastName,
    email: appState.mySelf?.email,
    support: "",
    message: "",
  };

  const cleanData = (values: ContactType) => {
    let cleanedValues = { ...values };
    supportOptions.forEach((option) => {
      if (option.value === values.support) {
        cleanedValues = { ...cleanedValues, support: option.text };
      }
    });
    return cleanedValues;
  };

  return (
    <div className="contact-form-wrapper">
      <h1>Contattaci! Siamo a tua disposizione</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          support: Yup.string()
            .required("Supporto è un campo obbligatorio")
            .oneOf(
              [
                "aiuto_tecnico",
                "info_bandi",
                "contatta_amministratore",
                "trovato_errore",
                "altro",
              ],
              "Scegliere uno dei valori validi"
            ),
          message: Yup.string().required("Messaggio è un campo obbligatorio"),
        })}
        onSubmit={(values, formikHelpers) => {
          sendMessage(cleanData(values), formikHelpers);
        }}
      >
        <Form id="contact-form">
          <Input name="firstName" value={initialValues.firstName} />

          <Input name="lastName" value={initialValues.lastName} />

          <Input name="email" value={initialValues.email} />

          <Select
            name="support"
            options={supportOptions}
            placeholder="Di cosa hai bisogno? *"
            errorPrompt={true}
          />
          <ErrorMessage name="support" component="span" />

          <TextArea
            name="message"
            placeholder="Inserisci la tua richiesta qui *"
            errorPrompt={true}
            rows={5}
          />
          <ErrorMessage name="message" component="span" />

          <SubmitButton form="contact-form" className="button--primary">
            <FormattedMessage id="message.sent" defaultMessage="Invia" />
          </SubmitButton>

          <span>* Campi obbligatori</span>
        </Form>
      </Formik>
    </div>
  );
};

export default ContactForm;
