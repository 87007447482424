export enum TableFilterFormActionConstants {
  SET_ORDER_BY = "TableFilterForm/SET_ORDER_BY",
  SET_SEARCH_TEXT = "TableFilterForm/SET_SEARCH_TEXT",
  SET_USER_TYPE = "TableFilterForm/SET_USER_TYPE",
  SET_FILTERS = "TableFilterForm/SET_FILTERS",
}

export type TableFilterFormAction =
  | {
      type: TableFilterFormActionConstants.SET_ORDER_BY;
      payload: {
        orderBy: string | null;
      };
    }
  | {
      type: TableFilterFormActionConstants.SET_SEARCH_TEXT;
      payload: {
        searchText: string | null;
      };
    }
  | {
      type: TableFilterFormActionConstants.SET_USER_TYPE;
      payload: {
        userType: string | null;
      };
    }
  | {
      type: TableFilterFormActionConstants.SET_FILTERS;
      payload: {
        searchText: string | null;
        orderBy: string | null;
        userType: string | null;
      };
    };
