//Built-in and constants
import React, { useEffect, useReducer, useState } from "react";
import { Helmet } from "react-helmet";
import {
  Dimmer,
  Grid,
  Header,
  Loader,
  Card,
  Icon,
  Button,
  Image,
} from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { useParams, useNavigate } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { FE_ROUTES } from "utils/global/globalCostants";
import newIcon from "assets/images/icon/new.svg";

//Service
import { getAnnouncementById } from "services/announcement/index";
import { downloadRes, downloadPDF } from "services/attachments";

//Reducer
import {
  announcementDetailReducer,
  initialAnnouncementDetailState,
} from "./reducer/reducer";
import { AnnouncementDetailActionConstants } from "./reducer/actions";

//Types
import {
  formatFinancedContribute,
  formatStatusValue,
} from "utils/function/announcement";

//Function
import { setNewAnnouncement } from "utils/function/setNewAnnouncement";

const AnnouncementDetail: React.FC = () => {
  /** Get params */
  const { id } = useParams();
  const navigate = useNavigate();

  /** Gets the reducer to receive GET request result */
  const [state, dispatch] = useReducer(
    announcementDetailReducer,
    initialAnnouncementDetailState
  );
  const [isNew, setIsNew] = useState<boolean>(false);

  const getAnnouncementDetails = () => {
    const loading = true;
    if (id) {
      getAnnouncementById(id, dispatch, loading);
    }
  };

  useEffect(() => {
    getAnnouncementDetails();
  }, []);

  useEffect(() => {
    if (state.announcement)
      setIsNew(
        setNewAnnouncement(
          state.announcement.createdAt,
          state.announcement.openingDate
        )
      );
  }, [state.announcement]);

  //  ------------- VIEW PAGE -------------  //

  const formatArrayValues = (arrayValues) => {
    let stringValue: Array<JSX.Element> = [];

    if (!_.isEmpty(arrayValues)) {
      stringValue = arrayValues.map((val, i) => {
        return <li key={i}>{val.description}</li>;
      });
    }
    return <ul>{stringValue}</ul>;
  };

  const generateHTML = (value: string | null | undefined) => {
    if (typeof value === "string") {
      return { __html: value };
    }
  };

  const startDownload = (id: string, action: string) => {
    if (id) downloadRes(id, action, dispatch);
  };

  return (
    <div>
      <Helmet>
        <title>{"Cliccabandi.it - Descrizione Bando"}</title>
        <meta name="description" content="bandi profile page" />
      </Helmet>

      {state.isLoading ? (
        <Dimmer page active>
          <Loader size="huge">
            <Header as="h2" inverted>
              <FormattedMessage
                id="announcementDetail.loadingAnnouncementDetail"
                defaultMessage="Caricamento dei dettagli del bando in corso"
              />
            </Header>
          </Loader>
        </Dimmer>
      ) : (
        <div className="announcementDetail__content">
          <>
            <div>
              <Header className="announcementHeader" dividing>
                <Grid>
                  <Grid.Column width={16} textAlign="center">
                    <Header>
                      <h1>{state.announcement?.title}</h1>
                    </Header>
                  </Grid.Column>
                </Grid>
                {isNew && <Image src={newIcon} />}
              </Header>
              <div className="announcementDetailSectionBody">
                <div className="announcementCardsParagraph">
                  <Card.Group>
                    <Card>
                      <div>
                        <strong>
                          {
                            <FormattedMessage
                              id="announcement.status"
                              defaultMessage="Stato"
                            />
                          }
                        </strong>
                      </div>
                      <div>{formatStatusValue(state.announcement?.status)}</div>
                    </Card>
                    <Card>
                      <div>
                        <strong>
                          {
                            <FormattedMessage
                              id="announcement.geographicalReference"
                              defaultMessage="Riferimento geografico"
                            />
                          }
                        </strong>
                      </div>
                      <div>
                        {state.announcement?.geographicalReference &&
                        state.announcement?.geographicalReference.length > 0
                          ? _.map(
                              state.announcement?.geographicalReference,
                              (gr, index) => {
                                if (index === 0) {
                                  return gr.description;
                                }
                                return "; " + gr.description;
                              }
                            )
                          : "Nazionale"}
                      </div>
                    </Card>
                    <Card>
                      <div>
                        <strong>
                          {
                            <FormattedMessage
                              id="announcementDetail.financedContribute"
                              defaultMessage="Contributo finanziato"
                            />
                          }
                        </strong>
                      </div>
                      <div>
                        {formatFinancedContribute(
                          state.announcement?.minFinancedContribute,
                          state.announcement?.maxFinancedContribute
                        )}
                      </div>
                    </Card>
                    <Card>
                      <div>
                        <strong>
                          {
                            <FormattedMessage
                              id="announcement.publicContributionPercentage"
                              defaultMessage="% contributo pubblico"
                            />
                          }
                        </strong>
                      </div>
                      <div>
                        {state.announcement?.publicContributionPercentage ||
                          "-"}
                      </div>
                    </Card>
                  </Card.Group>
                </div>
                <div className="announcementDetailParagraph">
                  <div className="p-title">
                    <h1>
                      <FormattedMessage
                        id="announcementDetail.description"
                        defaultMessage={"Descrizione bando"}
                      />
                    </h1>
                  </div>
                  <div className="row no__border">
                    {!_.isEmpty(state.announcement?.description) ? (
                      <div
                        className="row-content"
                        dangerouslySetInnerHTML={generateHTML(
                          state.announcement?.description
                        )}
                      />
                    ) : (
                      <div className="row-content">
                        Non è presente una descrizione del bando
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="row-title">
                      <FormattedMessage
                        id="announcement.openingDate"
                        defaultMessage={"Data di apertura"}
                      />
                    </div>
                    <div className="row-content">
                      {state.announcement?.openingDate
                        ? moment(state.announcement?.openingDate).format(
                            "DD/MM/YYYY"
                          )
                        : "-"}
                    </div>
                  </div>
                  <div className="row">
                    <div className="row-title">
                      <FormattedMessage
                        id="announcement.closingDate"
                        defaultMessage={"Data di chiusura"}
                      />
                    </div>
                    <div className="row-content">
                      {state.announcement?.closingDate
                        ? moment(state.announcement?.closingDate).format(
                            "DD/MM/YYYY"
                          )
                        : "-"}
                    </div>
                  </div>
                  <div className="row">
                    <div className="row-title">
                      <FormattedMessage
                        id="announcement.companySize"
                        defaultMessage={"Dimensione d'impresa"}
                      />
                    </div>
                    <div className="row-content">
                      {!_.isEmpty(state.announcement?.companySize)
                        ? formatArrayValues(state.announcement?.companySize)
                        : "Non sono presenti requisiti specifici rispetto alla dimensione d'impresa"}
                    </div>
                  </div>
                  <div className="row">
                    <div className="row-title">
                      <FormattedMessage
                        id="announcement.economicActivity"
                        defaultMessage={"Attività economiche (Settore ATECO)"}
                      />
                    </div>
                    <div className="row-content">
                      {!_.isEmpty(state.announcement?.economicActivity)
                        ? formatArrayValues(
                            state.announcement?.economicActivity
                          )
                        : "Non sono presenti restrizioni o indicazioni specifiche relative al settore economico"}
                    </div>
                  </div>
                  <div className="row">
                    <div className="row-title">
                      <FormattedMessage
                        id="announcement.topic"
                        defaultMessage={"Tematiche"}
                      />
                    </div>
                    <div className="row-content">
                      {!_.isEmpty(state.announcement?.topic)
                        ? formatArrayValues(state.announcement?.topic)
                        : "Non sono presenti requisiti specifici rispetto alle tematiche"}
                    </div>
                  </div>
                  <div className="row">
                    <div className="row-title">
                      <FormattedMessage
                        id="announcement.financingFund"
                        defaultMessage={"Fondo di Finanziamento"}
                      />
                    </div>
                    <div className="row-content">
                      {state.announcement?.financingFund || "-"}
                    </div>
                  </div>
                  <div className="row">
                    <div className="row-title">
                      <FormattedMessage
                        id="announcement.fundingType"
                        defaultMessage={"Tipo di Agevolazione"}
                      />
                    </div>
                    <div className="row-content">
                      {!_.isEmpty(state.announcement?.fundingType)
                        ? formatArrayValues(state.announcement?.fundingType)
                        : "Non sono presenti requisiti specifici rispetto al tipo di agevolazione"}
                    </div>
                  </div>
                  <div className="row">
                    <div className="row-title">
                      <FormattedMessage
                        id="announcement.applicantCompanyForm"
                        defaultMessage={"Tipo di Soggetto Richiedente"}
                      />
                    </div>
                    <div className="row-content">
                      {!_.isEmpty(state.announcement?.applicantCompanyForm)
                        ? formatArrayValues(
                            state.announcement?.applicantCompanyForm
                          )
                        : "Non sono presenti requisiti specifici rispetto al tipo di soggetto richiedente"}
                    </div>
                  </div>
                  <div className="row">
                    <div className="row-title">
                      <FormattedMessage
                        id="announcement.eligibleCost"
                        defaultMessage={"Costi ammissibili"}
                      />
                    </div>
                    <div className="row-content">
                      {!_.isEmpty(state.announcement?.eligibleCost)
                        ? formatArrayValues(state.announcement?.eligibleCost)
                        : "Non sono presenti requisiti specifici rispetto ai costi ammissibili"}
                    </div>
                  </div>
                  <div className="row">
                    <div className="row-title">
                      <FormattedMessage
                        id="announcement.promotingInstitution"
                        defaultMessage={"Ente promotore"}
                      />
                    </div>
                    <div className="row-content">
                      {!_.isEmpty(state.announcement?.promotingInstitution)
                        ? formatArrayValues(
                            state.announcement?.promotingInstitution
                          )
                        : "Non sono presenti requisiti specifici rispetto all'ente promotore"}
                    </div>
                  </div>
                  <div className="row">
                    <div className="row-title">
                      <FormattedMessage
                        id="announcement.webLink"
                        defaultMessage={"Riferimento web"}
                      />
                    </div>
                    <div
                      className="row-content"
                      dangerouslySetInnerHTML={
                        state.announcement?.webLink
                          ? generateHTML(state.announcement?.webLink)
                          : { __html: "Non è disponibile un riferimento web" }
                      }
                    />
                  </div>
                </div>
                <div className="otherInfoAndContacts">
                  <div className="announcementDetailParagraph">
                    <div className="p-title">
                      <h1>
                        <FormattedMessage
                          id="announcement.additionalInfo"
                          defaultMessage={"Ulteriori informazioni e contatti"}
                        />
                      </h1>
                    </div>
                  </div>
                  <div className="announcementDetailParagraph">
                    <div className="p-title">
                      <h2>
                        <FormattedMessage
                          id="announcement.note"
                          defaultMessage={"Note"}
                        />
                      </h2>
                    </div>
                    <div className="row">
                      <div
                        className="row-content"
                        dangerouslySetInnerHTML={
                          state.announcement?.note
                            ? generateHTML(state.announcement?.note)
                            : {
                                __html:
                                  "Non sono presenti note relative a questo bando",
                              }
                        }
                      />
                    </div>
                  </div>
                  <div className="announcementDetailParagraph">
                    <div className="p-title">
                      <h2>
                        <FormattedMessage
                          id="announcement.additionalInformation"
                          defaultMessage={"Contatti e informazioni aggiuntive"}
                        />
                      </h2>
                    </div>
                    <div className="row">
                      <div
                        className="row-content"
                        dangerouslySetInnerHTML={
                          state.announcement?.additionalInformation
                            ? generateHTML(
                                state.announcement?.additionalInformation
                              )
                            : {
                                __html:
                                  "Non sono presenti informazioni aggiuntive",
                              }
                        }
                      />
                    </div>
                  </div>
                  <div className="announcementDetailParagraph">
                    <div className="p-title">
                      <h2>
                        <FormattedMessage
                          id="announcement.successfulApplicants"
                          defaultMessage={"Vincitori"}
                        />
                      </h2>
                    </div>
                    <div className="row">
                      <div
                        className="row-content"
                        dangerouslySetInnerHTML={
                          state.announcement?.successfulApplicants
                            ? generateHTML(
                                state.announcement?.successfulApplicants
                              )
                            : {
                                __html: "Vincitori non disponibili",
                              }
                        }
                      />
                    </div>
                  </div>
                  <div className="announcementDetailParagraph">
                    <div className="p-title">
                      <h2>
                        <FormattedMessage
                          id="announcement.attachments"
                          defaultMessage={"Allegati"}
                        />
                      </h2>
                    </div>
                    <div className="row attachments">
                      <div className="fileItem">
                        <Icon name="file alternate" />
                        <div className="file--info">
                          <div className="fileName">
                            {state.announcement?.title}
                          </div>
                          <div className="fileExtension">.pdf</div>
                        </div>
                        {!state.isLoadingPdf ? (
                          <>
                            <Button
                              onClick={() => {
                                dispatch({
                                  type: AnnouncementDetailActionConstants.SET_IS_LOADING_PDF,
                                  payload: {
                                    isLoadingPdf: true,
                                  },
                                });
                                if (id) downloadPDF(id, "preview", dispatch);
                              }}
                            >
                              <Icon name="external alternate" />
                            </Button>
                            <Button
                              onClick={() => {
                                dispatch({
                                  type: AnnouncementDetailActionConstants.SET_IS_LOADING_PDF,
                                  payload: {
                                    isLoadingPdf: true,
                                  },
                                });
                                if (id) downloadPDF(id, "download", dispatch);
                              }}
                            >
                              <Icon name="download" />
                            </Button>
                          </>
                        ) : (
                          <Loader />
                        )}
                      </div>
                      {!_.isEmpty(state.announcement?.attachments) &&
                        state.announcement?.attachments.map((file, key) => (
                          <div key={key} className="fileItem">
                            <Icon name="file alternate" />
                            <div className="file--info">
                              <div className="fileName">{file.filename}</div>
                              <div className="fileExtension">
                                {file.extension}
                              </div>
                            </div>
                            {state.isLoadingAttachment &&
                            state.loadingIndex === key ? (
                              <Loader />
                            ) : (
                              <>
                                {file.extension.indexOf("xls") === -1 &&
                                  file.extension.indexOf("doc") === -1 && (
                                    <Button
                                      onClick={() => {
                                        dispatch({
                                          type: AnnouncementDetailActionConstants.SET_IS_LOADING_ATTACHMENT,
                                          payload: {
                                            isLoadingAttachment: true,
                                          },
                                        });
                                        dispatch({
                                          type: AnnouncementDetailActionConstants.SET_LOADING_INDEX,
                                          payload: {
                                            loadingIndex: key,
                                          },
                                        });
                                        startDownload(file.id, "preview");
                                      }}
                                    >
                                      <Icon name="external alternate" />
                                    </Button>
                                  )}
                                <Button
                                  onClick={() => {
                                    dispatch({
                                      type: AnnouncementDetailActionConstants.SET_IS_LOADING_ATTACHMENT,
                                      payload: {
                                        isLoadingAttachment: true,
                                      },
                                    });
                                    dispatch({
                                      type: AnnouncementDetailActionConstants.SET_LOADING_INDEX,
                                      payload: {
                                        loadingIndex: key,
                                      },
                                    });
                                    startDownload(file.id, "download");
                                  }}
                                >
                                  <Icon name="download" />
                                </Button>
                              </>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="announcementDetailParagraph">
                    <div className="row call-to-action">
                      <Button
                        className="button--underline"
                        onClick={() => {
                          navigate(`${FE_ROUTES.ELENCO_BANDI}`, {
                            replace: true,
                          });
                        }}
                      >
                        <Icon name="arrow left" />
                        <div className="button--text">
                          <FormattedMessage
                            id="announcementDetail.backToSearch"
                            defaultMessage="Torna alla ricerca"
                          />
                        </div>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      )}
    </div>
  );
};

export default AnnouncementDetail;
