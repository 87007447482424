import { AxiosResponse } from "axios";

// Internals and constants
import { API } from "utils/global/backendRoutes";
import authClient from "./authRequestClient";
import { clearAuthObject, updateAuthObject } from "utils/function/localStorage";
import { LOCAL_STORAGE } from "utils/global/globalCostants";

// Types
import { AuthObject, PromiseObject } from "./types";
import { UserLoginResponse } from "services/auth/types";

/**
 * Define a queue where store the incoming requests when is trying to refersh the token.
 * At the end if the token has been refreshed process the queue otherwise clear it.
 */
export let isTokenRefreshing = false;
export let failedRequestsQueue: Array<PromiseObject> = [];

export const refreshAccessToken = (): void => {
  isTokenRefreshing = true;
  // Get the current refresh token from locale storage
  const currentRefreshToken = localStorage.getItem(LOCAL_STORAGE.REFRESH_TOKEN);

  // Send request to authClient with the refresh token
  authClient
    .request({
      url: API.OAUTH_GRANT_PASSWORD,
      data: {
        grant_type: "refresh_token",
        refresh_token: currentRefreshToken,
      },
    })
    .then((response: AxiosResponse<UserLoginResponse>) => {
      const responseData = response.data;

      const authObject: AuthObject = {
        accessTokken: responseData.access_token,
        expiresIn: responseData.expires_in.toString(),
        refreshToken: responseData.refresh_token,
        scope: responseData.scope,
        tokenType: responseData.token_type,
      };

      // Update user data in locale storage
      updateAuthObject(authObject);

      // Handle all the failed requests with a promise resolve response
      failedRequestsQueue.map((promise) =>
        promise.resolve(responseData.access_token)
      );

      // Clear the queue of failed requests after have handled it
      isTokenRefreshing = false;
      failedRequestsQueue = [];
    })
    .catch((error) => failedRefreshToken(error));
};

/**
 * If the access token can't be refreshed clear the request queue, create a Logout
 * custom event for handle the error in React application, clear the locale storage.
 */
export const failedRefreshToken = (error: unknown): void => {
  // Reject all the queue promises
  failedRequestsQueue.map((promise) => promise.reject(error));

  isTokenRefreshing = false;
  clearAuthObject();
};
