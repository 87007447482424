import { SelectValueTextObject, SelectValueLabelObject } from "utils/types";
import {
  FilterAnnouncementAction,
  FilterAnnouncementActionConstants,
} from "./actions";
import { Customer } from "services/customer/types";
import { FilterTooltipObj } from "../types";

export type FilterAnnouncementState = {
  options: Array<SelectValueTextObject>;
  isLoading: boolean;
  customerList: Array<Customer>;
  customerOptions: Array<SelectValueLabelObject>;
  geographicReferenceOptions: Array<SelectValueTextObject>;
  economicActivityOptions: Array<SelectValueTextObject>;
  companySizeOptions: Array<SelectValueTextObject>;
  topicOptions: Array<SelectValueTextObject>;
  fundingTypeOptions: Array<SelectValueTextObject>;
  applicantCompanyFormOptions: Array<SelectValueTextObject>;
  eligibleCostOptions: Array<SelectValueTextObject>;
  promotingInstitutionOptions: Array<SelectValueTextObject>;
  statusOptions: Array<SelectValueTextObject>;
  isLoadingEconomicActivity: boolean;
  isLoadingGeoReference: boolean;
  isLoadingCompanySize: boolean;
  isLoadingTopic: boolean;
  isLoadingFundingType: boolean;
  isLoadingApplicantCompanyForm: boolean;
  isLoadingEligibleCost: boolean;
  isLoadingPromotingInstitution: boolean;
  tooltips: FilterTooltipObj;
};

export const announcementStatusOptions = [
  {
    value: "aperto",
    text: "Aperto",
  },
  {
    value: "chiuso",
    text: "Chiuso",
  },
  {
    value: "in_programmazione",
    text: "In programmazione",
  },
];

export const initialFilterAnnouncementState: FilterAnnouncementState = {
  isLoading: true,
  isLoadingEconomicActivity: true,
  isLoadingGeoReference: true,
  isLoadingCompanySize: true,
  isLoadingTopic: true,
  isLoadingFundingType: true,
  isLoadingApplicantCompanyForm: true,
  isLoadingEligibleCost: true,
  isLoadingPromotingInstitution: true,
  options: [],
  customerList: [],
  customerOptions: [],
  geographicReferenceOptions: [],
  economicActivityOptions: [],
  companySizeOptions: [],
  topicOptions: [],
  fundingTypeOptions: [],
  applicantCompanyFormOptions: [],
  eligibleCostOptions: [],
  promotingInstitutionOptions: [],
  statusOptions: announcementStatusOptions,
  tooltips: {},
};

export function filterAnnouncementReducer(
  state: FilterAnnouncementState,
  action: FilterAnnouncementAction
): FilterAnnouncementState {
  const { type } = action;

  switch (type) {
    //------ set list of customers for 'Ricerca cliente' ------//
    case FilterAnnouncementActionConstants.SET_OPTIONS:
      return {
        ...state,
        options: action.payload.options,
      };
    case FilterAnnouncementActionConstants.SET_CUSTOMER_LIST:
      return {
        ...state,
        customerList: action.payload.customerList,
      };
    case FilterAnnouncementActionConstants.SET_CUSTOMER_OPTIONS:
      return {
        ...state,
        customerOptions: action.payload.customerOptions,
      };
    case FilterAnnouncementActionConstants.SET_GEOGRAPHIC_REFERENCE_OPTIONS:
      return {
        ...state,
        geographicReferenceOptions: action.payload.geographicReferenceOptions,
      };
    case FilterAnnouncementActionConstants.SET_ECONOMIC_ACTIVITY_OPTIONS:
      return {
        ...state,
        economicActivityOptions: action.payload.economicActivityOptions,
      };
    case FilterAnnouncementActionConstants.SET_COMPANY_SIZE_OPTIONS:
      return {
        ...state,
        companySizeOptions: action.payload.companySizeOptions,
      };
    case FilterAnnouncementActionConstants.SET_TOPIC_OPTIONS:
      return {
        ...state,
        topicOptions: action.payload.topicOptions,
      };
    case FilterAnnouncementActionConstants.SET_FUNDING_TYPE_OPTIONS:
      return {
        ...state,
        fundingTypeOptions: action.payload.fundingTypeOptions,
      };
    case FilterAnnouncementActionConstants.SET_APPLICANT_COMPANY_FORM_OPTIONS:
      return {
        ...state,
        applicantCompanyFormOptions: action.payload.applicantCompanyFormOptions,
      };
    case FilterAnnouncementActionConstants.SET_ELIGIBLE_COST_OPTIONS:
      return {
        ...state,
        eligibleCostOptions: action.payload.eligibleCostOptions,
      };
    case FilterAnnouncementActionConstants.SET_PROMOTING_INSTITUTION_OPTIONS:
      return {
        ...state,
        promotingInstitutionOptions: action.payload.promotingInstitutionOptions,
      };

    //---- LOADING ACTIONS ----//

    case FilterAnnouncementActionConstants.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    case FilterAnnouncementActionConstants.SET_IS_LOADING_COMPANY_SIZE:
      return {
        ...state,
        isLoadingCompanySize: action.payload.isLoadingCompanySize,
      };
    case FilterAnnouncementActionConstants.SET_IS_LOADING_ECONOMIC_ACTIVITY:
      return {
        ...state,
        isLoadingEconomicActivity: action.payload.isLoadingEconomicActivity,
      };
    case FilterAnnouncementActionConstants.SET_IS_LOADING_GEOGRAPHIC_REFERENCE:
      return {
        ...state,
        isLoadingGeoReference: action.payload.isLoadingGeoReference,
      };
    case FilterAnnouncementActionConstants.SET_IS_LOADING_TOPIC:
      return {
        ...state,
        isLoadingTopic: action.payload.isLoadingTopic,
      };
    case FilterAnnouncementActionConstants.SET_IS_LOADING_FUNDING_TYPE:
      return {
        ...state,
        isLoadingFundingType: action.payload.isLoadingFundingType,
      };
    case FilterAnnouncementActionConstants.SET_IS_LOADING_APPLICANT_COMPANY_FORM:
      return {
        ...state,
        isLoadingApplicantCompanyForm:
          action.payload.isLoadingApplicantCompanyForm,
      };
    case FilterAnnouncementActionConstants.SET_IS_LOADING_ELIGIBLE_COST:
      return {
        ...state,
        isLoadingEligibleCost: action.payload.isLoadingEligibleCost,
      };
    case FilterAnnouncementActionConstants.SET_IS_LOADING_PROMOTING_INSTITUTION:
      return {
        ...state,
        isLoadingPromotingInstitution:
          action.payload.isLoadingPromotingInstitution,
      };
    case FilterAnnouncementActionConstants.SET_FILTER_TOOLTIPS:
      return {
        ...state,
        tooltips: action.payload.tooltips,
      };
    default:
      return state;
  }
}
