import { AppContext } from "pages/App";
import { appActionConstants } from "pages/App/reducer/actions";
import React, { useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Menu, Button, Icon, Image } from "semantic-ui-react";
import { FE_ROUTES } from "utils/global/globalCostants";
import { TopbarProps } from "./types";
import { MenuItem } from "pages/App/types";
import { FormattedMessage } from "react-intl";
import { CUSTOM_EVENT } from "utils/global/globalCostants";

const Topbar: React.FC<TopbarProps> = ({ className }) => {
  const { state, dispatch } = useContext(AppContext);

  const topBarItems: Array<MenuItem> = [
    {
      id: "announcements",
      to: FE_ROUTES.ELENCO_BANDI,
      position: "left",
      title: (
        <Menu.Item>
          <FormattedMessage id="link.announcements" defaultMessage="Bandi" />
        </Menu.Item>
      ),
    },
    {
      id: "administration",
      to: FE_ROUTES.MAIN_ROUTE,
      position: "left",
      title: (
        <Menu.Item>
          <FormattedMessage
            id="link.administration"
            defaultMessage="Amministrazione"
          />
        </Menu.Item>
      ),
    },
    {
      id: "contact",
      to: FE_ROUTES.CONTACT,
      position: "left",
      title: (
        <Menu.Item>
          <FormattedMessage id="link.contact" defaultMessage="Contatti" />
        </Menu.Item>
      ),
    },
  ];

  useEffect(() => {
    dispatch({
      type: appActionConstants.SET_TOPBAR_STATE,
      payload: {
        topbarState: [
          {
            id: "logo",
            to: "",
            position: "left",
            title: (
              <Menu.Item>
                <Image src={state.logo} />
              </Menu.Item>
            ),
          },
          ...topBarItems,
        ],
      },
    });
  }, []);

  return (
    <>
      <Menu
        className={`topbar ${className}`}
        inverted
        size="massive"
        style={{ position: "fixed" }}
      >
        <Menu.Menu position="left">
          {state.topbarState
            .filter(
              (topBarItem) =>
                !topBarItem.position || topBarItem.position === "left"
            )
            .map((topBarItem) => (
              <Menu.Item
                as={NavLink}
                style={{ textAlign: "left" }}
                key={topBarItem.id}
                name={topBarItem.id}
                to={topBarItem.to}
              >
                {topBarItem.title}
              </Menu.Item>
            ))}
        </Menu.Menu>

        <Menu.Menu position="right">
          {/* {state.topbarState
            .filter(
              (topBarItem) =>
                topBarItem.position && topBarItem.position === "right"
            )
            .filter(
              (topBarItem) =>
                (topBarItem.protected ?? false) === state.isAuthenticated
            )
            .map((topBarItems) => (
              <Menu.Item
                as={NavLink}
                style={{ textAlign: "left" }}
                key={topBarItems.id}
                name={topBarItems.id}
                to={topBarItems.to}
              >
                {topBarItems.title}
              </Menu.Item>
            ))} */}
          {state.isAuthenticated && (
            <div className="sign__out">
              {`${state.mySelf?.firstName} ${state.mySelf?.lastName}`}
              <Menu.Item
                className="link__logout"
                as={NavLink}
                key="logout"
                name="logout"
                to={FE_ROUTES.LOGIN}
                style={{ textAlign: "left" }}
                // onClick={() => {
                //   localStorage.clear();
                //   dispatch({ type: appActionConstants.REVOKE_SESSION });
                // }}
              >
                <Button
                  onClick={() => {
                    const logout = new CustomEvent(CUSTOM_EVENT.LOGOUT);
                    window.dispatchEvent(logout);
                    // clearAuthObject();
                    // dispatch({ type: appActionConstants.REVOKE_SESSION });
                  }}
                  className="logout--button"
                >
                  <Icon name="sign-out alternate" size="large" />
                </Button>
              </Menu.Item>
            </div>
          )}
        </Menu.Menu>
      </Menu>
    </>
  );
};

export default Topbar;
