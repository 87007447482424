import {
  FilterTableType,
  FilterTreeDataObj,
  FilterTreeFormType,
} from "../types";
import { FilterEditAction, FilterEditActionConstants } from "./actions";
import { FilterTooltipType } from "components/TooltipEdit/types";

export type FilterEditState = {
  filterValues: Array<FilterTableType>;
  isLoading: boolean;
  filterTreeData: Array<FilterTreeDataObj>;
  initialFormTreeValues: FilterTreeFormType;
  modalOpen: boolean;
  tooltip: FilterTooltipType;
  tooltipLoading: boolean;
};

export const initialFilterEditState: FilterEditState = {
  filterValues: [],
  isLoading: false,
  filterTreeData: [],
  initialFormTreeValues: {},
  modalOpen: false,
  tooltip: {
    id: null,
    filterReference: null,
    content: null,
  },
  tooltipLoading: false,
};

export function filterEditReducer(
  state: FilterEditState,
  action: FilterEditAction
): FilterEditState {
  const { type } = action;

  switch (type) {
    case FilterEditActionConstants.SET_FILTER_VALUES:
      return {
        ...state,
        filterValues: action.payload.filterValues,
      };
    case FilterEditActionConstants.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    case FilterEditActionConstants.SET_FILTER_TREE_DATA:
      return {
        ...state,
        filterTreeData: action.payload.filterTreeData,
      };
    case FilterEditActionConstants.SET_INITIAL_FORM_TREE_VALUES:
      return {
        ...state,
        initialFormTreeValues: action.payload.initialFormTreeValues,
      };
    case FilterEditActionConstants.SET_MODAL_OPEN:
      return {
        ...state,
        modalOpen: action.payload.modalOpen,
      };
    case FilterEditActionConstants.SET_FILTER_TOOLTIP:
      return {
        ...state,
        tooltip: action.payload.tooltip,
      };
    case FilterEditActionConstants.SET_TOOLTIP_LOADING:
      return {
        ...state,
        tooltipLoading: action.payload.tooltipLoading,
      };
    default:
      return state;
  }
}
