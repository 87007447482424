import React, { useEffect, useReducer, useContext } from "react";
import { Helmet } from "react-helmet";
import { Image } from "semantic-ui-react";

// app context
import { AppContext } from "pages/App";

// Form
import FirstLoginForm from "components/Forms/FirstLoginForm";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FirstLoginFormValues } from "components/Forms/FirstLoginForm/types";
import { userFirstLogin } from "services/user";
import { firstLoginReducer, initialFirstLoginState } from "./reducer/reducer";
import { FirstLoginActionConstants } from "./reducer/actions";

const FirstLogin: React.FC = () => {
  const { state: appState } = useContext(AppContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [state, dispatch] = useReducer(
    firstLoginReducer,
    initialFirstLoginState
  );

  /**
   * Every time the page is reloading, it sets the status code.
   */
  useEffect(() => {
    const token = searchParams.get("token");
    dispatch({
      type: FirstLoginActionConstants.SET_ACTIVATION_TOKEN,
      payload: { token: token },
    });
  }, []);

  const onSubmit = (values: FirstLoginFormValues) => {
    values = { ...values, token: state.token };
    userFirstLogin(values, navigate, dispatch);
  };

  return (
    <div className="password-framework">
      <Helmet>
        <body className="first-login" />
        <title>Clicca Bandi - First Login</title>
        <meta name="description" content="first-login page" />
      </Helmet>
      {state ? (
        <>
          <Image src={appState.logo} />
          <br></br>
          <div className="password-framework__container">
            <div>
              <h5>{"Imposta la password per attivare l'utente"}</h5>
            </div>
            <br></br>
            <FirstLoginForm onSubmit={onSubmit} />
          </div>
        </>
      ) : (
        <>
          <Image src={appState.logo} />
          <br></br>
          <div className="first-login-form-container">
            <div>
              <h1>
                {
                  "Si è verificato un problema durante l'attivazione dell'utente"
                }
              </h1>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FirstLogin;
