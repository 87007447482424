/** Built-in and constants */
import React, { useEffect, useReducer, useContext } from "react";
import { Helmet } from "react-helmet";
import {
  Dimmer,
  Grid,
  Header,
  Loader,
  Form,
  Select,
  Icon,
  Button,
  TextArea,
} from "semantic-ui-react";
import { Formik, ErrorMessage, getIn, FormikErrors } from "formik";
import { Field, Input } from "formik-semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams, useNavigate } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import * as Yup from "yup";
import { useConfirm } from "material-ui-confirm";
import { PropagateLoader } from "react-spinners";
import { ANNOUNCEMENT_STATUS } from "utils/global/globalCostants";
import { ROLES } from "utils/global/globalCostants";
import { API } from "utils/global/backendRoutes";
import { FE_ROUTES } from "utils/global/globalCostants";
/** Components */
import { RichTextEditor } from "components/RichTextEditor";
import { CurrencyInputFormik } from "components/CurrencyInputFormik";
import { FileThumb } from "components/FileThumb";
import { UploadDropzone } from "components/UploadDropzone";
/** Functions */
import { focusOnError } from "utils/function/focusOnError";
/** Services */
import { getAnnouncementById } from "services/announcement/index";
import {
  updateAnnouncement,
  createAnnouncement,
} from "services/announcement/index";
import { getFilterOptions } from "services/filters";
import { downloadRes } from "services/attachments";
/** Reducer */
import {
  announcementDetailReducer,
  initialAnnouncementDetailState,
} from "pages/AnnouncementDetail/reducer/reducer";
import {
  filterAnnouncementReducer,
  initialFilterAnnouncementState,
} from "components/Forms/FilterAnnouncementForm/reducer/reducer";
import { AppContext } from "pages/App";
/** Types */
import {
  HydratedFilterResponse,
  AnnouncementValuesTypes,
} from "pages/Announcement/types";

const AnnouncementDetail: React.FC = () => {
  const { action, id } = useParams();
  const { state: AppState } = useContext(AppContext);
  const navigate = useNavigate();
  const intl = useIntl();
  const confirm = useConfirm();
  const [state, dispatch] = useReducer(
    announcementDetailReducer,
    initialAnnouncementDetailState
  );

  const [FilterAnnouncementState, FilterAnnouncementDispatch] = useReducer(
    filterAnnouncementReducer,
    initialFilterAnnouncementState
  );

  const endpointList = [
    API.GEOGRAPHICAL_REFERENCE,
    API.ECONOMIC_ACTIVITY,
    API.COMPANY_SIZE,
    API.TOPIC,
    API.FUNDING_TYPE,
    API.APPLICANT_COMPANY_FORM,
    API.ELIGIBLE_COST,
    API.PROMOTING_INSTITUTION,
  ];

  const getAnnouncementDetails = () => {
    const loading = true;
    if (id) {
      getAnnouncementById(id, dispatch, loading);
    }
  };

  useEffect(() => {
    //** Redirect to view page if user does not have the right permissions */
    if (
      !_.some([ROLES.ADMIN, ROLES.SUPERVISOR, ROLES.EDITOR], (role) =>
        _.includes(AppState.mySelf?.roles, role)
      )
    )
      id
        ? navigate(`${FE_ROUTES.ELENCO_BANDI}/view/${id}`, {
            replace: true,
          })
        : navigate(`${FE_ROUTES.ELENCO_BANDI}`, {
            replace: true,
          });
    //** Create filters in editing mode */
    for (const endpoint of endpointList) {
      getFilterOptions(endpoint, FilterAnnouncementDispatch);
    }
    getAnnouncementDetails();
  }, []);

  //  ------------- EDIT PAGE -------------  //

  const setFilterArray = (
    responseArray: Array<HydratedFilterResponse> | []
  ) => {
    let filterArray: Array<string> = [];
    if (responseArray && responseArray?.length > 0) {
      filterArray = responseArray?.map((res): string => {
        return res.id;
      });
    }
    return filterArray;
  };

  const initialValues: AnnouncementValuesTypes = {
    id: state.announcement?.id ?? "",
    additionalInformation: state.announcement?.additionalInformation ?? "",
    closingDate: state.announcement?.closingDate ?? null,
    description: state.announcement?.description ?? "",
    previewDescription: state.announcement?.previewDescription ?? "",
    financingFund: state.announcement?.financingFund ?? "",
    maxFinancedContribute: state.announcement?.maxFinancedContribute ?? null,
    minFinancedContribute: state.announcement?.minFinancedContribute ?? null,
    openingDate: state.announcement?.openingDate ?? null,
    publicContributionPercentage:
      state.announcement?.publicContributionPercentage ?? "",
    status: state.announcement?.status ?? null,
    title: state.announcement?.title ?? null,
    webLink: state.announcement?.webLink ?? "",
    attachments: state.announcement?.attachments ?? [],
    attachmentsToUpload: [],
    note: state.announcement?.note ?? "",
    successfulApplicants: state.announcement?.successfulApplicants ?? "",
    isArchived: state.announcement?.isArchived ?? false,
    isDeleted: state.announcement?.isDeleted ?? false,
    geographicalReference: state.announcement?.geographicalReference
      ? setFilterArray(state.announcement?.geographicalReference)
      : [],
    companySize: state.announcement?.companySize
      ? setFilterArray(state.announcement?.companySize)
      : [],
    economicActivity: state.announcement?.economicActivity
      ? setFilterArray(state.announcement?.economicActivity)
      : [],
    topic: state.announcement?.topic
      ? setFilterArray(state.announcement?.topic)
      : [],
    fundingType: state.announcement?.fundingType
      ? setFilterArray(state.announcement?.fundingType)
      : [],
    applicantCompanyForm: state.announcement?.applicantCompanyForm
      ? setFilterArray(state.announcement?.applicantCompanyForm)
      : [],
    eligibleCost: state.announcement?.eligibleCost
      ? setFilterArray(state.announcement?.eligibleCost)
      : [],
    promotingInstitution: state.announcement?.promotingInstitution
      ? setFilterArray(state.announcement?.promotingInstitution)
      : [],
  };

  const onSubmit = (values, formikHelpers) => {
    action === "edit"
      ? updateAnnouncement(values, formikHelpers, dispatch).finally(() => {
          window.scrollTo({
            top: 0,
            left: 0,
          });
        })
      : createAnnouncement(values, formikHelpers, navigate, dispatch).finally(
          () => {
            window.scrollTo({
              top: 0,
              left: 0,
            });
          }
        );
  };

  const handleClick = (action: string) => {
    const name = !_.isEmpty(state.announcement?.title)
      ? state.announcement?.title
      : "";
    let confirmPayload = {};
    if (action === "archive") {
      confirmPayload = {
        description: `${name} sta per essere archiviato.`,
        title: "Sei sicuro?",
        confirmationText: "Conferma",
        cancellationText: "Cancella",
        confirmationButtonProps: { className: "button--confirm yes" },
        cancellationButtonProps: { className: "button--confirm no" },
      };
    }
    if (action === "restore") {
      confirmPayload = {
        description: `Cliccando sul tasto di conferma, ${name} verrà ripristinato.`,
        title: "Vuoi ripristinare il bando?",
        confirmationText: "Conferma",
        cancellationText: "Cancella",
        confirmationButtonProps: { className: "button--confirm yes" },
        cancellationButtonProps: { className: "button--confirm no" },
      };
    }
    if (action === "deletePermanently") {
      confirmPayload = {
        description:
          "Stai per eliminare definitivamente questo bando. Cliccando sul tasto di conferma tutti i dati verranno persi e non sarà più possibile ripristinarli.",
        title: "Eliminare definitivamente il bando?",
        confirmationText: "Conferma",
        cancellationText: "Cancella",
        confirmationButtonProps: { className: "button--confirm yes" },
        cancellationButtonProps: { className: "button--confirm no" },
      };
    }
    confirm(confirmPayload)
      .then(() => {
        switch (action) {
          case "archive":
            updateAnnouncement(
              {
                id: state.announcement?.id,
                title: state.announcement?.title,
                previewDescription: state.announcement?.previewDescription,
                status: state.announcement?.status,
                isArchived: true,
              },
              undefined,
              dispatch
            );
            navigate(`${FE_ROUTES.GESTIONE_BANDI}`, {
              replace: true,
            });
            break;
          case "restore":
            updateAnnouncement(
              {
                id: state.announcement?.id,
                title: state.announcement?.title,
                previewDescription: state.announcement?.previewDescription,
                status: state.announcement?.status,
                isArchived: false,
              },
              undefined,
              dispatch
            );
            navigate(`${FE_ROUTES.ARCHIVIO_BANDI}`, {
              replace: true,
            });
            break;
          case "deletePermanently":
            updateAnnouncement(
              {
                id: state.announcement?.id,
                title: state.announcement?.title,
                previewDescription: state.announcement?.previewDescription,
                status: state.announcement?.status,
                isDeleted: true,
              },
              undefined,
              dispatch
            );
            navigate(`${FE_ROUTES.ARCHIVIO_BANDI}`, {
              replace: true,
            });
            break;
        }
      })
      .catch(() => {
        /* ... */
      });
  };

  const startDownload = (id: string, action: string) => {
    if (id) downloadRes(id, action);
  };

  const handleSubmit = (
    validateForm: (
      values?: any
    ) => Promise<FormikErrors<AnnouncementValuesTypes>>,
    submitForm: (() => Promise<void>) & (() => Promise<any>),
    setFieldError: (field: string, message: string | undefined) => void,
    setSubmitting: (isSubmitting: boolean) => void,
    values: AnnouncementValuesTypes
  ) => {
    const placeholders = [
      "Inserire la descrizione",
      "Inserire le note",
      "Inserire i contatti e le informazioni aggiuntive",
      "Inserire uno o più riferimenti web",
      "Inserire i vincitori del bando",
    ];
    let isDateValid = true;
    if (values.openingDate && !moment(values.openingDate).isValid()) {
      isDateValid = false;
      setFieldError("openingDate", "Data non valida");
    } else if (values.openingDate && moment(values.openingDate).isValid()) {
      const formattedDate = moment.utc(values.openingDate).toDate();
      formattedDate.setHours(0, 0, 0, 0);
      const myOpeningDate = new Date(
        Date.UTC(
          formattedDate.getFullYear(),
          formattedDate.getMonth(),
          formattedDate.getDate(),
          formattedDate.getHours(),
          formattedDate.getMinutes(),
          formattedDate.getSeconds()
        )
      );
      values.openingDate = myOpeningDate;
    }
    if (values.closingDate && !moment(values.closingDate).isValid()) {
      isDateValid = false;
      setFieldError("closingDate", "Data non valida");
    } else if (values.closingDate && moment(values.closingDate).isValid()) {
      const formattedDate = moment.utc(values.closingDate).toDate();
      formattedDate.setHours(0, 0, 0, 0);
      const myClosingDate = new Date(
        Date.UTC(
          formattedDate.getFullYear(),
          formattedDate.getMonth(),
          formattedDate.getDate(),
          formattedDate.getHours(),
          formattedDate.getMinutes(),
          formattedDate.getSeconds()
        )
      );
      values.closingDate = myClosingDate;
    }
    _.forOwn(values, (value, key) => {
      if (
        _.isString(value) &&
        (_.isEmpty(value) || _.some(placeholders, (ph) => value.includes(ph)))
      ) {
        values[key] = null;
      }
    });
    validateForm().then((errs: FormikErrors<AnnouncementValuesTypes>) => {
      if (errs && Object.keys(errs).length > 0) {
        focusOnError(errs);
        setSubmitting(false);
      } else if (isDateValid) {
        submitForm();
      } else setSubmitting(false);
    });
  };

  return (
    <div>
      <Helmet>
        <title>{"Cliccabandi.it - Modifica Bando"}</title>
        <meta name="description" content="bandi edit page" />
      </Helmet>
      {state.isLoading ? (
        <Dimmer page active>
          <Loader size="huge">
            <Header as="h2" inverted>
              <FormattedMessage
                id="announcementDetail.loadingAnnouncementDetail"
                defaultMessage="Caricamento dei dettagli del bando in corso"
              />
            </Header>
          </Loader>
        </Dimmer>
      ) : (
        <div className="announcementEdit__content">
          {state.isReloading ? (
            <div className="reloader">
              <PropagateLoader color={"var(--c-secondary)"} />
            </div>
          ) : (
            <div className="announcementEditSectionBody">
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validateOnChange={true}
                onSubmit={(values, formikHelpers) => {
                  onSubmit(values, formikHelpers);
                }}
                validationSchema={Yup.object().shape({
                  title: Yup.string()
                    .typeError("Titolo è un campo obbligatorio")
                    .label(
                      intl.formatMessage({
                        id: "announcementEdit.title",
                        defaultMessage: "Titolo",
                      })
                    )
                    .required("Titolo è un campo obbligatorio"),
                  status: Yup.string()
                    .typeError("Status è un campo obbligatorio")
                    .oneOf([
                      ANNOUNCEMENT_STATUS.APERTO,
                      ANNOUNCEMENT_STATUS.CHIUSO,
                      ANNOUNCEMENT_STATUS.IN_PROGRAMMAZIONE,
                      null,
                    ])
                    .required("Status è un campo obbligatorio"),
                  minFinancedContribute: Yup.number()
                    .typeError(
                      "Contributo min finanziato deve essere un numero"
                    )
                    .label(
                      intl.formatMessage({
                        id: "announcementEdit.minFinancedContribute",
                        defaultMessage: "Contributo min finanziato",
                      })
                    )
                    .nullable()
                    .positive("Contributo min finanziato deve essere positivo")
                    .when("maxFinancedContribute", {
                      is: (max) => {
                        if (!_.isNull(max)) {
                          return true;
                        }
                        return false;
                      },
                      then: Yup.number()
                        .nullable()
                        .test(
                          "lessThanMax",
                          "Contributo minimo deve essere minore di contributo massimo",
                          (value, context) => {
                            if (value)
                              return (
                                value <= context.parent.maxFinancedContribute
                              );
                            return true;
                          }
                        ),
                    }),
                  maxFinancedContribute: Yup.number()
                    .typeError(
                      "Contributo max finanziato deve essere un numero"
                    )
                    .label(
                      intl.formatMessage({
                        id: "announcementEdit.maxFinancedContribute",
                        defaultMessage: "Contributo max finanziato",
                      })
                    )
                    .nullable()
                    .positive("Contributo max finanziato deve essere positivo"),
                  publicContributionPercentage: Yup.string()
                    .typeError(
                      "% di contributo pubblico deve essere una stringa"
                    )
                    .label(
                      intl.formatMessage({
                        id: "announcement.publicContributionPercentage",
                        defaultMessage: "% di contributo pubblico",
                      })
                    )
                    .nullable(),
                  previewDescription: Yup.string()
                    .label(
                      intl.formatMessage({
                        id: "announcementEdit.previewDescription",
                        defaultMessage: "Anteprima descrizione",
                      })
                    )
                    .required(
                      "Descrizione in anteprima è un campo obbligatorio"
                    ),
                  description: Yup.string()
                    .label(
                      intl.formatMessage({
                        id: "announcementEdit.description",
                        defaultMessage: "Descrizione",
                      })
                    )
                    .nullable(),
                  openingDate: Yup.mixed()
                    .label(
                      intl.formatMessage({
                        id: "announcement.openingDate",
                        defaultMessage: "Data di apertura",
                      })
                    )
                    .nullable(),
                  closingDate: Yup.mixed()
                    .label(
                      intl.formatMessage({
                        id: "announcement.closingDate",
                        defaultMessage: "Data di chiusura",
                      })
                    )
                    .nullable()
                    .when("openingDate", {
                      is: (date: string) => {
                        if (!_.isNull(date)) return true;
                        return false;
                      },
                      then: Yup.mixed().test(
                        "afterOpeningDate",
                        `Il valore deve essere successivo alla data di apertura`,
                        (value, context) => {
                          const formattedValue = value
                            ? value.isValid
                              ? value.format("YYYY/MM/DD")
                              : moment(value)
                            : null;
                          if (formattedValue)
                            return moment(context.parent.openingDate).isBefore(
                              formattedValue
                            );
                          return true;
                        }
                      ),
                    }),
                  financingFund: Yup.string()
                    .label(
                      intl.formatMessage({
                        id: "announcement.financingFund",
                        defaultMessage: "Fondo di finanziamento",
                      })
                    )
                    .nullable(),
                  note: Yup.string()
                    .label(
                      intl.formatMessage({
                        id: "announcement.note",
                        defaultMessage: "Note",
                      })
                    )
                    .nullable(),
                  additionalInfo: Yup.string()
                    .label(
                      intl.formatMessage({
                        id: "announcement.additionalInfo",
                        defaultMessage: "Ulteriori informazioni e contatti",
                      })
                    )
                    .nullable(),
                  webLink: Yup.string()
                    .label(
                      intl.formatMessage({
                        id: "announcement.webLink",
                        defaultMessage: "Riferimento web",
                      })
                    )
                    .nullable(),
                })}
              >
                {({
                  values,
                  setFieldValue,
                  handleBlur,
                  errors,
                  isSubmitting,
                  submitForm,
                  validateForm,
                  setFieldError,
                  setSubmitting,
                }) => (
                  <>
                    <Grid.Row className="archive">
                      <Grid.Column>
                        {state.announcement?.isArchived
                          ? !_.isEmpty(id) && (
                              <>
                                <Button
                                  onClick={() => {
                                    if (state.announcement?.isArchived)
                                      handleClick("deletePermanently");
                                  }}
                                >
                                  <div className="button--text">
                                    <FormattedMessage
                                      id="announcementEdit.deletePermanently"
                                      defaultMessage="Elimina definitivamente"
                                    />
                                  </div>
                                </Button>
                                <Button
                                  onClick={() => {
                                    if (state.announcement?.isArchived)
                                      handleClick("restore");
                                  }}
                                >
                                  <div className="button--text">
                                    <FormattedMessage
                                      id="announcementEdit.restore"
                                      defaultMessage="Ripristina"
                                    />
                                  </div>
                                </Button>
                              </>
                            )
                          : !_.isEmpty(id) && (
                              <Button
                                onClick={() => {
                                  if (!state.announcement?.isArchived)
                                    handleClick("archive");
                                }}
                              >
                                <div className="button--text">
                                  <FormattedMessage
                                    id="announcementEdit.archive"
                                    defaultMessage="Archivia il bando"
                                  />
                                </div>
                              </Button>
                            )}
                      </Grid.Column>
                    </Grid.Row>
                    <Form
                      id="announcement-edit"
                      className="form__AnnouncementEdit"
                    >
                      <div className="p-title">
                        <h1>
                          <FormattedMessage
                            id="announcementEdit.info"
                            defaultMessage="Informazioni del bando"
                          />
                        </h1>
                      </div>
                      <Grid.Row columns={1}>
                        <Grid.Column>
                          <Field name="title">
                            {({ field }) => (
                              <div
                                className={field.value ? "filled" : undefined}
                              >
                                <Input
                                  name="title"
                                  id="title"
                                  label={
                                    <>
                                      <FormattedMessage
                                        id="announcementEdit.title"
                                        defaultMessage="Titolo"
                                      />
                                      *
                                    </>
                                  }
                                  onKeyPress={(event) => {
                                    if (event.charCode === 13) {
                                      event.target.blur();
                                      event.preventDefault();
                                    }
                                  }}
                                />
                                <ErrorMessage name="title" component="span" />
                              </div>
                            )}
                          </Field>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={2}>
                        <Grid.Column>
                          <Field name="status">
                            {({ field, meta: { touched, error } }) => (
                              <div
                                className={field.value ? "filled" : undefined}
                              >
                                <div className="field">
                                  <label>
                                    <FormattedMessage
                                      id="announcement.status"
                                      defaultMessage="Stato"
                                    />
                                    *
                                  </label>
                                  <Select
                                    options={
                                      FilterAnnouncementState.statusOptions
                                    }
                                    className={
                                      touched && error ? "invalid-field" : ""
                                    }
                                    value={field.value}
                                    onBlur={handleBlur}
                                    name={"status"}
                                    id="status"
                                    clearable={true}
                                    onChange={(event, data) =>
                                      setFieldValue(field.name, data.value)
                                    }
                                    error={
                                      getIn(errors, "status") && touched.status
                                        ? true
                                        : false
                                    }
                                  />
                                  <ErrorMessage
                                    name="status"
                                    component="span"
                                    className="invalid-field-error"
                                  />
                                </div>
                              </div>
                            )}
                          </Field>
                        </Grid.Column>
                        <Grid.Column>
                          <Field name="geographicalReference">
                            {({ field }) => (
                              <div
                                className={
                                  field.value.length > 0 ? "filled" : undefined
                                }
                              >
                                <div className="field">
                                  <label>
                                    <FormattedMessage
                                      id="announcement.geographicalReference"
                                      defaultMessage="Riferimento geografico"
                                    />
                                  </label>
                                  <Select
                                    name="geographicalReference"
                                    options={
                                      FilterAnnouncementState.geographicReferenceOptions
                                    }
                                    search={true}
                                    multiple={true}
                                    loading={
                                      FilterAnnouncementState.isLoadingGeoReference
                                    }
                                    disabled={
                                      FilterAnnouncementState.isLoadingGeoReference
                                    }
                                    onChange={(event, data) => {
                                      setFieldValue(field.name, data.value);
                                    }}
                                    defaultValue={
                                      initialValues.geographicalReference
                                    }
                                  />
                                </div>
                              </div>
                            )}
                          </Field>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={3}>
                        <Grid.Column>
                          <Field name="minFinancedContribute">
                            {({ field }) => (
                              <CurrencyInputFormik
                                field={field}
                                label={
                                  <FormattedMessage
                                    id="announcementEdit.minFinancedContribute"
                                    defaultMessage="Contributo min finanziato"
                                  />
                                }
                                setFieldValue={setFieldValue}
                                className={
                                  field.value || field.value === 0
                                    ? "filled"
                                    : undefined
                                }
                              />
                            )}
                          </Field>
                        </Grid.Column>
                        <Grid.Column>
                          <Field name="maxFinancedContribute">
                            {({ field }) => (
                              <CurrencyInputFormik
                                field={field}
                                label={
                                  <FormattedMessage
                                    id="announcementEdit.maxFinancedContribute"
                                    defaultMessage="Contributo max finanziato"
                                  />
                                }
                                setFieldValue={setFieldValue}
                                className={
                                  field.value || field.value === 0
                                    ? "filled"
                                    : undefined
                                }
                              />
                            )}
                          </Field>
                        </Grid.Column>
                        <Grid.Column>
                          <Field name="publicContributionPercentage">
                            {({ field }) => (
                              <div
                                className={field.value ? "filled" : undefined}
                              >
                                <Input
                                  name="publicContributionPercentage"
                                  label={
                                    <FormattedMessage
                                      id="announcement.publicContributionPercentage"
                                      defaultMessage="% di contributo pubblico"
                                    />
                                  }
                                  onKeyPress={(event) => {
                                    if (event.charCode === 13) {
                                      event.target.blur();
                                      event.preventDefault();
                                    }
                                  }}
                                />
                                <ErrorMessage
                                  name="publicContributionPercentage"
                                  component="span"
                                />
                              </div>
                            )}
                          </Field>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={1}>
                        <Grid.Column>
                          <Field name="previewDescription">
                            {({ field, meta: { touched, error } }) => (
                              <div
                                className={field.value ? "filled" : undefined}
                              >
                                <div className="field cicciopasticcio">
                                  <label>
                                    <FormattedMessage
                                      id="announcementEdit.previewDescription"
                                      defaultMessage="Descrizione in anteprima"
                                    />
                                    *
                                  </label>
                                  <TextArea
                                    className={
                                      touched && error ? "invalid-field" : ""
                                    }
                                    name="previewDescription"
                                    id="previewDescription"
                                    maxLength="500"
                                    value={field.value}
                                    onBlur={handleBlur}
                                    onChange={(event, data) =>
                                      setFieldValue(field.name, data.value)
                                    }
                                    onKeyPress={(event) => {
                                      if (event.charCode === 13) {
                                        event.target.blur();
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                  <ErrorMessage
                                    name="previewDescription"
                                    component="span"
                                  />
                                </div>
                              </div>
                            )}
                          </Field>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={1}>
                        <Grid.Column>
                          <Field name="description">
                            {({ field }) => (
                              <>
                                <label>
                                  <FormattedMessage
                                    id="announcementEdit.description"
                                    defaultMessage="Descrizione"
                                  />
                                </label>
                                <RichTextEditor
                                  data={state.announcement?.description}
                                  placeholder="Inserire la descrizione"
                                  setFieldValue={setFieldValue}
                                  field={field}
                                />
                              </>
                            )}
                          </Field>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={2}>
                        <Grid.Column>
                          <Field name="openingDate">
                            {({ field }) => (
                              <div
                                className={field.value ? "filled" : undefined}
                              >
                                <Input
                                  label={
                                    <FormattedMessage
                                      id="announcement.openingDate"
                                      defaultMessage="Data di apertura"
                                    />
                                  }
                                  name="openingDate"
                                  error={
                                    getIn(errors, "openingDate") ? true : false
                                  }
                                  onKeyPress={(event) => {
                                    if (event.charCode === 13) {
                                      event.target.blur();
                                      event.preventDefault();
                                    }
                                  }}
                                >
                                  <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                      error={false}
                                      helperText={null}
                                      clearable
                                      disableToolbar
                                      format="DD/MM/YYYY"
                                      autoOk
                                      onChange={(value) =>
                                        setFieldValue("openingDate", value)
                                      }
                                      InputProps={{
                                        disableUnderline: true,
                                      }}
                                      value={values.openingDate}
                                      cancelLabel="Cancella"
                                      clearLabel="Reset"
                                    />
                                  </MuiPickersUtilsProvider>
                                </Input>
                                {getIn(errors, field.name) && (
                                  <span>{getIn(errors, field.name)}</span>
                                )}
                              </div>
                            )}
                          </Field>
                        </Grid.Column>
                        <Grid.Column>
                          <Field name="closingDate">
                            {({ field }) => (
                              <div
                                className={field.value ? "filled" : undefined}
                              >
                                <Input
                                  label={
                                    <FormattedMessage
                                      id="announcement.closingDate"
                                      defaultMessage="Data di chiusura"
                                    />
                                  }
                                  name="closingDate"
                                  error={
                                    getIn(errors, "closingDate") ? true : false
                                  }
                                  onKeyPress={(event) => {
                                    if (event.charCode === 13) {
                                      event.target.blur();
                                      event.preventDefault();
                                    }
                                  }}
                                >
                                  <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                      error={false}
                                      helperText={null}
                                      clearable
                                      disableToolbar
                                      format="DD/MM/YYYY"
                                      autoOk
                                      onChange={(value) =>
                                        setFieldValue("closingDate", value)
                                      }
                                      InputProps={{
                                        disableUnderline: true,
                                      }}
                                      value={values.closingDate}
                                      cancelLabel="Cancella"
                                      clearLabel="Reset"
                                    />
                                  </MuiPickersUtilsProvider>
                                </Input>
                                {getIn(errors, field.name) && (
                                  <span>{getIn(errors, field.name)}</span>
                                )}
                              </div>
                            )}
                          </Field>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={1}>
                        <Grid.Column>
                          <Field name="companySize">
                            {({ field }) => (
                              <div
                                className={
                                  field.value.length > 0 ? "filled" : undefined
                                }
                              >
                                <div className="field">
                                  <label>
                                    <FormattedMessage
                                      id="announcement.companySize"
                                      defaultMessage="Dimensione d'impresa"
                                    />
                                  </label>
                                  <Select
                                    name="companySize"
                                    options={
                                      FilterAnnouncementState.companySizeOptions
                                    }
                                    search={true}
                                    multiple={true}
                                    loading={
                                      FilterAnnouncementState.isLoadingCompanySize
                                    }
                                    disabled={
                                      FilterAnnouncementState.isLoadingCompanySize
                                    }
                                    onChange={(event, data) => {
                                      setFieldValue(field.name, data.value);
                                    }}
                                    defaultValue={initialValues.companySize}
                                  />
                                </div>
                              </div>
                            )}
                          </Field>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={1}>
                        <Grid.Column>
                          <Field name="economicActivity">
                            {({ field }) => (
                              <div
                                className={
                                  field.value.length > 0 ? "filled" : undefined
                                }
                              >
                                <div className="field">
                                  <label>
                                    <FormattedMessage
                                      id="announcement.economicActivity"
                                      defaultMessage="Attività economiche (Settore ATECO)"
                                    />
                                  </label>
                                  <Select
                                    name="economicActivity"
                                    options={
                                      FilterAnnouncementState.economicActivityOptions
                                    }
                                    search={true}
                                    multiple={true}
                                    loading={
                                      FilterAnnouncementState.isLoadingEconomicActivity
                                    }
                                    disabled={
                                      FilterAnnouncementState.isLoadingEconomicActivity
                                    }
                                    onChange={(event, data) => {
                                      setFieldValue(field.name, data.value);
                                    }}
                                    defaultValue={
                                      initialValues.economicActivity
                                    }
                                  />
                                </div>
                              </div>
                            )}
                          </Field>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={1}>
                        <Grid.Column>
                          <Field name="topic">
                            {({ field }) => (
                              <div
                                className={
                                  field.value.length > 0 ? "filled" : undefined
                                }
                              >
                                <div className="field">
                                  <label>
                                    <FormattedMessage
                                      id="announcement.topic"
                                      defaultMessage="Tematiche"
                                    />
                                  </label>
                                  <Select
                                    name="topic"
                                    options={
                                      FilterAnnouncementState.topicOptions
                                    }
                                    search={true}
                                    multiple={true}
                                    loading={
                                      FilterAnnouncementState.isLoadingTopic
                                    }
                                    disabled={
                                      FilterAnnouncementState.isLoadingTopic
                                    }
                                    onChange={(event, data) => {
                                      setFieldValue(field.name, data.value);
                                    }}
                                    defaultValue={initialValues.topic}
                                  />
                                </div>
                              </div>
                            )}
                          </Field>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={1}>
                        <Grid.Column>
                          <Field name="financingFund">
                            {({ field }) => (
                              <div
                                className={field.value ? "filled" : undefined}
                              >
                                <Input
                                  name="financingFund"
                                  label={
                                    <FormattedMessage
                                      id="announcement.financingFund"
                                      defaultMessage="Fondo di finanziamento"
                                    />
                                  }
                                />
                                <ErrorMessage
                                  name="financingFund"
                                  component="span"
                                />
                              </div>
                            )}
                          </Field>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={1}>
                        <Grid.Column>
                          <Field name="fundingType">
                            {({ field }) => (
                              <div
                                className={
                                  field.value.length > 0 ? "filled" : undefined
                                }
                              >
                                <div className="field">
                                  <label>
                                    <FormattedMessage
                                      id="announcement.fundingType"
                                      defaultMessage="Tipo di agevolazione"
                                    />
                                  </label>
                                  <Select
                                    name="fundingType"
                                    options={
                                      FilterAnnouncementState.fundingTypeOptions
                                    }
                                    search={true}
                                    multiple={true}
                                    loading={
                                      FilterAnnouncementState.isLoadingFundingType
                                    }
                                    disabled={
                                      FilterAnnouncementState.isLoadingFundingType
                                    }
                                    onChange={(event, data) => {
                                      setFieldValue(field.name, data.value);
                                    }}
                                    defaultValue={initialValues.fundingType}
                                  />
                                </div>
                              </div>
                            )}
                          </Field>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={1}>
                        <Grid.Column>
                          <Field name="applicantCompanyForm">
                            {({ field }) => (
                              <div
                                className={
                                  field.value.length > 0 ? "filled" : undefined
                                }
                              >
                                <div className="field">
                                  <label>
                                    <FormattedMessage
                                      id="announcement.applicantCompanyForm"
                                      defaultMessage="Tipo di soggetto richiedente"
                                    />
                                  </label>
                                  <Select
                                    name="applicantCompanyForm"
                                    options={
                                      FilterAnnouncementState.applicantCompanyFormOptions
                                    }
                                    search={true}
                                    multiple={true}
                                    loading={
                                      FilterAnnouncementState.isLoadingApplicantCompanyForm
                                    }
                                    disabled={
                                      FilterAnnouncementState.isLoadingApplicantCompanyForm
                                    }
                                    onChange={(event, data) => {
                                      setFieldValue(field.name, data.value);
                                    }}
                                    defaultValue={
                                      initialValues.applicantCompanyForm
                                    }
                                  />
                                </div>
                              </div>
                            )}
                          </Field>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={1}>
                        <Grid.Column>
                          <Field name="eligibleCost">
                            {({ field }) => (
                              <div
                                className={
                                  field.value.length > 0 ? "filled" : undefined
                                }
                              >
                                <div className="field">
                                  <label>
                                    <FormattedMessage
                                      id="announcement.eligibleCost"
                                      defaultMessage="Costi ammissibili"
                                    />
                                  </label>
                                  <Select
                                    name={"eligibleCost"}
                                    options={
                                      FilterAnnouncementState.eligibleCostOptions
                                    }
                                    search={true}
                                    multiple={true}
                                    loading={
                                      FilterAnnouncementState.isLoadingEligibleCost
                                    }
                                    disabled={
                                      FilterAnnouncementState.isLoadingEligibleCost
                                    }
                                    onChange={(event, data) => {
                                      setFieldValue(field.name, data.value);
                                    }}
                                    defaultValue={initialValues.eligibleCost}
                                  />
                                </div>
                              </div>
                            )}
                          </Field>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={1}>
                        <Grid.Column>
                          <Field name="promotingInstitution">
                            {({ field }) => (
                              <div
                                className={
                                  field.value.length > 0 ? "filled" : undefined
                                }
                              >
                                <div className="field">
                                  <label>
                                    <FormattedMessage
                                      id="announcement.promotingInstitution"
                                      defaultMessage="Ente promotore"
                                    />
                                  </label>
                                  <Select
                                    name="promotingInstitution"
                                    options={
                                      FilterAnnouncementState.promotingInstitutionOptions
                                    }
                                    search={true}
                                    multiple={true}
                                    loading={
                                      FilterAnnouncementState.isLoadingPromotingInstitution
                                    }
                                    disabled={
                                      FilterAnnouncementState.isLoadingPromotingInstitution
                                    }
                                    onChange={(event, data) => {
                                      setFieldValue(field.name, data.value);
                                    }}
                                    defaultValue={
                                      initialValues.promotingInstitution
                                    }
                                  />
                                </div>
                              </div>
                            )}
                          </Field>
                        </Grid.Column>
                      </Grid.Row>
                      <div className="p-title">
                        <h1>
                          <FormattedMessage
                            id="announcement.additionalInfo"
                            defaultMessage="Ulteriori informazioni e contatti"
                          />
                        </h1>
                      </div>
                      <Grid.Row columns={1}>
                        <Grid.Column>
                          <Field name="note">
                            {({ field }) => (
                              <>
                                <label>
                                  <FormattedMessage
                                    id="announcement.note"
                                    defaultMessage="Note"
                                  />
                                </label>
                                <RichTextEditor
                                  data={state.announcement?.note}
                                  placeholder="Inserire le note"
                                  setFieldValue={setFieldValue}
                                  field={field}
                                />
                              </>
                            )}
                          </Field>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={1}>
                        <Grid.Column>
                          <Field name="additionalInformation">
                            {({ field }) => (
                              <>
                                <label>
                                  <FormattedMessage
                                    id="announcement.additionalInformation"
                                    defaultMessage="Contatti e informazioni aggiuntive"
                                  />
                                </label>
                                <RichTextEditor
                                  data={
                                    state.announcement?.additionalInformation
                                  }
                                  placeholder="Inserire i contatti e le informazioni aggiuntive"
                                  setFieldValue={setFieldValue}
                                  field={field}
                                />
                              </>
                            )}
                          </Field>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={1}>
                        <Grid.Column>
                          <Field name="webLink">
                            {({ field }) => (
                              <>
                                <label>
                                  <FormattedMessage
                                    id="announcement.webLink"
                                    defaultMessage="Riferimento web"
                                  />
                                </label>
                                <RichTextEditor
                                  data={state.announcement?.webLink}
                                  placeholder="Inserire uno o più riferimenti web"
                                  setFieldValue={setFieldValue}
                                  field={field}
                                />
                              </>
                            )}
                          </Field>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={1}>
                        <Grid.Column>
                          <Field name="successfulApplicants">
                            {({ field }) => (
                              <>
                                <label>
                                  <FormattedMessage
                                    id="announcement.successfulApplicants"
                                    defaultMessage="Vincitori del bando"
                                  />
                                </label>
                                <RichTextEditor
                                  data={
                                    state.announcement?.successfulApplicants
                                  }
                                  placeholder="Inserire i vincitori del bando"
                                  setFieldValue={setFieldValue}
                                  field={field}
                                />
                              </>
                            )}
                          </Field>
                        </Grid.Column>
                      </Grid.Row>
                      <div className="p-title">
                        <h1>
                          <FormattedMessage
                            id="announcement.attachments"
                            defaultMessage="Allegati"
                          />
                        </h1>
                      </div>
                      <Grid.Row columns={1}>
                        <Grid.Column>
                          <Field name="attachmentsToUpload">
                            {() => (
                              <>
                                <UploadDropzone />
                                {values.attachmentsToUpload.length > 0 && (
                                  <div className="toBeUploaded">
                                    <h3>
                                      <FormattedMessage
                                        id="announcementEdit.filesToUpload"
                                        defaultMessage="Documenti da caricare"
                                      />
                                    </h3>
                                    <div className="upload__list">
                                      {values.attachmentsToUpload.map(
                                        (file, i) => (
                                          <FileThumb
                                            key={i}
                                            file={file}
                                            index={i}
                                            fileFile={file}
                                          />
                                        )
                                      )}
                                    </div>
                                  </div>
                                )}
                                {values.attachments.length > 0 && (
                                  <div className="alreadyUploaded">
                                    <h3>
                                      <FormattedMessage
                                        id="announcementEdit.uploadedFiles"
                                        defaultMessage="Documenti caricati"
                                      />
                                    </h3>
                                    <div className="upload__list">
                                      {values.attachments.map((file, i) => (
                                        <FileThumb
                                          key={i}
                                          file={file}
                                          startDownload={startDownload}
                                        />
                                      ))}
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </Field>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={2}>
                        <Grid.Column>
                          <div>
                            <h3>
                              <FormattedMessage
                                id="announcement.createdAtDetails"
                                defaultMessage={"Creazione del bando"}
                              />
                            </h3>
                          </div>
                          {!_.isEmpty(state.announcement?.createdAt) ? (
                            <FormattedMessage
                              id="registerMessage"
                              defaultMessage="Il bando è stato creato da {createdBy} il {createdAt}"
                              values={{
                                createdAt: moment(
                                  state.announcement?.createdAt
                                ).format("DD-MM-YYYY"),
                                createdBy:
                                  state.announcement?.createdBy?.displayName,
                              }}
                            />
                          ) : (
                            "Non sono presenti dati"
                          )}
                        </Grid.Column>
                        <Grid.Column>
                          <div>
                            <h3>
                              <FormattedMessage
                                id="announcement.updatedAtDetails"
                                defaultMessage={"Modifica del bando"}
                              />
                            </h3>
                          </div>
                          <div>
                            {!_.isEmpty(state.announcement?.updatedAt) ? (
                              <FormattedMessage
                                id="registerMessage"
                                defaultMessage="Il bando è stato modificato da {createdBy} il {createdAt}"
                                values={{
                                  createdAt: moment(
                                    state.announcement?.updatedAt
                                  ).format("DD-MM-YYYY"),
                                  createdBy:
                                    state.announcement?.updatedBy?.displayName,
                                }}
                              />
                            ) : (
                              "Non sono presenti dati"
                            )}
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row className="call-to-action">
                        <Grid.Column>
                          <Button
                            className="button--underline"
                            onClick={() => {
                              if (state.announcement?.isArchived)
                                navigate(`${FE_ROUTES.ARCHIVIO_BANDI}`, {
                                  replace: true,
                                });
                              else
                                navigate(`${FE_ROUTES.GESTIONE_BANDI}`, {
                                  replace: true,
                                });
                            }}
                          >
                            <Icon name="arrow left" />
                            <div className="button--text">
                              <FormattedMessage
                                id="announcementEdit.back"
                                defaultMessage="Annulla"
                              />
                            </div>
                          </Button>
                        </Grid.Column>
                        <Grid.Column>
                          <Button
                            type="submit"
                            onClick={() => {
                              handleSubmit(
                                validateForm,
                                submitForm,
                                setFieldError,
                                setSubmitting,
                                values
                              );
                            }}
                            loading={isSubmitting}
                          >
                            <div>
                              <Icon name="check" />
                              <FormattedMessage
                                id="announcementEdit.confirm"
                                defaultMessage="Conferma"
                              />
                            </div>
                          </Button>
                        </Grid.Column>
                      </Grid.Row>
                    </Form>
                  </>
                )}
              </Formik>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AnnouncementDetail;
