import { FormikErrors } from "formik";
import { AnnouncementValuesTypes } from "pages/Announcement/types";
import { User } from "services/user/types";

/** Focus on first error - if any - when submitting */
export const focusOnError = (
  errors: FormikErrors<AnnouncementValuesTypes | User>
) => {
  const firstError = document.getElementById(Object.keys(errors)[0]);
  firstError?.scrollIntoView({
    behavior: "smooth",
    block: "center",
  });
};
