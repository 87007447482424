import { Customer } from "services/customer/types";
import { SelectValueTextObject, SelectValueLabelObject } from "utils/types";
import { FilterTooltipObj } from "../types";

export enum FilterAnnouncementActionConstants {
  SET_OPTIONS = "FilterAnnouncement/SET_OPTIONS",
  SET_CUSTOMER_LIST = "FilterAnnouncement/SET_CUSTOMER_LIST",
  SET_CUSTOMER_OPTIONS = "FilterAnnouncement/SET_CUSTOMER_OPTIONS",
  SET_GEOGRAPHIC_REFERENCE_OPTIONS = "FilterAnnouncement/SET_GEOGRAPHIC_REFERENCE_OPTIONS",
  SET_ECONOMIC_ACTIVITY_OPTIONS = "FilterAnnouncement/SET_ECONOMIC_ACTIVITY_OPTIONS",
  SET_COMPANY_SIZE_OPTIONS = "FilterAnnouncement/SET_COMPANY_SIZE_OPTIONS",
  SET_TOPIC_OPTIONS = "FilterAnnouncement/SET_TOPIC_OPTIONS",
  SET_FUNDING_TYPE_OPTIONS = "FilterAnnouncement/SET_FUNDING_TYPE_OPTIONS",
  SET_APPLICANT_COMPANY_FORM_OPTIONS = "FilterAnnouncement/SET_APPLICANT_COMPANY_FORM_OPTIONS",
  SET_ELIGIBLE_COST_OPTIONS = "FilterAnnouncement/SET_ELIGIBLE_COST_OPTIONS",
  SET_PROMOTING_INSTITUTION_OPTIONS = "FilterAnnouncement/SET_PROMOTING_INSTITUTION_OPTIONS",
  SET_IS_LOADING = "FilterAnnouncement/SET_IS_LOADING",
  SET_IS_LOADING_ECONOMIC_ACTIVITY = "FilterAnnouncement/SET_IS_LOADING_ECONOMIC_ACTIVITY",
  SET_IS_LOADING_GEOGRAPHIC_REFERENCE = "FilterAnnouncement/SET_IS_LOADING_GEOGRAPHIC_REFERENCE",
  SET_IS_LOADING_COMPANY_SIZE = "FilterAnnouncement/SET_IS_LOADING_COMPANY_SIZE",
  SET_IS_LOADING_TOPIC = "FilterAnnouncement/SET_IS_LOADING_TOPIC",
  SET_IS_LOADING_FUNDING_TYPE = "FilterAnnouncement/SET_IS_LOADING_FUNDING_TYPE",
  SET_IS_LOADING_APPLICANT_COMPANY_FORM = "FilterAnnouncement/SET_IS_LOADING_APPLICANT_COMPANY_FORM",
  SET_IS_LOADING_ELIGIBLE_COST = "FilterAnnouncement/SET_IS_LOADING_ELIGIBLE_COST",
  SET_IS_LOADING_PROMOTING_INSTITUTION = "FilterAnnouncement/SET_IS_LOADING_PROMOTING_INSTITUTION",
  SET_FILTER_TOOLTIPS = "FilterAnnouncement/SET_FILTER_TOOLTIPS",
}

export type FilterAnnouncementAction =
  | {
      type: FilterAnnouncementActionConstants.SET_OPTIONS;
      payload: { options: Array<SelectValueTextObject> };
    }
  | {
      type: FilterAnnouncementActionConstants.SET_CUSTOMER_LIST;
      payload: { customerList: Array<Customer> };
    }
  | {
      type: FilterAnnouncementActionConstants.SET_CUSTOMER_OPTIONS;
      payload: { customerOptions: Array<SelectValueLabelObject> };
    }
  | {
      type: FilterAnnouncementActionConstants.SET_GEOGRAPHIC_REFERENCE_OPTIONS;
      payload: { geographicReferenceOptions: Array<SelectValueTextObject> };
    }
  | {
      type: FilterAnnouncementActionConstants.SET_ECONOMIC_ACTIVITY_OPTIONS;
      payload: { economicActivityOptions: Array<SelectValueTextObject> };
    }
  | {
      type: FilterAnnouncementActionConstants.SET_COMPANY_SIZE_OPTIONS;
      payload: { companySizeOptions: Array<SelectValueTextObject> };
    }
  | {
      type: FilterAnnouncementActionConstants.SET_TOPIC_OPTIONS;
      payload: { topicOptions: Array<SelectValueTextObject> };
    }
  | {
      type: FilterAnnouncementActionConstants.SET_FUNDING_TYPE_OPTIONS;
      payload: { fundingTypeOptions: Array<SelectValueTextObject> };
    }
  | {
      type: FilterAnnouncementActionConstants.SET_APPLICANT_COMPANY_FORM_OPTIONS;
      payload: { applicantCompanyFormOptions: Array<SelectValueTextObject> };
    }
  | {
      type: FilterAnnouncementActionConstants.SET_ELIGIBLE_COST_OPTIONS;
      payload: { eligibleCostOptions: Array<SelectValueTextObject> };
    }
  | {
      type: FilterAnnouncementActionConstants.SET_PROMOTING_INSTITUTION_OPTIONS;
      payload: { promotingInstitutionOptions: Array<SelectValueTextObject> };
    }
  | {
      type: FilterAnnouncementActionConstants.SET_IS_LOADING;
      payload: { isLoading: boolean };
    }
  | {
      type: FilterAnnouncementActionConstants.SET_IS_LOADING_ECONOMIC_ACTIVITY;
      payload: { isLoadingEconomicActivity: boolean };
    }
  | {
      type: FilterAnnouncementActionConstants.SET_IS_LOADING_GEOGRAPHIC_REFERENCE;
      payload: { isLoadingGeoReference: boolean };
    }
  | {
      type: FilterAnnouncementActionConstants.SET_IS_LOADING_COMPANY_SIZE;
      payload: { isLoadingCompanySize: boolean };
    }
  | {
      type: FilterAnnouncementActionConstants.SET_IS_LOADING_TOPIC;
      payload: { isLoadingTopic: boolean };
    }
  | {
      type: FilterAnnouncementActionConstants.SET_IS_LOADING_FUNDING_TYPE;
      payload: { isLoadingFundingType: boolean };
    }
  | {
      type: FilterAnnouncementActionConstants.SET_IS_LOADING_APPLICANT_COMPANY_FORM;
      payload: { isLoadingApplicantCompanyForm: boolean };
    }
  | {
      type: FilterAnnouncementActionConstants.SET_IS_LOADING_ELIGIBLE_COST;
      payload: { isLoadingEligibleCost: boolean };
    }
  | {
      type: FilterAnnouncementActionConstants.SET_IS_LOADING_PROMOTING_INSTITUTION;
      payload: { isLoadingPromotingInstitution: boolean };
    }
  | {
      type: FilterAnnouncementActionConstants.SET_FILTER_TOOLTIPS;
      payload: { tooltips: FilterTooltipObj };
    };
