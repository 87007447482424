import { AppAction, appActionConstants } from "pages/App/reducer/actions";
import standardClient from "services/client/standardRequestClient";
import { API } from "utils/global/backendRoutes";
//--- types ---//
import { MySelf } from "utils/types/mySelf";
import { AxiosResponse } from "axios";
import { NavigateFunction } from "react-router-dom";
import { toast } from "react-toastify";
import { FORBIDDEN } from "utils/global/standardError";

export const loadMyself = (
  dispatch: React.Dispatch<AppAction>,
  navigate?: NavigateFunction
): Promise<void> => {
  return standardClient
    .request({
      baseURL: API.BASE_URL,
      url: API.MYSELF,
      method: "GET",
    })
    .then((res: AxiosResponse<MySelf>) => {
      dispatch({
        type: appActionConstants.SET_MYSELF,
        payload: { mySelf: res.data },
      });
      dispatch({ type: appActionConstants.SET_SESSION });
    })
    .catch((error) => {
      if (error.data && error.data.title === FORBIDDEN) {
        toast.error("Utente non autorizzato");
      } else if (error.statusText && error.statusText === FORBIDDEN) {
        toast.error("Utente non autorizzato");
      }
      dispatch({ type: appActionConstants.REVOKE_SESSION });
      if (navigate) navigate("/login");
    })
    .finally(() => {
      setTimeout(() => {
        dispatch({
          type: appActionConstants.SET_USER_LOADING,
          payload: { isLoading: false },
        });
      }, 900);
    });
};
