import React from "react";
import ErrorBoundary from "components/ErrorBoundaries";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import LangProvider from "components/LangProvider";
import App from "pages/App";
import { isLoaded, setConfig } from "utils/global/backendRoutes";

/**
 * If config was aside the index is ok or then start with default values
 */
const request = async () => {
  try {
    const response = await fetch(`${window.location.origin}/config.json`);

    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};

const render = async () => {
  if (!isLoaded()) {
    const result = await request();
    setConfig(result);
  }

  ReactDOM.render(
    <BrowserRouter>
      <ErrorBoundary>
        <LangProvider>
          <App />
        </LangProvider>
      </ErrorBoundary>
    </BrowserRouter>,
    document.getElementById("app")
  );
};

render();
