import moment, { Moment } from "moment";
import _ from "lodash";

export const setNewAnnouncement = (
  announcementCreatedAt: Date | null,
  announcementOpeningDate: Date | null
): boolean => {
  let isNew = false;
  const createdAt = announcementCreatedAt
    ? moment(announcementCreatedAt)
    : null;
  if (createdAt)
    createdAt.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const openingDate = announcementOpeningDate
    ? moment(announcementOpeningDate)
    : null;
  if (openingDate)
    openingDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  let latestDate: Moment | null = null;
  const compareDate = moment().subtract(10, "days");
  compareDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  if (!_.isNull(createdAt) && !_.isNull(openingDate)) {
    if (createdAt.isBefore(openingDate)) latestDate = openingDate;
    else latestDate = createdAt;
  } else if (!_.isNull(createdAt)) {
    latestDate = createdAt;
  } else if (!_.isNull(openingDate)) latestDate = openingDate;
  if (
    compareDate.isSameOrBefore(latestDate) &&
    latestDate?.isSameOrBefore(
      moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    )
  )
    isNew = true;
  return isNew;
};
