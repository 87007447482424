import { SelectValueTextObject } from "utils/types";
import { FilterTableGridType } from "../types";

export enum FilterManagementActionConstants {
  SET_FILTER_LIST = "FilterManagement'SET_FILTER_MANAGEMENT",
  SET_GEOGRAPHIC_REFERENCE_OPTIONS = "FilterAnnouncement/SET_GEOGRAPHIC_REFERENCE_OPTIONS",
  SET_ECONOMIC_ACTIVITY_OPTIONS = "FilterAnnouncement/SET_ECONOMIC_ACTIVITY_OPTIONS",
  SET_COMPANY_SIZE_OPTIONS = "FilterAnnouncement/SET_COMPANY_SIZE_OPTIONS",
  SET_TOPIC_OPTIONS = "FilterAnnouncement/SET_TOPIC_OPTIONS",
  SET_FUNDING_TYPE_OPTIONS = "FilterAnnouncement/SET_FUNDING_TYPE_OPTIONS",
  SET_APPLICANT_COMPANY_FORM_OPTIONS = "FilterAnnouncement/SET_APPLICANT_COMPANY_FORM_OPTIONS",
  SET_ELIGIBLE_COST_OPTIONS = "FilterAnnouncement/SET_ELIGIBLE_COST_OPTIONS",
  SET_PROMOTING_INSTITUTION_OPTIONS = "FilterAnnouncement/SET_PROMOTING_INSTITUTION_OPTIONS",
  SET_IS_LOADING = "FilterAnnouncement/SET_IS_LOADING",
  SET_IS_LOADING_ECONOMIC_ACTIVITY = "FilterAnnouncement/SET_IS_LOADING_ECONOMIC_ACTIVITY",
  SET_IS_LOADING_GEOGRAPHIC_REFERENCE = "FilterAnnouncement/SET_IS_LOADING_GEOGRAPHIC_REFERENCE",
  SET_IS_LOADING_COMPANY_SIZE = "FilterAnnouncement/SET_IS_LOADING_COMPANY_SIZE",
  SET_IS_LOADING_TOPIC = "FilterAnnouncement/SET_IS_LOADING_TOPIC",
  SET_IS_LOADING_FUNDING_TYPE = "FilterAnnouncement/SET_IS_LOADING_FUNDING_TYPE",
  SET_IS_LOADING_APPLICANT_COMPANY_FORM = "FilterAnnouncement/SET_IS_LOADING_APPLICANT_COMPANY_FORM",
  SET_IS_LOADING_ELIGIBLE_COST = "FilterAnnouncement/SET_IS_LOADING_ELIGIBLE_COST",
  SET_IS_LOADING_PROMOTING_INSTITUTION = "FilterAnnouncement/SET_IS_LOADING_PROMOTING_INSTITUTION",
}

export type FilterManagementAction =
  | {
      type: FilterManagementActionConstants.SET_FILTER_LIST;
      payload: { filters: Array<FilterTableGridType> };
    }
  | {
      type: FilterManagementActionConstants.SET_GEOGRAPHIC_REFERENCE_OPTIONS;
      payload: { geographicReferenceOptions: Array<SelectValueTextObject> };
    }
  | {
      type: FilterManagementActionConstants.SET_ECONOMIC_ACTIVITY_OPTIONS;
      payload: { economicActivityOptions: Array<SelectValueTextObject> };
    }
  | {
      type: FilterManagementActionConstants.SET_COMPANY_SIZE_OPTIONS;
      payload: { companySizeOptions: Array<SelectValueTextObject> };
    }
  | {
      type: FilterManagementActionConstants.SET_TOPIC_OPTIONS;
      payload: { topicOptions: Array<SelectValueTextObject> };
    }
  | {
      type: FilterManagementActionConstants.SET_FUNDING_TYPE_OPTIONS;
      payload: { fundingTypeOptions: Array<SelectValueTextObject> };
    }
  | {
      type: FilterManagementActionConstants.SET_APPLICANT_COMPANY_FORM_OPTIONS;
      payload: { applicantCompanyFormOptions: Array<SelectValueTextObject> };
    }
  | {
      type: FilterManagementActionConstants.SET_ELIGIBLE_COST_OPTIONS;
      payload: { eligibleCostOptions: Array<SelectValueTextObject> };
    }
  | {
      type: FilterManagementActionConstants.SET_PROMOTING_INSTITUTION_OPTIONS;
      payload: { promotingInstitutionOptions: Array<SelectValueTextObject> };
    }
  | {
      type: FilterManagementActionConstants.SET_IS_LOADING;
      payload: { isLoading: boolean };
    }
  | {
      type: FilterManagementActionConstants.SET_IS_LOADING_ECONOMIC_ACTIVITY;
      payload: { isLoadingEconomicActivity: boolean };
    }
  | {
      type: FilterManagementActionConstants.SET_IS_LOADING_GEOGRAPHIC_REFERENCE;
      payload: { isLoadingGeoReference: boolean };
    }
  | {
      type: FilterManagementActionConstants.SET_IS_LOADING_COMPANY_SIZE;
      payload: { isLoadingCompanySize: boolean };
    }
  | {
      type: FilterManagementActionConstants.SET_IS_LOADING_TOPIC;
      payload: { isLoadingTopic: boolean };
    }
  | {
      type: FilterManagementActionConstants.SET_IS_LOADING_FUNDING_TYPE;
      payload: { isLoadingFundingType: boolean };
    }
  | {
      type: FilterManagementActionConstants.SET_IS_LOADING_APPLICANT_COMPANY_FORM;
      payload: { isLoadingApplicantCompanyForm: boolean };
    }
  | {
      type: FilterManagementActionConstants.SET_IS_LOADING_ELIGIBLE_COST;
      payload: { isLoadingEligibleCost: boolean };
    }
  | {
      type: FilterManagementActionConstants.SET_IS_LOADING_PROMOTING_INSTITUTION;
      payload: { isLoadingPromotingInstitution: boolean };
    };
