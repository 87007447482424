import { MySelf } from "utils/types/mySelf";
import { FooterStateType, MenuItem } from "../types";

export enum appActionConstants {
  SET_SESSION = "App/SET_SESSION",
  SET_USER_LOADING = "App/SET_USER_LOADING",
  REVOKE_SESSION = "App/REVOKE_SESSION",
  SET_MYSELF = "App/SET_MYSELF",
  SET_USER_BLOCKED = "App/USER_BLOCKED",
  SET_REMAINING_ATTEMPTS = "App/REMAINING_ATTEMPTS",
  SET_FOOTER_STATE = "App/SET_FOOTER_STATE",
  SET_TOPBAR_STATE = "App/SET_TOPBAR_STATE",
  SET_LOGO = "App/SET_LOGO",
  SET_WAVE_BG = "App/SET_WAVE_BG",
}

export type AppAction =
  | { type: appActionConstants.SET_SESSION }
  | {
      type: appActionConstants.SET_USER_LOADING;
      payload: { isLoading: boolean };
    }
  | { type: appActionConstants.REVOKE_SESSION }
  | {
      type: appActionConstants.SET_MYSELF;
      payload: { mySelf: MySelf };
    }
  | {
      type: appActionConstants.SET_REMAINING_ATTEMPTS;
      payload: { remainingAttempts: number };
    }
  | { type: appActionConstants.SET_USER_BLOCKED }
  | {
      type: appActionConstants.SET_FOOTER_STATE;
      payload: { footerState: FooterStateType };
    }
  | {
      type: appActionConstants.SET_TOPBAR_STATE;
      payload: { topbarState: Array<MenuItem> };
    }
  | {
      type: appActionConstants.SET_LOGO;
      payload: { logo: string };
    }
  | {
      type: appActionConstants.SET_WAVE_BG;
      payload: { waveBg: string };
    };
