import { AxiosResponse, AxiosPromise } from "axios";
import standardClient from "services/client/standardRequestClient";
import { toast } from "react-toastify";
import { API } from "utils/global/backendRoutes";
import { FileTypes } from "components/FileThumb/types";
import { Announcement } from "pages/Announcement/types";
import { StandardResponseError } from "services/client/types";
import _ from "lodash";
import {
  AnnouncementDetailActionConstants,
  AnnouncementDetailAction,
} from "pages/AnnouncementDetail/reducer/actions";

/**
 * Upload attachments.
 * @param files
 * @param id
 * @param formikHelpers
 * @returns
 */
export const uploadAttachments = (
  files: Array<FileTypes>,
  id: string | null
): Promise<void> => {
  const bodyFormData = new FormData();
  _.forEach(files, (file, i) => {
    bodyFormData.append(`params[files][${i}]`, file, file.name);
  });
  bodyFormData.set("params[entity_id]", id ?? "");
  bodyFormData.set("params[entity_type]", "document");
  bodyFormData.set("method", "upload-announcement-document");

  return standardClient({
    url: `${API.ATTACHMENT_MANAGER}`,
    method: "POST",
    data: bodyFormData,
  })
    .then(() => {
      toast.success("Allegati caricati correttamente");
    })
    .catch(() => {
      toast.error("Errore durante il salvataggio degli allegati");
    });
};

/**
 * Download attachments.
 * @param id
 * @returns
 */
const downloadAttachment = (id: string): AxiosPromise => {
  const bodyFormData = new FormData();
  bodyFormData.set("params[id]", id ?? "");
  bodyFormData.set("method", "download");

  return standardClient({
    url: `${API.ATTACHMENT_MANAGER}`,
    method: "POST",
    responseType: "blob",
    data: bodyFormData,
  });
};

export const downloadRes = (
  id: string,
  action: string,
  dispatch?: React.Dispatch<AnnouncementDetailAction>
): void => {
  downloadAttachment(id)
    .then((res) => {
      const contentDisposition: string = res.headers["content-disposition"]
        ? res.headers["content-disposition"]
        : "";
      const match = contentDisposition.match(`filename="(.*)"`);
      const filename = match ? match[1] : "";
      const newBlob = new Blob([res.data], { type: res.data.type });
      const fileUrl = window.URL.createObjectURL(newBlob);
      const tempLink = document.createElement("a");
      tempLink.href = fileUrl;
      if (action === "download") tempLink.setAttribute("download", filename);
      if (action === "preview") tempLink.setAttribute("target", "_blank");
      tempLink.click();
      const link = document.querySelector(`[href="${fileUrl}"]`);
      link ? link.remove() : null;
    })
    .finally(() => {
      if (dispatch) {
        dispatch({
          type: AnnouncementDetailActionConstants.SET_IS_LOADING_ATTACHMENT,
          payload: {
            isLoadingAttachment: false,
          },
        });
        dispatch({
          type: AnnouncementDetailActionConstants.SET_LOADING_INDEX,
          payload: {
            loadingIndex: null,
          },
        });
      }
    });
};

/**
 * Delete attachments.
 * @param id
 * @returns
 */
export const deleteAttachment = (id: string | null): Promise<void> => {
  const bodyFormData = new FormData();
  bodyFormData.set("params[id]", id ?? "");
  bodyFormData.set("method", "delete");

  return (
    standardClient({
      url: `${API.ATTACHMENT_MANAGER}`,
      method: "POST",
      data: bodyFormData,
    })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((response: AxiosResponse<Announcement>) => {
        toast.success("Allegato eliminato correttamente");
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch((error: AxiosResponse<StandardResponseError>) => {
        toast.error("Errore durante l'eliminazione dell'allegato");
      })
  );
};

/**
 * Download PDF.
 * @param id
 * @returns
 */
const downloadAnnouncementPDF = (id: string): AxiosPromise => {
  return standardClient({
    url: `${API.EXPORT_ANNOUNCEMENT_PDF}`,
    method: "POST",
    responseType: "blob",
    data: { id },
  });
};

export const downloadPDF = (
  id: string,
  action: string,
  dispatch: React.Dispatch<AnnouncementDetailAction>
): void => {
  downloadAnnouncementPDF(id)
    .then((res) => {
      const contentDisposition: string = res.headers["content-disposition"]
        ? res.headers["content-disposition"]
        : "";
      const match = contentDisposition.match(`filename="(.*)"`);
      const filename = match ? match[1] : "";
      const newBlob = new Blob([res.data], { type: res.data.type });
      const fileUrl = window.URL.createObjectURL(newBlob);
      const tempLink = document.createElement("a");
      tempLink.href = fileUrl;
      if (action === "download") tempLink.setAttribute("download", filename);
      if (action === "preview") tempLink.setAttribute("target", "_blank");
      tempLink.click();
      const link = document.querySelector(`[href="${fileUrl}"]`);
      link ? link.remove() : null;
    })
    .catch(() => {
      toast.error("Errore durante il download del file");
    })
    .finally(() => {
      dispatch({
        type: AnnouncementDetailActionConstants.SET_IS_LOADING_PDF,
        payload: {
          isLoadingPdf: false,
        },
      });
    });
};
