import {
  FilterTableType,
  FilterTreeDataObj,
  FilterTreeFormType,
} from "../types";
import { FilterTooltipType } from "components/TooltipEdit/types";

export enum FilterEditActionConstants {
  SET_FILTER_VALUES = "FilterEdit/SET_FILTER_VALUES",
  SET_IS_LOADING = "FilterEdit/SET_IS_LOADING",
  SET_FILTER_TREE_DATA = "FilterEdit/SET_FILTER_TREE_DATA",
  SET_INITIAL_FORM_TREE_VALUES = "FilterEdit/SET_INITIAL_FORM_TREE_VALUES",
  SET_MODAL_OPEN = "FilterEdit/SET_MODAL_OPEN",
  SET_FILTER_TOOLTIP = "FilterEdit/SET_FILTER_TOOLTIP",
  SET_TOOLTIP_LOADING = "FilterEdit/SET_TOOLTIP_LOADING",
}

export type FilterEditAction =
  | {
      type: FilterEditActionConstants.SET_FILTER_VALUES;
      payload: { filterValues: Array<FilterTableType> };
    }
  | {
      type: FilterEditActionConstants.SET_IS_LOADING;
      payload: { isLoading: boolean };
    }
  | {
      type: FilterEditActionConstants.SET_FILTER_TREE_DATA;
      payload: { filterTreeData: Array<FilterTreeDataObj> };
    }
  | {
      type: FilterEditActionConstants.SET_INITIAL_FORM_TREE_VALUES;
      payload: { initialFormTreeValues: FilterTreeFormType };
    }
  | {
      type: FilterEditActionConstants.SET_MODAL_OPEN;
      payload: { modalOpen: boolean };
    }
  | {
      type: FilterEditActionConstants.SET_FILTER_TOOLTIP;
      payload: { tooltip: FilterTooltipType };
    }
  | {
      type: FilterEditActionConstants.SET_TOOLTIP_LOADING;
      payload: { tooltipLoading: boolean };
    };
