import React, { useEffect, useState } from "react";
import { Image } from "semantic-ui-react";
import { Outlet } from "react-router";

// Images
import {
  BG_ASSILEA,
  BG_MEDIOBANCA,
  BG_CLICCABANDI,
  BG_VALSABBINA,
} from "utils/global/tenantConstants";

const AccessLayout: React.FC = () => {
  const [bgImage, setBgImage] = useState("");

  useEffect(() => {
    const hostname = window.location.hostname;
    const subdomain = hostname.split(".")[0];

    switch (subdomain) {
      case "assilea":
        setBgImage(BG_ASSILEA);
        break;
      case "mediobanca":
        setBgImage(BG_MEDIOBANCA);
        break;
      case "bancavalsabbina":
        setBgImage(BG_VALSABBINA);
        break;
      default:
        setBgImage(BG_CLICCABANDI);
    }
  }, []);

  return (
    <div className="access">
      <Image className="bg-image" src={bgImage} />
      <Outlet />
    </div>
  );
};

export default AccessLayout;
