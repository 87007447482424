import { AxiosResponse } from "axios";
import { toast } from "react-toastify";

// Internals and constants
import { API } from "utils/global/backendRoutes";
import { AppAction, appActionConstants } from "pages/App/reducer/actions";
import authClient from "services/client/authRequestClient";
import { INVALID_USER_DATA } from "utils/global/standardError";
import { updateAuthObject } from "utils/function/localStorage";
import { loadMyself } from "./loadMyself";

// Types
import { AuthObject, StandardResponseError } from "services/client/types";
import { LoginFormValues } from "components/Forms/LoginForm/types";
import { NavigateFunction } from "react-router-dom";
import { UserLoginResponse } from "./types";
import { HybridAuthFormValues } from "components/Forms/HybridAuthForm/types";

export const userLogin = (
  dispatch: React.Dispatch<AppAction>,
  userData: LoginFormValues,
  grant: string,
  navigate: NavigateFunction
): void | boolean => {
  // Add to request data also the grant type (password in this case)
  const requestData = { ...userData, grant_type: grant };
  dispatch({
    type: appActionConstants.SET_USER_LOADING,
    payload: { isLoading: true },
  });

  authClient
    .request({
      url: API.OAUTH_GRANT_PASSWORD,
      method: "POST",
      data: requestData,
    })
    .then((response: AxiosResponse<UserLoginResponse>) => {
      const responseData = response.data;

      const authObject: AuthObject = {
        accessTokken: responseData.access_token,
        expiresIn: responseData.expires_in.toString(),
        refreshToken: responseData.refresh_token,
        scope: responseData.scope,
        tokenType: responseData.token_type,
      };

      updateAuthObject(authObject);

      loadMyself(dispatch, navigate);
    })
    .catch((error: AxiosResponse<StandardResponseError>) => {
      if (error.data && error.data.title === INVALID_USER_DATA) {
        toast.error("Password o username non validi");
      } else if (error.statusText && error.statusText === INVALID_USER_DATA) {
        toast.error("Password o username non validi");
      }
      if (
        error.data &&
        error.data.title &&
        error.data.title === "user_blocked"
      ) {
        dispatch({
          type: appActionConstants.SET_USER_BLOCKED,
        });
      }
      dispatch({
        type: appActionConstants.SET_USER_LOADING,
        payload: { isLoading: false },
      });
    });
};

export const hybridAuthBsvLogin = (
  dispatch: React.Dispatch<AppAction>,
  userData: HybridAuthFormValues,
  navigate: NavigateFunction
): void | boolean => {
  const requestData = { ...userData };

  authClient
    .request({
      url: API.OAUTH_BVS + "/QTorUgkI2Speo5KK5lYEWvN0cpoQ0WgEyN28",
      method: "POST",
      data: requestData,
    })
    .then((response: AxiosResponse<any>) => {
      const responseData = response.data;

      console.log(responseData);

      navigate(response.data.url);
    })
    .catch((error: AxiosResponse<StandardResponseError>) => {
      toast.error("Errore nell'autenticazione ibrida");
      console.log(error.data);
    });
};
