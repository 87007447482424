import { FirstLoginAction, FirstLoginActionConstants } from "./actions";

export type FirstLoginState = {
  token: string | null;
  isLoading: boolean;
  status: boolean | null;
};

export const initialFirstLoginState: FirstLoginState = {
  token: null,
  isLoading: false,
  status: null,
};

export function firstLoginReducer(
  state: FirstLoginState,
  action: FirstLoginAction
): FirstLoginState {
  const { type } = action;

  switch (type) {
    case FirstLoginActionConstants.SET_ACTIVATION_STATUS:
      return { ...state, status: action.payload.status };
    case FirstLoginActionConstants.SET_IS_LOADING:
      return { ...state, isLoading: action.payload.isLoading };
    case FirstLoginActionConstants.SET_ACTIVATION_TOKEN:
      return { ...state, token: action.payload.token };
    default:
      return state;
  }
}
