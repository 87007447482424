import React, { useState } from "react";
import { Form, Input, SubmitButton } from "formik-semantic-ui-react";
import { ErrorMessage, Formik } from "formik";
import { Grid } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import * as Yup from "yup";

import { RegistrationRequestState } from "./types";

const RegisterForm: React.FC = () => {
  /**
   * The "request" state contains information about the current registration request.
   * Basically, when a registration request is sent the form will be replaced with a new component
   * with some details (check your inbox, for instance).
   */
  const [request, setRequest] = useState<RegistrationRequestState>({
    sent: false,
    email: null,
  });

  return (
    <>
      {request.sent && (
        <FormattedMessage
          id="registerMessage"
          defaultMessage="La richiesta di registrazione è stata inviata a {email}, controlla la tua casella postale."
          values={{
            email: request.email,
          }}
        />
      )}
      {!request.sent && (
        <Formik
          initialValues={{
            email: null,
            password: null,
            confirmPassword: null,
          }}
          onSubmit={(values) => {
            // TODO: The register API is not developed yet.
            setTimeout(() => {
              setRequest({
                sent: true,
                email: values.email,
              });
            }, 3000);
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .required("Email è un campo richiesto")
              .nullable(),
            password: Yup.string()
              .required("Password è un campo richiesto")
              .nullable(),
            confirmPassword: Yup.string()
              .required("Conferma password è un campo richiesto")
              .nullable()
              .oneOf([Yup.ref("password"), null]),
          })}
        >
          <Form>
            <Grid className="register-form">
              <Grid.Row>
                <Input
                  name="email"
                  placeholder="email"
                  label={<FormattedMessage id="email" defaultMessage="Email" />}
                />
                <ErrorMessage name="email" />
              </Grid.Row>

              <Grid.Row columns={2}>
                <Grid.Column className="register-column-password">
                  <Input
                    name="password"
                    placeholder="Password"
                    label={
                      <FormattedMessage
                        id="password"
                        defaultMessage="Password"
                      />
                    }
                  />
                  <ErrorMessage name="password" />
                </Grid.Column>
                <Grid.Column className="register-column-confirm-password">
                  <Input
                    name="confirmPassword"
                    placeholder="confirmPassword"
                    label={
                      <FormattedMessage
                        id="confirmPassword"
                        defaultMessage="Conferma password"
                      />
                    }
                  />
                  <ErrorMessage name="confirmPassword" />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <SubmitButton
                  content={
                    <FormattedMessage
                      id="register"
                      defaultMessage="Registrati"
                    />
                  }
                />
              </Grid.Row>
            </Grid>
          </Form>
        </Formik>
      )}
    </>
  );
};

export default RegisterForm;
