import React from "react";
import ContactForm from "components/Forms/ContactForm";

const Contact: React.FC = () => {
  return (
    <div className="contact-container">
      <ContactForm />
    </div>
  );
};

export default Contact;
