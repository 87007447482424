import React, { createContext, useEffect, useReducer, useState } from "react";
import { Dimmer, Header, Loader } from "semantic-ui-react";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "moment/locale/it";
import { ConfirmProvider } from "material-ui-confirm";
import _ from "lodash";
/** Components */
import CheckAuth from "components/CheckAuth";
import CheckRole from "components/CheckRole";
import ScrollToTop from "components/ScrollToTop";
/** Internals and constants */
import { AppAction, appActionConstants } from "./reducer/actions";
import { appReducer, AppState, initialAppState } from "./reducer/reducer";
import {
  CUSTOM_EVENT,
  FE_ROUTES,
  LOCAL_STORAGE,
  ROLES,
} from "utils/global/globalCostants";
import { loadMyself } from "services/auth/loadMyself";
import { clearAuthObject } from "utils/function/localStorage";
import { setTenantVariables } from "utils/function/setTenantVariables";
import { AuthObject } from "services/client/types";
import { updateAuthObject } from "utils/function/localStorage";
/** Layout & style */
import StandardLayout from "layout/StandardLayout";
import AccessLayout from "layout/AccessLayout";
import ElencoBandiLayout from "layout/ElencoBandiLayout";
import "semantic-ui-less/semantic.less";
import "react-toastify/dist/ReactToastify.css";
import "./style.less";
import "react-sortable-tree/style.css";
/** Pages */
import Login from "pages/Login";
import Register from "pages/Register";
import FirstLogin from "pages/FirstLogin";
import PasswordRecovery from "pages/PasswordRecovery";
import HomePage from "pages/HomePage";
import User from "pages/User";
// import CustomerProfile from "pages/CustomerProfile";
import UserProfile from "pages/UserProfile";
import AnnouncementDetail from "pages/AnnouncementDetail";
import AnnouncementEdit from "pages/AnnouncementEdit";
import AnnouncementManagement from "pages/AnnouncementManagement";
import AnnouncementArchive from "pages/AnnouncementArchive";
import Contact from "pages/Contact";
// import Customer from "pages/Customer";
import ElencoBandiGridLayout from "layout/ElencoBandiGridLayout";
import PasswordReset from "pages/PasswordReset";
import FilterManagement from "pages/FilterManagement";
import FilterEdit from "pages/FilterEdit";
import TopbarLayout from "layout/TopbarLayout/TopbarLayout";
import HybridAuth from "pages/HybridAuth";

export const AppContext = createContext<{
  state: AppState;
  dispatch: React.Dispatch<AppAction>;
}>({
  state: initialAppState,
  dispatch: () => null,
});

const App: React.FC = () => {
  const [state, dispatch] = useReducer(appReducer, initialAppState);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  // const [firstAccess, setFirstAccess] = useState<boolean>(true);

  useEffect(() => {
    // Custom event attachment for handle API errors
    window.addEventListener(CUSTOM_EVENT.LOGOUT, () => {
      clearAuthObject();
      console.log("Logout");
      dispatch({ type: appActionConstants.REVOKE_SESSION });
    });

    /** Retrieve SSO auth data from url */
    const accessToken = searchParams.get("access_token");
    const refreshToken = searchParams.get("refresh_token");
    const tokenType = searchParams.get("token_type");
    const expiresIn = searchParams.get("expires_in");
    const scope = "";

    if (accessToken && expiresIn && refreshToken && tokenType) {
      const authObject: AuthObject = {
        accessTokken: accessToken,
        expiresIn: expiresIn,
        refreshToken: refreshToken,
        scope: scope,
        tokenType: tokenType,
      };

      updateAuthObject(authObject);
    }

    /**
     * Check if an accessToken is saved in the local storage:
     * - If it is not, set isAuthenticated to false and redirect to loginPage
     * - If it is, show a loading page until the myself API call has been done.
     *   This is necessary to check if the data in the local storage are already valid or not
     */
    if (!localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN)) {
      dispatch({ type: appActionConstants.REVOKE_SESSION });
    } else {
      loadMyself(dispatch);
    }

    // const hostname = window.location.hostname;
    // const hostnameList = hostname.split(".");
    // let curTenant = hostname.split(".")[0];

    // if (state.mySelf?.tenant && state.mySelf.tenant.subdomain) {
    //   curTenant = state.mySelf.tenant.subdomain;
    //   if (firstAccess) {
    //     if (hostnameList.length === 1) {
    //       console.log("====>>> ONE!");
    //       hostnameList.splice(0, 0, curTenant);
    //       const newHostname = hostnameList.join(".");
    //       console.log(`${hostnameList.join(".")}`);
    //       window.location.replace(url.replace(hostname, newHostname));
    //     }
    //     setFirstAccess(false);
    //   }
    // }

    // Remove events listener on component unmount
    return () => {
      window.removeEventListener(CUSTOM_EVENT.LOGOUT, () => {
        clearAuthObject();
        dispatch({ type: appActionConstants.REVOKE_SESSION });
      });
    };
  }, []);

  useEffect(() => {
    const url = window.location.href;
    const hostname = window.location.hostname;
    const hostnameList = hostname.split(".");
    let curTenant = hostname.split(".")[0];

    // console.log(hostnameList);
    // console.log(curTenant);
    // console.log(state.mySelf?.tenant);

    const root = document.getElementsByTagName("html")[0];
    if (root.classList) root.removeAttribute("class");

    if (state.mySelf?.tenant) {
      curTenant = state.mySelf.tenant.subdomain;
      //   if (firstAccess) {
      //     if (hostnameList.length === 1) {
      //       console.log("====>>> ONE!");
      //       hostnameList.splice(0, 0, curTenant);
      //       const newHostname = hostnameList.join(".");
      //       console.log(`${hostnameList.join(".")}`);
      //       window.location.href = url.replace(hostname, newHostname);
      //     }
      //     setFirstAccess(false);
      //   }
    }

    setTenantVariables(curTenant, dispatch);
  }, [state.mySelf?.tenant]);

  /** While the state is undefined a loader will be showed */
  if (state.isAuthenticated === undefined) {
    return (
      <Dimmer page active>
        <Loader size="huge">
          <Header as="h2" inverted style={{ fontFamily: "Inter" }}>
            Avvio applicativo in corso
            <Header.Subheader>Si prega di attendere.</Header.Subheader>
          </Header>
        </Loader>
      </Dimmer>
    );
  }

  return (
    <>
      <AppContext.Provider value={{ state, dispatch }}>
        <ConfirmProvider>
          <ScrollToTop />
          <Routes>
            {/* Routes with Topbar and Standard layout*/}
            <Route element={<TopbarLayout />}>
              <Route element={<StandardLayout />}>
                <Route
                  index
                  element={
                    <CheckAuth>
                      <HomePage />
                    </CheckAuth>
                  }
                />
                <Route
                  path={FE_ROUTES.MAIN_ROUTE}
                  element={
                    <CheckAuth>
                      <HomePage />
                    </CheckAuth>
                  }
                />
                {/*<Route
              path={FE_ROUTES.CUSTOMER}
              element={
                <CheckAuth>
                  <Customer />
                </CheckAuth>
              }
            />
            <Route
              path={FE_ROUTES.CUSTOMER + "/:action"}
              element={
                <CheckAuth>
                  <CustomerProfile />
                </CheckAuth>
              }
            />
            <Route
              path={FE_ROUTES.CUSTOMER + "/:action/:id"}
              element={
                <CheckAuth>
                  <CustomerProfile />
                </CheckAuth>
              }
            />*/}
                <Route
                  path={FE_ROUTES.UTENTI}
                  element={
                    <CheckAuth>
                      <CheckRole
                        allowedRoles={[
                          ROLES.SUPERVISOR,
                          ROLES.ADMIN,
                          ROLES.BANK_ADMIN,
                        ]}
                      >
                        <User />
                      </CheckRole>
                    </CheckAuth>
                  }
                />
                <Route
                  path={FE_ROUTES.UTENTI + "/:action"}
                  element={
                    <CheckAuth>
                      <CheckRole
                        allowedRoles={[
                          ROLES.SUPERVISOR,
                          ROLES.ADMIN,
                          ROLES.BANK_ADMIN,
                        ]}
                      >
                        <UserProfile />
                      </CheckRole>
                    </CheckAuth>
                  }
                />
                <Route
                  path={FE_ROUTES.UTENTI + "/:action/:id"}
                  element={
                    <CheckAuth>
                      <CheckRole
                        allowedRoles={[
                          ROLES.SUPERVISOR,
                          ROLES.ADMIN,
                          ROLES.BANK_ADMIN,
                        ]}
                      >
                        <UserProfile />
                      </CheckRole>
                    </CheckAuth>
                  }
                />
                <Route
                  path={FE_ROUTES.GESTIONE_BANDI}
                  element={
                    <CheckAuth>
                      <CheckRole
                        allowedRoles={[
                          ROLES.SUPERVISOR,
                          ROLES.ADMIN,
                          ROLES.EDITOR,
                        ]}
                      >
                        <AnnouncementManagement />
                      </CheckRole>
                    </CheckAuth>
                  }
                />
                <Route
                  path={FE_ROUTES.GESTIONE_BANDI + "/:action"}
                  element={
                    <CheckAuth>
                      <CheckRole
                        allowedRoles={[
                          ROLES.SUPERVISOR,
                          ROLES.ADMIN,
                          ROLES.EDITOR,
                        ]}
                      >
                        <AnnouncementEdit />
                      </CheckRole>
                    </CheckAuth>
                  }
                />
                <Route
                  path={FE_ROUTES.GESTIONE_BANDI + "/:action/:id"}
                  element={
                    <CheckAuth>
                      <CheckRole
                        allowedRoles={[
                          ROLES.SUPERVISOR,
                          ROLES.ADMIN,
                          ROLES.EDITOR,
                        ]}
                      >
                        <AnnouncementEdit />
                      </CheckRole>
                    </CheckAuth>
                  }
                />
                <Route
                  path={FE_ROUTES.ARCHIVIO_BANDI}
                  element={
                    <CheckAuth>
                      <CheckRole
                        allowedRoles={[
                          ROLES.SUPERVISOR,
                          ROLES.ADMIN,
                          ROLES.EDITOR,
                        ]}
                      >
                        <AnnouncementArchive />
                      </CheckRole>
                    </CheckAuth>
                  }
                />
                <Route
                  path={FE_ROUTES.ARCHIVIO_BANDI + "/:action/:id"}
                  element={
                    <CheckAuth>
                      <CheckRole
                        allowedRoles={[
                          ROLES.SUPERVISOR,
                          ROLES.ADMIN,
                          ROLES.EDITOR,
                        ]}
                      >
                        <AnnouncementEdit />
                      </CheckRole>
                    </CheckAuth>
                  }
                />
                <Route
                  path={FE_ROUTES.GESTIONE_FILTRI + "/:action/:id"}
                  element={
                    <CheckAuth>
                      <CheckRole
                        allowedRoles={[
                          ROLES.SUPERVISOR,
                          ROLES.ADMIN,
                          ROLES.EDITOR,
                        ]}
                      >
                        <FilterEdit />
                      </CheckRole>
                    </CheckAuth>
                  }
                />
                <Route
                  path={FE_ROUTES.GESTIONE_FILTRI}
                  element={
                    <CheckAuth>
                      <CheckRole
                        allowedRoles={[
                          ROLES.SUPERVISOR,
                          ROLES.ADMIN,
                          ROLES.EDITOR,
                        ]}
                      >
                        <FilterManagement />
                      </CheckRole>
                    </CheckAuth>
                  }
                />
              </Route>
              {/* Routes with ElencoBandi layout*/}
              <Route element={<ElencoBandiLayout />}>
                <Route
                  path={FE_ROUTES.ELENCO_BANDI}
                  element={
                    <CheckAuth>
                      <ElencoBandiGridLayout />
                    </CheckAuth>
                  }
                />
                <Route
                  path={FE_ROUTES.ELENCO_BANDI + "/:action/:id"}
                  element={
                    <CheckAuth>
                      <AnnouncementDetail />
                    </CheckAuth>
                  }
                />
                <Route
                  path={FE_ROUTES.CONTACT}
                  element={
                    <CheckAuth>
                      <Contact />
                    </CheckAuth>
                  }
                />
              </Route>
            </Route>
            <Route element={<AccessLayout />}>
              <Route
                path={FE_ROUTES.LOGIN}
                element={
                  <CheckAuth onlyNotAuth>
                    <Login />
                  </CheckAuth>
                }
              />
              <Route
                path={FE_ROUTES.REGISTER}
                element={
                  <CheckAuth onlyNotAuth>
                    <Register />
                  </CheckAuth>
                }
              />
              <Route
                path={FE_ROUTES.PASSWORD_RECOVERY}
                element={
                  <CheckAuth onlyNotAuth>
                    <PasswordRecovery />
                  </CheckAuth>
                }
              />
              <Route
                path={FE_ROUTES.PASSWORD_RESET}
                element={
                  <CheckAuth onlyNotAuth>
                    <PasswordReset />
                  </CheckAuth>
                }
              />
              <Route
                path={FE_ROUTES.FIRST_LOGIN}
                element={
                  <CheckAuth onlyNotAuth>
                    <FirstLogin />
                  </CheckAuth>
                }
              />
              <Route
                path={FE_ROUTES.HYBRIDAUTH}
                element={
                  <CheckAuth onlyNotAuth>
                    <HybridAuth />
                  </CheckAuth>
                }
              />
            </Route>
            <Route path="*" element={<Navigate to={FE_ROUTES.MAIN_ROUTE} />} />
          </Routes>
          <ToastContainer />
        </ConfirmProvider>
      </AppContext.Provider>
    </>
  );
};

export default App;
