import AnnouncementContainer from "components/AnnouncementContainer";
import { ElencoBandiContext } from "layout/ElencoBandiLayout";
import React, { useEffect } from "react";
import { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import {
  Dimmer,
  Header,
  Loader,
  Image,
  Dropdown,
  Icon,
} from "semantic-ui-react";
import { Form, Select } from "formik-semantic-ui-react";
import { Formik } from "formik";
import { exportReport, getFilteredAnnouncement } from "services/announcement";
import announcementSearch from "assets/images/announcementSearch.svg";
import { AnnouncementFormTypes } from "./types";
import { AnnouncementActionConstants } from "./reducer/actions";
import {
  exportValueOptions,
  orderByOptions,
} from "utils/function/announcement";
import { Pagination } from "semantic-ui-react";

const Announcement: React.FC = () => {
  const { state, dispatch } = useContext(ElencoBandiContext);
  const [formValues, setFormValues] = useState<AnnouncementFormTypes>({
    orderByValue: "earliest",
  });
  const [firstRender, setFirstRender] = useState<boolean>(true);

  const changeValues = (data) => {
    const value: string = data.value ?? "";
    setFormValues((preValues) => {
      return { ...preValues, orderByValue: value };
    });
  };

  useEffect(() => {
    getFilteredAnnouncement(
      dispatch,
      state.filters,
      state.orderByValue,
      state.paginationData
    );
    if (firstRender) setFirstRender(false);
  }, []);

  useEffect(() => {
    if (!firstRender) {
      dispatch({
        type: AnnouncementActionConstants.SET_ANNOUNCEMENT_ORDERBY,
        payload: { orderBy: formValues.orderByValue },
      });
      getFilteredAnnouncement(
        dispatch,
        state.filters,
        formValues.orderByValue,
        state.paginationData
      );
    }
  }, [formValues]);

  useEffect(() => {
    if (!firstRender) {
      getFilteredAnnouncement(
        dispatch,
        state.filters,
        formValues.orderByValue,
        state.paginationData
      );
    }
  }, [state.paginationData.page]);

  const onSubmit = () => {
    console.log("submit");
  };

  return (
    <>
      <div>
        <Helmet>
          <body className="elencoBandi elencoBandi-list" />
          <title>CliccaBandi.it - Elenco bandi</title>
          <meta name="description" content="elencoBandi list page" />
        </Helmet>

        <div className="elencoBandi__content">
          <Header className="elencoBandiHeader" dividing>
            <div className="totalItems">
              <h4>
                <FormattedMessage
                  id="announcements.totalItems"
                  defaultMessage="Per questa ricerca sono stati trovati:&nbsp;"
                />
                <span className="results">
                  {" "}
                  {state.paginationData.total_items} risultati
                </span>
              </h4>
            </div>
            <div>
              <Dropdown
                text="Esporta risultati"
                icon="cloud download"
                floating
                labeled
                button
                className="icon"
                loading={state.generatingExport}
              >
                <Dropdown.Menu>
                  <Dropdown.Menu scrolling>
                    {exportValueOptions.map((option) => (
                      <Dropdown.Item
                        key={option.key}
                        value={option.value}
                        text={option.text}
                        onClick={(event, data) => {
                          exportReport(state.filters, data.value, dispatch);
                        }}
                      />
                    ))}
                  </Dropdown.Menu>
                </Dropdown.Menu>
              </Dropdown>
              <Icon name="chevron down" />
            </div>
            <div>
              <Formik initialValues={formValues} onSubmit={onSubmit}>
                {() => (
                  <Form>
                    <Select
                      name="orderByValue"
                      options={orderByOptions}
                      placeholder="Ordina per..."
                      multiple={false}
                      clearable
                      onChange={(event, data) => changeValues(data)}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </Header>

          {state.isLoading ? (
            <Dimmer page active>
              <Loader size="huge">
                <Header as="h2" inverted>
                  Caricamento dei bandi in corso
                </Header>
              </Loader>
            </Dimmer>
          ) : (
            <div
              className={
                state.announcementList.length === 0
                  ? "elencoBandiSectionBodyNoResults"
                  : "elencoBandiSectionBody"
              }
            >
              {state.announcementList.length > 0 ? (
                <>
                  {state.announcementList.map((announcementValue, key) => (
                    <AnnouncementContainer
                      announcementValue={announcementValue}
                      key={key}
                    />
                  ))}
                  <Pagination
                    activePage={state.paginationData.page}
                    onPageChange={(event, data) => {
                      dispatch({
                        type: AnnouncementActionConstants.SET_ANNOUNCEMENT_PAGE_NUMBER,
                        payload: {
                          pageNumber: data.activePage || 1,
                        },
                      });
                    }}
                    totalPages={state.paginationData.page_count}
                  />
                </>
              ) : (
                <div>
                  <Image src={announcementSearch} />
                  <FormattedMessage
                    id="announcements.noResults"
                    defaultMessage="Non sono presenti risultati per questo criterio di ricerca"
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Announcement;
