import React, { useContext, useEffect, useReducer, createContext } from "react";
import { Button, Grid, Icon, Header, Container } from "semantic-ui-react";
import { FilterAnnouncementFormValues } from "./types";
import { Form, Formik } from "formik";
import { Field, Input, Select } from "formik-semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { getFilteredAnnouncement } from "services/announcement";
import { getFilterOptions } from "services/filters";
import { ElencoBandiContext } from "layout/ElencoBandiLayout";
import {
  filterAnnouncementReducer,
  initialFilterAnnouncementState,
  FilterAnnouncementState,
} from "./reducer/reducer";
import { FilterAnnouncementAction } from "./reducer/actions";
import { AnnouncementActionConstants } from "pages/Announcement/reducer/actions";
import _ from "lodash";
import { API } from "utils/global/backendRoutes";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { initialAnnouncementState } from "pages/Announcement/reducer/reducer";
import { CurrencyInputFormik } from "components/CurrencyInputFormik";
import { Tooltip } from "components/Tooltip";
import { filterTranslation } from "utils/function/computeFilters";
import { getFilterTooltips } from "services/tooltips";

export const FilterAnnouncementContext = createContext<{
  state: FilterAnnouncementState;
  dispatch: React.Dispatch<FilterAnnouncementAction>;
}>({
  state: initialFilterAnnouncementState,
  dispatch: () => null,
});

const FilterAnnouncementForm: () => JSX.Element = () => {
  const { state: AnnouncementState, dispatch: announcementDispatch } =
    useContext(ElencoBandiContext);

  const [state, dispatch] = useReducer(
    filterAnnouncementReducer,
    initialFilterAnnouncementState
  );

  const initialFormValues = {
    status: AnnouncementState.filters?.status || [],
    customer: AnnouncementState.filters?.customer || null,
    geographicalReference:
      AnnouncementState.filters?.geographicalReference || [],
    economicActivity: AnnouncementState.filters?.economicActivity || [],
    companySize: AnnouncementState.filters?.companySize || [],
    topic: AnnouncementState.filters?.topic || [],
    fundingType: AnnouncementState.filters?.fundingType || [],
    applicantCompanyForm: AnnouncementState.filters?.applicantCompanyForm || [],
    eligibleCost: AnnouncementState.filters?.eligibleCost || [],
    promotingInstitution: AnnouncementState.filters?.promotingInstitution || [],
    minFinancedContribute:
      AnnouncementState.filters?.minFinancedContribute || "",
    maxFinancedContribute:
      AnnouncementState.filters?.maxFinancedContribute || "",
    keyword: AnnouncementState.filters?.keyword || "",
  };

  const endpointList = [
    API.GEOGRAPHICAL_REFERENCE,
    API.ECONOMIC_ACTIVITY,
    API.COMPANY_SIZE,
    API.TOPIC,
    API.FUNDING_TYPE,
    API.APPLICANT_COMPANY_FORM,
    API.ELIGIBLE_COST,
    API.PROMOTING_INSTITUTION,
  ];

  useEffect(() => {
    for (const endpoint of endpointList) {
      getFilterOptions(endpoint, dispatch);
    }
    getFilterTooltips(undefined, undefined, dispatch);
  }, []);

  const FormikListener = ({ values, callback, setFieldValue }) => {
    useEffect(() => {
      callback(values, setFieldValue);
      // eslint-disable-next-line react/prop-types
    }, [values?.customer]);
    return null;
  };

  FormikListener.propTypes = {
    values: PropTypes.object,
    callback: PropTypes.func,
    setFieldValue: PropTypes.func,
  };

  const handleOnChange = (values, setFieldValue) => {
    if (state.customerList.length > 0 && !_.isEmpty(values.customer)) {
      const selectedCustomer = _.find(state.customerList, {
        id: values.customer.value,
      });
      setFieldValue(
        "economicActivity",
        _.find(state.economicActivityOptions, {
          value: selectedCustomer?.economicActivity,
        })
          ? _.find(state.economicActivityOptions, {
              value: selectedCustomer?.economicActivity,
            })
          : []
      );
      setFieldValue(
        "companySize",
        _.find(state.companySizeOptions, {
          value: selectedCustomer?.companySize,
        })
          ? _.find(state.companySizeOptions, {
              value: selectedCustomer?.companySize,
            })
          : []
      );
      setFieldValue(
        "geographicalReference",
        _.find(state.geographicReferenceOptions, {
          value: selectedCustomer?.geographicReference,
        })
          ? _.find(state.geographicReferenceOptions, {
              value: selectedCustomer?.geographicReference,
            })
          : []
      );
    }
  };

  const updateState = (value, name) => {
    if (name === "minFinancedContribute") {
      announcementDispatch({
        type: AnnouncementActionConstants.SET_ANNOUNCEMENT_FILTER_MINFINANCEDCONTRIBUTE,
        payload: { minFinancedContribute: value },
      });
    }
    if (name === "maxFinancedContribute") {
      announcementDispatch({
        type: AnnouncementActionConstants.SET_ANNOUNCEMENT_FILTER_MAXFINANCEDCONTRIBUTE,
        payload: { maxFinancedContribute: value },
      });
    }
  };

  /*
    const onChangeCustomer = (setFieldValue, data, values) => {
      setFieldValue("customer", data);
      if (_.isNull(data) && !_.isEmpty(values.customer)) {
        if (values.geographicalReference) {
          setFieldValue("geographicalReference", []);
        }
        if (values.companySize) {
          setFieldValue("companySize", []);
        }
        if (values.economicActivity) {
          setFieldValue("economicActivity", []);
        }
      }
    };
  */

  /**
   * @param values
   */
  const onSubmit = (values: FilterAnnouncementFormValues): void => {
    if (values) {
      const payload = {
        status: values.status,
        geographicalReference: values.geographicalReference,
        economicActivity: values.economicActivity,
        companySize: values.companySize,
        topic: values.topic,
        fundingType: values.fundingType,
        applicantCompanyForm: values.applicantCompanyForm,
        eligibleCost: values.eligibleCost,
        promotingInstitution: values.promotingInstitution,
        minFinancedContribute: values.minFinancedContribute,
        maxFinancedContribute: values.maxFinancedContribute,
        keyword: values.keyword,
      };
      getFilteredAnnouncement(
        announcementDispatch,
        payload,
        AnnouncementState.orderByValue,
        initialAnnouncementState.paginationData
      );
    }
    window.scrollTo({
      top: 0,
      left: 0,
    });
  };

  /**
   * @param values
   * manually cleaning form values in order to guarantee the reset of
   * the form, even when context initial values change
   */
  const handleReset = (values: FilterAnnouncementFormValues, resetForm) => {
    announcementDispatch({
      type: AnnouncementActionConstants.RESET_FILTERS,
    });
    const emptyValues = _.clone(values);
    emptyValues.status = [];
    emptyValues.customer = null;
    emptyValues.geographicalReference = [];
    emptyValues.economicActivity = [];
    emptyValues.companySize = [];
    emptyValues.topic = [];
    emptyValues.fundingType = [];
    emptyValues.applicantCompanyForm = [];
    emptyValues.eligibleCost = [];
    emptyValues.promotingInstitution = [];
    emptyValues.minFinancedContribute = "";
    emptyValues.maxFinancedContribute = "";
    emptyValues.keyword = "";
    resetForm({ values: emptyValues });
    onSubmit(emptyValues);
  };

  const generateHTML = (value: string | null | undefined) => {
    if (typeof value === "string") {
      return { __html: value };
    }
  };

  return (
    <Formik
      initialValues={initialFormValues}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        minFinancedContribute: Yup.number()
          .typeError("Inserire un numero")
          .notRequired()
          .nullable()
          .when("maxFinancedContribute", {
            is: (max) => {
              if (max) {
                return true;
              }
              return false;
            },
            then: Yup.number()
              .nullable()
              .test(
                "lessThanMax",
                "Contributo minimo deve essere minore di contributo massimo",
                (value, context) => {
                  if (value)
                    return value <= context.parent.maxFinancedContribute;
                  return true;
                }
              ),
          }),
        maxFinancedContribute: Yup.number()
          .typeError("Inserire un numero")
          .notRequired()
          .nullable(),
      })}
    >
      {({ setFieldValue, values, handleBlur, resetForm }) => (
        <Form>
          <Grid padded>
            {/*
            <Grid.Row width={12}>
              <Field name="customer">
                {() => (
                  <>
                    <label>
                      <FormattedMessage
                        id="announcement.clientSearch"
                        defaultMessage="Ricerca cliente"
                      />
                    </label>
                    <AsyncSelect
                      isClearable={true}
                      value={values.customer}
                      loadOptions={(
                        customer: string,
                        callback: (options: SelectValueLabelObject[]) => void
                      ) => {
                        getCustomers(dispatch, customer, callback);
                      }}
                      onChange={(data) => {
                        onChangeCustomer(setFieldValue, data, values);
                      }}
                      placeholder="Digitare la ragione sociale"
                      noOptionsMessage={() => "Nessun risultato trovato"}
                      loadingMessage={() => "Caricamento..."}
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      }}
                    />
                  </>
                )}
              </Field>
            </Grid.Row>
          */}
            <Grid.Row className="filter__buttons">
              <Grid.Column>
                <Button
                  className="button--primary--negative"
                  content={
                    <FormattedMessage
                      id="customerfilter.apply"
                      defaultMessage="Applica"
                    />
                  }
                />
                <Button
                  className="button--underline button--reset"
                  onClick={() => handleReset(values, resetForm)}
                >
                  <Icon name="redo" />
                  <div className="button--text">
                    <FormattedMessage
                      id="filterSidebar.reset"
                      defaultMessage="Reset"
                    />
                  </div>
                </Button>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row width={12}>
              <Input
                label={
                  <FormattedMessage
                    id="filterSideBar.keyword"
                    defaultMessage="Parole chiave"
                  />
                }
                name="keyword"
                placeholder="Inserisci una parola chiave"
                onChange={(event, data) => {
                  announcementDispatch({
                    type: AnnouncementActionConstants.SET_ANNOUNCEMENT_FILTER_KEYWORD,
                    payload: { keyword: data.value },
                  });
                }}
              />
            </Grid.Row>
            <Grid.Row>
              <Field name="status">
                {() => (
                  <>
                    <label>
                      <FormattedMessage
                        id="announcement.status"
                        defaultMessage="Stato"
                      />
                    </label>
                    <Select
                      options={state.statusOptions}
                      name={"status"}
                      clearable={true}
                      search={true}
                      multiple={true}
                      onChange={(event, data) => {
                        const dataValues: Array<string> = [];
                        if (_.isArray(data.value)) {
                          _.forEach(data.value, (v) => {
                            if (_.isString(v)) dataValues.push(v);
                          });
                        }
                        announcementDispatch({
                          type: AnnouncementActionConstants.SET_ANNOUNCEMENT_FILTER_STATUS,
                          payload: { status: dataValues },
                        });
                      }}
                    />
                  </>
                )}
              </Field>
            </Grid.Row>
            <Grid.Row className="covered-amount">
              <Field name="coveredAmount">
                {() => (
                  <>
                    <label>
                      <FormattedMessage
                        id="announcement.coveredAmount"
                        defaultMessage="Contributo finanziato"
                      />
                    </label>
                    <div className="covered-amount-inputs">
                      <Field name="minFinancedContribute">
                        {({ field }) => (
                          <CurrencyInputFormik
                            field={field}
                            label={
                              <FormattedMessage
                                id="announcement.minFinancedContribute"
                                defaultMessage="Minimo"
                              />
                            }
                            setFieldValue={setFieldValue}
                            onBlur={handleBlur}
                            className={
                              field.value || field.value === 0
                                ? "filled"
                                : undefined
                            }
                            updateState={updateState}
                          />
                        )}
                      </Field>
                      <Field name="maxFinancedContribute">
                        {({ field }) => (
                          <CurrencyInputFormik
                            field={field}
                            label={
                              <FormattedMessage
                                id="announcement.maxFinancedContribute"
                                defaultMessage="Massimo"
                              />
                            }
                            setFieldValue={setFieldValue}
                            onBlur={handleBlur}
                            className={
                              field.value || field.value === 0
                                ? "filled"
                                : undefined
                            }
                            updateState={updateState}
                          />
                        )}
                      </Field>
                    </div>
                  </>
                )}
              </Field>
            </Grid.Row>
            <Grid.Row>
              <Field name="economicActivity">
                {({ field }) => (
                  <>
                    <label>
                      <FormattedMessage
                        id="announcement.economicActivity"
                        defaultMessage="Attività economiche (Settore ATECO)"
                      />
                      {state.tooltips[field.name] && (
                        <Tooltip
                          header={
                            <Header as="h3">
                              {filterTranslation[field.name]}
                            </Header>
                          }
                          content={
                            <Container
                              dangerouslySetInnerHTML={generateHTML(
                                state.tooltips[field.name]
                              )}
                            ></Container>
                          }
                        />
                      )}
                    </label>
                    <Select
                      name={"economicActivity"}
                      options={state.economicActivityOptions}
                      search={true}
                      multiple={true}
                      loading={state.isLoadingEconomicActivity}
                      disabled={state.isLoadingEconomicActivity}
                      onChange={(event, data) => {
                        const dataValues: Array<string> = [];
                        if (_.isArray(data.value)) {
                          _.forEach(data.value, (v) => {
                            if (_.isString(v)) dataValues.push(v);
                          });
                        }
                        announcementDispatch({
                          type: AnnouncementActionConstants.SET_ANNOUNCEMENT_FILTER_ECONOMIC_ACTIVITY,
                          payload: { economicActivity: dataValues },
                        });
                      }}
                    />
                  </>
                )}
              </Field>
            </Grid.Row>
            <Grid.Row>
              <Field name="companySize">
                {({ field }) => (
                  <>
                    <label>
                      <FormattedMessage
                        id="announcement.companySize"
                        defaultMessage="Dimensione d'impresa"
                      />
                      {state.tooltips[field.name] && (
                        <Tooltip
                          header={
                            <Header as="h3">
                              {filterTranslation[field.name]}
                            </Header>
                          }
                          content={
                            <Container
                              dangerouslySetInnerHTML={generateHTML(
                                state.tooltips[field.name]
                              )}
                            ></Container>
                          }
                        />
                      )}
                    </label>
                    <Select
                      name={"companySize"}
                      options={state.companySizeOptions}
                      search={true}
                      multiple={true}
                      clearable={true}
                      loading={state.isLoadingCompanySize}
                      disabled={state.isLoadingCompanySize}
                      onChange={(event, data) => {
                        const dataValues: Array<string> = [];
                        if (_.isArray(data.value)) {
                          _.forEach(data.value, (v) => {
                            if (_.isString(v)) dataValues.push(v);
                          });
                        }
                        announcementDispatch({
                          type: AnnouncementActionConstants.SET_ANNOUNCEMENT_FILTER_COMPANY_SIZE,
                          payload: { companySize: dataValues },
                        });
                      }}
                    />
                  </>
                )}
              </Field>
            </Grid.Row>
            <Grid.Row>
              <Field name="geographicalReference">
                {({ field }) => (
                  <>
                    <label>
                      <FormattedMessage
                        id="announcement.geographicReference"
                        defaultMessage="Riferimento geografico"
                      />
                      {state.tooltips[field.name] && (
                        <Tooltip
                          header={
                            <Header as="h3">
                              {filterTranslation[field.name]}
                            </Header>
                          }
                          content={
                            <Container
                              dangerouslySetInnerHTML={generateHTML(
                                state.tooltips[field.name]
                              )}
                            ></Container>
                          }
                        />
                      )}
                    </label>
                    <Select
                      name={"geographicalReference"}
                      options={state.geographicReferenceOptions}
                      search={true}
                      clearable={true}
                      multiple={true}
                      loading={state.isLoadingGeoReference}
                      disabled={state.isLoadingGeoReference}
                      onChange={(event, data) => {
                        const dataValues: Array<string> = [];
                        if (_.isArray(data.value)) {
                          _.forEach(data.value, (v) => {
                            if (_.isString(v)) dataValues.push(v);
                          });
                        }
                        announcementDispatch({
                          type: AnnouncementActionConstants.SET_ANNOUNCEMENT_FILTER_GEOGRAPHICAL_REFERENCE,
                          payload: { geographicalReference: dataValues },
                        });
                      }}
                    />
                  </>
                )}
              </Field>
            </Grid.Row>
            <Grid.Row>
              <Field name="topic">
                {({ field }) => (
                  <>
                    <label>
                      <FormattedMessage
                        id="announcement.topic"
                        defaultMessage="Tematiche"
                      />
                      {state.tooltips[field.name] && (
                        <Tooltip
                          header={
                            <Header as="h3">
                              {filterTranslation[field.name]}
                            </Header>
                          }
                          content={
                            <Container
                              dangerouslySetInnerHTML={generateHTML(
                                state.tooltips[field.name]
                              )}
                            ></Container>
                          }
                        />
                      )}
                    </label>
                    <Select
                      name={"topic"}
                      options={state.topicOptions}
                      search={true}
                      multiple={true}
                      clearable={true}
                      loading={state.isLoadingTopic}
                      disabled={state.isLoadingTopic}
                      onChange={(event, data) => {
                        const dataValues: Array<string> = [];
                        if (_.isArray(data.value)) {
                          _.forEach(data.value, (v) => {
                            if (_.isString(v)) dataValues.push(v);
                          });
                        }
                        announcementDispatch({
                          type: AnnouncementActionConstants.SET_ANNOUNCEMENT_FILTER_TOPIC,
                          payload: { topic: dataValues },
                        });
                      }}
                    />
                  </>
                )}
              </Field>
            </Grid.Row>
            <Grid.Row>
              <Field name="fundingType">
                {({ field }) => (
                  <>
                    <label>
                      <FormattedMessage
                        id="announcement.fundingType"
                        defaultMessage="Tipo di agevolazione"
                      />
                      {state.tooltips[field.name] && (
                        <Tooltip
                          header={
                            <Header as="h3">
                              {filterTranslation[field.name]}
                            </Header>
                          }
                          content={
                            <Container
                              dangerouslySetInnerHTML={generateHTML(
                                state.tooltips[field.name]
                              )}
                            ></Container>
                          }
                        />
                      )}
                    </label>
                    <Select
                      name={"fundingType"}
                      options={state.fundingTypeOptions}
                      search={true}
                      multiple={true}
                      clearable={true}
                      loading={state.isLoadingFundingType}
                      disabled={state.isLoadingFundingType}
                      onChange={(event, data) => {
                        const dataValues: Array<string> = [];
                        if (_.isArray(data.value)) {
                          _.forEach(data.value, (v) => {
                            if (_.isString(v)) dataValues.push(v);
                          });
                        }
                        announcementDispatch({
                          type: AnnouncementActionConstants.SET_ANNOUNCEMENT_FILTER_FUNDING_TYPE,
                          payload: { fundingType: dataValues },
                        });
                      }}
                    />
                  </>
                )}
              </Field>
            </Grid.Row>
            <Grid.Row>
              <Field name="applicantCompanyForm">
                {({ field }) => (
                  <>
                    <label>
                      <FormattedMessage
                        id="announcement.applicantCompanyForm"
                        defaultMessage="Tipo di soggetto richiedente"
                      />
                      {state.tooltips[field.name] && (
                        <Tooltip
                          header={
                            <Header as="h3">
                              {filterTranslation[field.name]}
                            </Header>
                          }
                          content={
                            <Container
                              dangerouslySetInnerHTML={generateHTML(
                                state.tooltips[field.name]
                              )}
                            ></Container>
                          }
                        />
                      )}
                    </label>
                    <Select
                      name={"applicantCompanyForm"}
                      options={state.applicantCompanyFormOptions}
                      search={true}
                      multiple={true}
                      clearable={true}
                      loading={state.isLoadingApplicantCompanyForm}
                      disabled={state.isLoadingApplicantCompanyForm}
                      onChange={(event, data) => {
                        const dataValues: Array<string> = [];
                        if (_.isArray(data.value)) {
                          _.forEach(data.value, (v) => {
                            if (_.isString(v)) dataValues.push(v);
                          });
                        }
                        announcementDispatch({
                          type: AnnouncementActionConstants.SET_ANNOUNCEMENT_FILTER_APPLICANT_COMPANY_FORM,
                          payload: { applicantCompanyForm: dataValues },
                        });
                      }}
                    />
                  </>
                )}
              </Field>
            </Grid.Row>
            <Grid.Row>
              <Field name="eligibleCost">
                {({ field }) => (
                  <>
                    <label>
                      <FormattedMessage
                        id="announcement.eligibleCost"
                        defaultMessage="Costi ammissibili"
                      />
                      {state.tooltips[field.name] && (
                        <Tooltip
                          header={
                            <Header as="h3">
                              {filterTranslation[field.name]}
                            </Header>
                          }
                          content={
                            <Container
                              dangerouslySetInnerHTML={generateHTML(
                                state.tooltips[field.name]
                              )}
                            ></Container>
                          }
                        />
                      )}
                    </label>
                    <Select
                      name={"eligibleCost"}
                      options={state.eligibleCostOptions}
                      search={true}
                      multiple={true}
                      clearable={true}
                      loading={state.isLoadingEligibleCost}
                      disabled={state.isLoadingEligibleCost}
                      onChange={(event, data) => {
                        const dataValues: Array<string> = [];
                        if (_.isArray(data.value)) {
                          _.forEach(data.value, (v) => {
                            if (_.isString(v)) dataValues.push(v);
                          });
                        }
                        announcementDispatch({
                          type: AnnouncementActionConstants.SET_ANNOUNCEMENT_FILTER_ELIGIBLE_COST,
                          payload: { eligibleCost: dataValues },
                        });
                      }}
                    />
                  </>
                )}
              </Field>
            </Grid.Row>
            <Grid.Row>
              <Field name="promotingInstitution">
                {({ field }) => (
                  <>
                    <label>
                      <FormattedMessage
                        id="announcement.promotingInstitution"
                        defaultMessage="Ente promotore"
                      />
                      {state.tooltips[field.name] && (
                        <Tooltip
                          header={
                            <Header as="h3">
                              {filterTranslation[field.name]}
                            </Header>
                          }
                          content={
                            <Container
                              dangerouslySetInnerHTML={generateHTML(
                                state.tooltips[field.name]
                              )}
                            ></Container>
                          }
                        />
                      )}
                    </label>
                    <Select
                      name={"promotingInstitution"}
                      options={state.promotingInstitutionOptions}
                      search={true}
                      multiple={true}
                      clearable={true}
                      loading={state.isLoadingPromotingInstitution}
                      disabled={state.isLoadingPromotingInstitution}
                      onChange={(event, data) => {
                        const dataValues: Array<string> = [];
                        if (_.isArray(data.value)) {
                          _.forEach(data.value, (v) => {
                            if (_.isString(v)) dataValues.push(v);
                          });
                        }
                        announcementDispatch({
                          type: AnnouncementActionConstants.SET_ANNOUNCEMENT_FILTER_PROMOTING_INSTITUTION,
                          payload: { promotingInstitution: dataValues },
                        });
                      }}
                    />
                  </>
                )}
              </Field>
            </Grid.Row>
          </Grid>
          <FormikListener
            values={values}
            callback={handleOnChange}
            setFieldValue={setFieldValue}
          />
        </Form>
      )}
    </Formik>
  );
};

export default FilterAnnouncementForm;
