import { toast } from "react-toastify";

// Client
import standardClient from "services/client/standardRequestClient";

// Constants
import { API } from "utils/global/backendRoutes";

// Types
import { ContactType } from "components/Forms/ContactForm/types";
import { FormikHelpers } from "formik";

export const sendMessage = (
  values: ContactType,
  formikelpers: FormikHelpers<ContactType>
) => {
  standardClient({
    url: API.CONTACT,
    method: "POST",
    data: values,
  })
    .then(() => {
      toast.success("Messaggio inviato con successo");
    })
    .catch(() => {
      toast.error("Errore durante l'invio del messaggio");
    })
    .finally(() => formikelpers.setSubmitting(false));
};
