import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Grid, Header, Segment } from "semantic-ui-react";

// Images

// Form
import PasswordResetForm from "../../components/Forms/PasswordResetForm";

// Types
import { PasswordResetState } from "./types";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

const PasswordReset: React.FC = () => {
  /**
   * That state keeps the information about the current registration process.
   * Basically it's empty until the registration process will be succesful.
   */
  const [response, setResponse] = useState<PasswordResetState>({
    sent: false,
  });

  return (
    <div className="password-framework">
      <Helmet>
        <title>CliccaBandi.it - login</title>
        <meta name="description" content="password recovery page" />
      </Helmet>
      {!response.sent && (
        <div className="password-framework__container">
          <div className="password-reset-form-container">
            {<PasswordResetForm setResponse={setResponse} />}
          </div>
        </div>
      )}
      {response.sent && (
        <Grid className="password-framework__container">
          <Grid.Row textAlign="center">
            <Grid.Column>
              <Header>
                <FormattedMessage
                  id="resetPassword.thank"
                  defaultMessage="Richiesta inviata"
                />
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row textAlign="center">
            <Grid.Column>
              <Segment>
                <FormattedMessage
                  id="resetPassword.succesful"
                  defaultMessage="La password è stata resettata correttamente."
                />
                <Segment.Inline>
                  <Button
                    type="button"
                    className="button--to-dashboard"
                    content={
                      <div>
                        <Link to="/">
                          <FormattedMessage
                            id="toDashboard"
                            defaultMessage="Torna alla dashboard"
                          />
                        </Link>
                      </div>
                    }
                  />
                </Segment.Inline>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </div>
  );
};

export default PasswordReset;
