import { Announcement } from "pages/Announcement/types";
import {
  AnnouncementManagementAction,
  AnnouncementManagementActionConstants,
} from "./actions";

export type AnnouncementManagementState = {
  isLoading: boolean;
  announcementList: Array<Announcement>;
};

export const initialAnnouncementManagementState: AnnouncementManagementState = {
  isLoading: false,
  announcementList: [],
};

export function announcementManagementReducer(
  state: AnnouncementManagementState,
  action: AnnouncementManagementAction
): AnnouncementManagementState {
  const { type } = action;

  switch (type) {
    case AnnouncementManagementActionConstants.SET_ANNOUNCEMENT_LIST:
      return { ...state, announcementList: action.payload.announcementList };
    case AnnouncementManagementActionConstants.SET_IS_LOADING:
      return { ...state, isLoading: action.payload.isLoading };
    default:
      return state;
  }
}
