import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { Image } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";

import { AppContext } from "pages/App";

// Form
import HybridAuthForm from "components/Forms/HybridAuthForm";
import { hybridAuthBsvLogin } from "services/auth/userLogin";
import { HybridAuthFormValues } from "components/Forms/HybridAuthForm/types";

const Login: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  const navigate = useNavigate();

  const onSubmit = (values: HybridAuthFormValues) => {
    hybridAuthBsvLogin(dispatch, values, navigate);
  };

  return (
    <div className="loginPage">
      <div className="access__container">
        <Helmet>
          <title>CliccaBandi - Hybrid login</title>
          <meta name="description" content="hybrid auth page" />
        </Helmet>
        <>
          <Image size="medium" src={state.logo} />
          <span className="access__container__title">Accedi</span>
          <HybridAuthForm onSubmit={onSubmit} />
        </>
      </div>
    </div>
  );
};

export default Login;
