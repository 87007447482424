import { RoleResponse, User } from "services/user/types";
import { SelectValueTextObject } from "utils/types";
import { UserAction, UserActionConstants } from "./actions";

export type UserState = {
  isLoading: boolean;
  isLoadingApplicationTenant: boolean;
  isResetPasswordLoading: boolean;
  userList: Array<User>;
  roles: Array<RoleResponse>;
  applicationTenantOptions: Array<SelectValueTextObject>;
};

export const initialUserState: UserState = {
  isLoading: false,
  isResetPasswordLoading: false,
  isLoadingApplicationTenant: false,
  userList: [],
  roles: [],
  applicationTenantOptions: [],
};

export function userReducer(state: UserState, action: UserAction): UserState {
  const { type } = action;

  switch (type) {
    case UserActionConstants.SET_SEARCH_FILTER:
      return { ...state, isLoading: action.payload.isLoading };
    case UserActionConstants.SET_USER_DICTIONARY:
      return { ...state, userList: action.payload.userList };
    case UserActionConstants.SET_ROLE_LIST:
      return { ...state, roles: action.payload.roles };
    case UserActionConstants.SET_RESET_PASSWORD_LOADING:
      return {
        ...state,
        isResetPasswordLoading: action.payload.isResetPasswordLoading,
      };
    case UserActionConstants.SET_APPLICATION_TENANT_LIST:
      return {
        ...state,
        applicationTenantOptions: action.payload.applicationTenantOptions,
      };
    case UserActionConstants.SET_IS_LOADING_APPLICATION_TENANT:
      return {
        ...state,
        isLoadingApplicationTenant: action.payload.isLoadingApplicationTenant,
      };
    default:
      return state;
  }
}
