import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Button, Icon } from "semantic-ui-react";
import { useFormikContext } from "formik";
import { AnnouncementValuesTypes } from "pages/Announcement/types";
import _ from "lodash";

export const UploadDropzone: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<AnnouncementValuesTypes>();
  const [errors, setErrors] = useState<Array<string>>([]);
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    noClick: true,
    onDrop: (acceptedFiles, rejectedFiles) => {
      setErrors([]);
      if (acceptedFiles.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const files: Array<any> = [];
        acceptedFiles.forEach((file) => {
          const newFile = new File([file], file.name, {
            type: file.type,
          });
          files.push(newFile);
        });
        setFieldValue("attachmentsToUpload", [
          ...values.attachmentsToUpload,
          ...acceptedFiles,
        ]);
      }
      if (rejectedFiles.length > 0) {
        rejectedFiles.forEach((file) => {
          if (
            !_.isEmpty(
              _.find(file.errors, (err) => {
                return err.code === "file-too-large";
              })
            )
          ) {
            setErrors((prevErrors) => {
              return [
                ...prevErrors,
                `Il file "${file.file.name}" è troppo grande`,
              ];
            });
          }
        });
      }
    },
    maxSize: 15728640,
  });

  return (
    <>
      <div {...getRootProps({ className: "dropzone-area" })}>
        <input {...getInputProps()} />
        <div className="left-side">
          <Icon name="upload" />
          {isDragActive ? (
            <p>Rilascia i documenti in questo spazio</p>
          ) : values.attachmentsToUpload.length > 0 ? (
            <p>File selezionati: {values.attachmentsToUpload.length}</p>
          ) : (
            <div className="upload--text">
              <p>Trascina i documenti da caricare</p>
              <p>Dimensione massima consentita: 15MB</p>
            </div>
          )}
        </div>
        <Button
          type="button"
          onClick={open}
          className="button--primary--negative"
        >
          Esplora
        </Button>
      </div>
      {errors.length > 0 &&
        _.map(errors, (err) => <div className="upload--error">{err}</div>)}
    </>
  );
};
