import { AppAction, appActionConstants } from "pages/App/reducer/actions";

// Images
import {
  LOGO_ASSILEA,
  LOGO_MEDIOBANCA,
  LOGO_CLICCABANDI,
  LOGO_VALSABBINA,
  STANDARD_WAVE_BG,
  VALSABBINA_WAVE_BG,
} from "utils/global/tenantConstants";

export const setTenantVariables = (
  tenant: string | undefined,
  dispatch: React.Dispatch<AppAction>
): void => {
  let logo = "";
  let waveBg = "";
  let subdomain = tenant;

  switch (tenant) {
    case "assilea":
      logo = LOGO_ASSILEA;
      waveBg = STANDARD_WAVE_BG;
      break;
    case "mediobanca":
      logo = LOGO_MEDIOBANCA;
      waveBg = STANDARD_WAVE_BG;
      break;
    case "bancavalsabbina":
      logo = LOGO_VALSABBINA;
      waveBg = VALSABBINA_WAVE_BG;
      break;
    default:
      logo = LOGO_CLICCABANDI;
      waveBg = STANDARD_WAVE_BG;
      subdomain = undefined;
  }

  if (subdomain) {
    const root = document.getElementsByTagName("html")[0];
    root.setAttribute("class", subdomain);
  }

  dispatch({
    type: appActionConstants.SET_LOGO,
    payload: {
      logo: logo,
    },
  });
  dispatch({
    type: appActionConstants.SET_WAVE_BG,
    payload: {
      waveBg: waveBg,
    },
  });
};
