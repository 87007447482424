import React, { useEffect, useState, useReducer, createContext } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import _ from "lodash";

//Constants
import { LOCAL_STORAGE } from "utils/global/globalCostants";

// Layout
import SideBar from "layout/SideBar";
import Footer from "layout/Footer";

// Pages
import { SidebarItem } from "pages/App/types";

// Utils
import { FE_ROUTES } from "utils/global/globalCostants";
import { Breadcrumbs } from "@mui/material";
import { isThatRole } from "utils/function/acl";
import { ROLES } from "utils/global/globalCostants";
import {
  dataGridReducer,
  DataGridState,
  initialDataGridState,
} from "components/DataGrid/reducer/reducer";
import { DataGridAction } from "components/DataGrid/reducer/actions";
import { composeBreadcrumbs } from "utils/function/composeBreadcrumbs";
import { Link } from "react-router-dom";
import {
  initialTableFilterFormState,
  TableFilterFormState,
  tableFilterFormReducer,
} from "components/Forms/TableFilterForm/reducer/reducer";
import { TableFilterFormAction } from "components/Forms/TableFilterForm/reducer/actions";

export const TableFilterContext = createContext<{
  tableFilterState: TableFilterFormState;
  tableFilterDispatch: React.Dispatch<TableFilterFormAction>;
  dataGridState: DataGridState;
  dataGridDispatch: React.Dispatch<DataGridAction>;
  route: string;
  setRoute: React.Dispatch<React.SetStateAction<string>>;
}>({
  tableFilterState: initialTableFilterFormState,
  tableFilterDispatch: () => null,
  dataGridState: initialDataGridState,
  dataGridDispatch: () => null,
  route: "",
  setRoute: () => null,
});

const StandardLayout: React.FC = () => {
  const location = useLocation();
  const { action, id } = useParams();
  const [dataGridState, dataGridDispatch] = useReducer(
    dataGridReducer,
    initialDataGridState
  );
  const [tableFilterState, tableFilterDispatch] = useReducer(
    tableFilterFormReducer,
    initialTableFilterFormState
  );
  const [breadcrumbs, setBreadcrumbs] = useState<string>("[]");
  const [route, setRoute] = useState<string>("");

  useEffect(() => {
    composeBreadcrumbs(id, action);
    const getBreadcrumbsIfNotNull = localStorage.getItem(
      LOCAL_STORAGE.BREADCRUMBS
    );
    if (!_.isNull(getBreadcrumbsIfNotNull))
      setBreadcrumbs(getBreadcrumbsIfNotNull);
  }, [location]);

  const sideBarItems: Array<SidebarItem> = [
    // {
    //   id: "Clienti",
    //   to: FE_ROUTES.CUSTOMER,
    //   title: (
    //     <div className="ui icon" data-toggle="tooltip" title="Elenco clienti">
    //       <Icon name="user" />
    //     </div>
    //   ),
    // },
    isThatRole(ROLES.ADMIN) ||
    isThatRole(ROLES.SUPERVISOR) ||
    isThatRole(ROLES.BANK_ADMIN)
      ? {
          id: "Users",
          to: FE_ROUTES.UTENTI,
          title: (
            <div
              className="ui icon"
              data-toggle="tooltip"
              title="Elenco utenti"
            >
              <Icon name="users" />
            </div>
          ),
        }
      : null,
    isThatRole(ROLES.ADMIN) ||
    isThatRole(ROLES.SUPERVISOR) ||
    isThatRole(ROLES.EDITOR)
      ? {
          id: "Gestione bandi",
          to: FE_ROUTES.GESTIONE_BANDI,
          title: (
            <div
              className="ui icon"
              data-toggle="tooltip"
              title="Gestione bandi"
            >
              <Icon name="folder open" />
            </div>
          ),
        }
      : null,
    isThatRole(ROLES.ADMIN) ||
    isThatRole(ROLES.SUPERVISOR) ||
    isThatRole(ROLES.EDITOR)
      ? {
          id: "Archivio bandi",
          to: FE_ROUTES.ARCHIVIO_BANDI,
          title: (
            <div
              className="ui icon"
              data-toggle="tooltip"
              title="Archivio bandi"
            >
              <Icon name="file archive" />
            </div>
          ),
        }
      : null,
    isThatRole(ROLES.ADMIN) ||
    isThatRole(ROLES.SUPERVISOR) ||
    isThatRole(ROLES.EDITOR)
      ? {
          id: "Gestione filtri",
          to: FE_ROUTES.GESTIONE_FILTRI,
          title: (
            <div
              className="ui icon"
              data-toggle="tooltip"
              title="Gestione filtri"
            >
              <Icon name="filter" />
            </div>
          ),
        }
      : null,
  ];

  return (
    <TableFilterContext.Provider
      value={{
        tableFilterState,
        tableFilterDispatch,
        dataGridState,
        dataGridDispatch,
        route,
        setRoute,
      }}
    >
      <SideBar
        className="sidebar--left"
        sideBarItems={sideBarItems}
        moduleName="Gestione"
      />
      <div className="standardlayout__container">
        <div className="scroll__container">
          {localStorage.getItem(LOCAL_STORAGE.BREADCRUMBS) && (
            <Breadcrumbs separator="›">
              {_.map(JSON.parse(breadcrumbs), (chunk, index) =>
                !_.isUndefined(chunk.link) ? (
                  <Link to={chunk.link} key={`breadcrumbs_${index}`}>
                    {!_.isEmpty(chunk.name) ? chunk.name : <Icon name="home" />}
                  </Link>
                ) : (
                  <div key={`breadcrumbs_${index}`}>
                    {!_.isEmpty(chunk.name) ? chunk.name : <Icon name="home" />}
                  </div>
                )
              )}
            </Breadcrumbs>
          )}
          <Outlet />
        </div>
        <Footer />
      </div>
    </TableFilterContext.Provider>
  );
};
export default StandardLayout;
