import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { Helmet } from "react-helmet";

import { FormattedMessage } from "react-intl";
import { Button, Grid, Header, Icon } from "semantic-ui-react";
import {
  announcementManagementReducer,
  AnnouncementManagementState,
  initialAnnouncementManagementState,
} from "./reducer/reducer";
import { AnnouncementManagementAction } from "./reducer/actions";
import { TableFilterContext } from "layout/StandardLayout";
import DataGrid from "components/DataGrid";
import { DataGridActionConstants } from "components/DataGrid/reducer/actions";
import { AnnouncementFormatterParams } from "components/DataGrid/types";
import { FE_ROUTES } from "utils/global/globalCostants";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { getManagementAnnouncementList } from "services/announcement";
import TableFilterForm from "components/Forms/TableFilterForm";
import Tooltip from "@material-ui/core/Tooltip";
import { TableFilterFormActionConstants } from "components/Forms/TableFilterForm/reducer/actions";
import { initialDataGridState } from "components/DataGrid/reducer/reducer";
import { TableFilterFormState } from "components/Forms/TableFilterForm/reducer/reducer";

export const defaultAnnouncementManagementFilters: TableFilterFormState = {
  searchText: "",
  orderBy: "earliest",
  userType: null,
};

export const AnnouncementManagementContext = createContext<{
  state: AnnouncementManagementState;
  dispatch: React.Dispatch<AnnouncementManagementAction>;
}>({
  state: initialAnnouncementManagementState,
  dispatch: () => null,
});

const AnnouncementManagement: React.FC = () => {
  const [state, dispatch] = useReducer(
    announcementManagementReducer,
    initialAnnouncementManagementState
  );

  const {
    tableFilterState,
    tableFilterDispatch,
    dataGridState,
    dataGridDispatch,
    route,
    setRoute,
  } = useContext(TableFilterContext);

  const [firstRender, setFirstRender] = useState<boolean>(true);

  /**
   * The hook to navigate throught the application.
   */
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const refPath = location.pathname.replaceAll("/", "");
    if (route != refPath) {
      tableFilterDispatch({
        type: TableFilterFormActionConstants.SET_FILTERS,
        payload: defaultAnnouncementManagementFilters,
      });
      setRoute(refPath);
    } else {
      getManagementAnnouncementList(
        dispatch,
        dataGridState.paginationData,
        tableFilterState,
        dataGridDispatch
      );
    }
  }, []);

  /**
   * Gets announcement list on pagination data change.
   */
  useEffect(() => {
    // Get announcementManagement collection
    if (!firstRender) {
      getManagementAnnouncementList(
        dispatch,
        dataGridState.paginationData,
        tableFilterState,
        dataGridDispatch
      );
    }
  }, [dataGridState.paginationData.page]);

  useEffect(() => {
    if (!firstRender) {
      if (
        dataGridState.paginationData.page !=
        initialDataGridState.paginationData.page
      )
        dataGridDispatch({
          type: DataGridActionConstants.RESET_PAGINATION,
        });
      else {
        getManagementAnnouncementList(
          dispatch,
          dataGridState.paginationData,
          tableFilterState,
          dataGridDispatch
        );
      }
    } else {
      setFirstRender(false);
    }
  }, [tableFilterState]);

  /**
   * Manages page selection change.
   * @param data
   */
  const handleSelectPage = (data: string | number) => {
    if (dataGridDispatch) {
      dataGridDispatch({
        type: DataGridActionConstants.SET_TABLE_PAGE_NUMBER,
        payload: {
          pageNumber: data,
        },
      });
    }
  };

  const resetPaginationAndFilters = () => {
    tableFilterDispatch({
      type: TableFilterFormActionConstants.SET_FILTERS,
      payload: defaultAnnouncementManagementFilters,
    });
  };

  return (
    <>
      <div className="announcement-management-container">
        <Helmet>
          <body className="announcement list " />
          <title>CliccaBandi.it - Gestione bandi</title>
          <meta name="description" content="gestione list page" />
        </Helmet>
        <div className="annoucement_management__content">
          <Header className="announcementManagementHeader" dividing>
            <Grid>
              <Grid.Row className="first-row">
                <Grid.Column>
                  <h1>
                    <FormattedMessage
                      id="announcementManagement.title"
                      defaultMessage="Elenco bandi"
                    />
                  </h1>
                </Grid.Column>
                <Grid.Column className="new_announcement__buttons">
                  <Button
                    type="button"
                    onClick={() => {
                      navigate(`${FE_ROUTES.GESTIONE_BANDI}/create`, {
                        replace: true,
                      });
                    }}
                  >
                    <div>
                      <Icon name="plus" />
                      <FormattedMessage
                        id="add.new.announcement"
                        defaultMessage="Aggiungi nuovo bando"
                      />
                    </div>
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <TableFilterForm
              searchFieldPlaceHolder={
                <FormattedMessage
                  id="announcement.announcementTitle"
                  defaultMessage="Ricerca per titolo"
                />
              }
              defaultFilters={tableFilterState}
              resetPagination={resetPaginationAndFilters}
            />
          </Header>

          <div className="announcementManagementSectionBody">
            <DataGrid
              isLoading={dataGridState.isLoading}
              elements={state.announcementList}
              columns={[
                {
                  key: "title",
                  name: "",
                  formatter: ({ data }: AnnouncementFormatterParams) => {
                    return (
                      <Tooltip
                        title={data.title ? data.title : ""}
                        classes={{
                          popper: "isGrey",
                        }}
                        arrow
                        placement="top"
                        enterDelay={800}
                      >
                        <div className="list_title">{data.title}</div>
                      </Tooltip>
                    );
                  },
                },
                {
                  key: "action",
                  name: "",
                  formatter: ({ data }: AnnouncementFormatterParams) => {
                    const annoucementLink = `${FE_ROUTES.GESTIONE_BANDI}/edit/${data.id}`;
                    return (
                      <Tooltip
                        title="Modifica"
                        classes={{
                          popper: "isGrey",
                        }}
                        arrow
                      >
                        <Link to={annoucementLink}>
                          <Icon name="pencil alternate" />
                        </Link>
                      </Tooltip>
                    );
                  },
                },
              ]}
              totalItems={state.announcementList.length}
              page={dataGridState.paginationData.page}
              onPageSelect={handleSelectPage}
              paginate
              pageSize={dataGridState.paginationData.page_size}
              pageCount={dataGridState.paginationData.page_count}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AnnouncementManagement;
