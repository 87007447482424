import React from "react";
import { Menu } from "semantic-ui-react";
import { SideBarProps } from "./types";
import { NavLink } from "react-router-dom";

const SideBar: React.FC<SideBarProps> = ({ className, sideBarItems }) => {
  return (
    <>
      <Menu className={`sidebar ${className}`} inverted size="massive">
        {sideBarItems.map(
          (sideBarItem) =>
            sideBarItem && (
              <Menu.Item
                className="sidebar__item"
                as={NavLink}
                style={{ textAlign: "center" }}
                key={sideBarItem.id}
                name={sideBarItem.id}
                to={sideBarItem.to}
              >
                {sideBarItem.title}
              </Menu.Item>
            )
        )}
      </Menu>
    </>
  );
};

export default SideBar;
