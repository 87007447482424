import { AppContext } from "pages/App";
import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
/** Types */
import { CheckAuthProps } from "./types";

const CheckAuth: React.FC<CheckAuthProps> = ({
  children,
  onlyNotAuth = false,
}) => {
  const { state } = useContext(AppContext);
  const location = useLocation();

  if (!state.isAuthenticated && onlyNotAuth) {
    return children;
  }

  if (!state.isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  /**
   * If the page is only for not authenticated user (as login or registration)
   * redirect the already auth user to the homepage
   */
  if (onlyNotAuth && state.isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};

export default CheckAuth;
