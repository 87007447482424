import _ from "lodash";
import { FilterTableGridType } from "pages/FilterManagement/types";

export const computeFilters = (
  endpointList: Array<string>
): Array<FilterTableGridType> => {
  const filterNameList = _.map(endpointList, (ep) => {
    const slicedEp = ep.slice(1);
    let splitIntoPieces = slicedEp.split("-");
    if (splitIntoPieces.length > 1) {
      splitIntoPieces = _.map(splitIntoPieces, (piece, index) => {
        if (index > 0 && splitIntoPieces[index]) {
          let newPiece = "";
          if (splitIntoPieces[index])
            newPiece =
              splitIntoPieces[index].slice(0, 1).toUpperCase() +
              splitIntoPieces[index].slice(1);
          return newPiece;
        }
        return piece;
      });
    }
    return {
      title: filterTranslation[splitIntoPieces.join("")],
      link: slicedEp,
    };
  });
  return filterNameList;
};

export const filterTranslation = {
  economicActivity: "Attività economiche (Settore ATECO)",
  eligibleCost: "Costi ammissibili",
  companySize: "Dimensione d'impresa",
  promotingInstitution: "Ente promotore",
  geographicalReference: "Riferimento geografico",
  topic: "Tematiche",
  fundingType: "Tipo di agevolazione",
  applicantCompanyForm: "Tipo di soggetto richiedente",
};
