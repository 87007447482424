import { RoleResponse, User } from "services/user/types";
import { SelectValueTextObject } from "utils/types";

export enum UserActionConstants {
  SET_SEARCH_FILTER = "User/SET_SEARCH_FILTER",
  SET_USER_DICTIONARY = "User/SET_USER_DICTIONARY",
  SET_USER = "User/SET_USER",
  SET_ROLE_LIST = "User/SET_ROLE_LIST",
  SET_RESET_PASSWORD_LOADING = "User/SET_RESET_PASSWORD_LOADING",
  SET_APPLICATION_TENANT_LIST = "User/SET_APPLICATION_TENANT_LIST",
  SET_IS_LOADING_APPLICATION_TENANT = "User/SET_IS_LOADING_APPLICATION_TENANT",
  SET_USER_ACTIVE = "User/SET_USER_ACTIVE",
  SET_USER_BLOCKED = "User/SET_USER_BLOCKED",
}

export enum UserPageActionConstants {
  VIEW = "view",
  MODIFY = "edit",
  NEW = "new",
}

export type UserAction =
  | {
      type: UserActionConstants.SET_SEARCH_FILTER;
      payload: { isLoading: boolean };
    }
  | {
      type: UserActionConstants.SET_USER_DICTIONARY;
      payload: { userList: Array<User> };
    }
  | {
      type: UserActionConstants.SET_USER;
      payload: { user: User };
    }
  | {
      type: UserActionConstants.SET_ROLE_LIST;
      payload: { roles: Array<RoleResponse> };
    }
  | {
      type: UserActionConstants.SET_RESET_PASSWORD_LOADING;
      payload: { isResetPasswordLoading: boolean };
    }
  | {
      type: UserActionConstants.SET_APPLICATION_TENANT_LIST;
      payload: { applicationTenantOptions: Array<SelectValueTextObject> };
    }
  | {
      type: UserActionConstants.SET_IS_LOADING_APPLICATION_TENANT;
      payload: { isLoadingApplicationTenant: boolean };
    }
  | {
      type: UserActionConstants.SET_USER_ACTIVE;
      payload: { userActive: boolean };
    }
  | {
      type: UserActionConstants.SET_USER_BLOCKED;
      payload: { userBlocked: boolean };
    };
