import React from "react";
import { Dimmer, Header, Icon } from "semantic-ui-react";
import { ErrorBoundaryProps, ErrorBoundaryState } from "./types";

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render(): JSX.Element {
    if (this.state.hasError) {
      return (
        <Dimmer active page>
          <Header as="h2" icon inverted>
            <Icon name="alarm" />
            Qualcosa è andato storto
            <Header.Subheader>
              <p>Ci dispiace, prova a ricaricare la pagina.</p>
              <p>Se il problema persiste contatta l&apos;assistenza</p>
            </Header.Subheader>
          </Header>
        </Dimmer>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
