import React from "react";
import { FormattedMessage } from "react-intl";
import FilterSidebar from "layout/FilterSideBar";
import Announcement from "pages/Announcement";

const ElencoBandiGridLayout: React.FC = () => {
  return (
    <>
      <div className="subHeader">
        <h1>
          <FormattedMessage
            id="announcements.title"
            defaultMessage="Scopri subito i bandi di contributo aperti!"
          />
        </h1>
        <h4>
          <FormattedMessage
            id="announcements.subTitle"
            defaultMessage="Verifica velocemente online quali bandi corrispondono alle tue esigenze"
          />
        </h4>
      </div>
      <div className="announcementMain__container">
        <FilterSidebar className="filterSidebar--left" moduleName="Gestione" />
        <div className="announcementGrid__container">
          <Announcement />
        </div>
      </div>
    </>
  );
};
export default ElencoBandiGridLayout;
