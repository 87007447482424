import React from "react";
import { FilterSideBarProps } from "./types";

import FilterAnnouncementForm from "components/Forms/FilterAnnouncementForm";

const FilterSidebar: React.FC<FilterSideBarProps> = ({ className }) => {
  return (
    <div className={`filterSidebar ${className}`}>
      <FilterAnnouncementForm />
    </div>
  );
};

export default FilterSidebar;
