import { AxiosResponse } from "axios";
import {
  AnnouncementAction,
  AnnouncementActionConstants,
} from "pages/Announcement/reducer/actions";
import React from "react";
import { toast } from "react-toastify";
import standardClient from "services/client/standardRequestClient";
import { API } from "utils/global/backendRoutes";
import { PaginateObject } from "utils/types/paginateObject";
import { NavigateFunction } from "react-router-dom";
import { AnnouncementObjectResponse } from "./types";
import { StandardResponseError } from "services/client/types";
import qs from "qs";
import { FilterAnnouncementFormValues } from "components/Forms/FilterAnnouncementForm/types";
import _ from "lodash";
import {
  AnnouncementDetailAction,
  AnnouncementDetailActionConstants,
} from "pages/AnnouncementDetail/reducer/actions";
import {
  Announcement,
  AnnouncementValuesTypes,
} from "pages/Announcement/types";
import {
  DataGridAction,
  DataGridActionConstants,
} from "components/DataGrid/reducer/actions";
import {
  AnnouncementManagementAction,
  AnnouncementManagementActionConstants,
} from "pages/AnnouncementManagement/reducer/actions";
import { PaginationData } from "components/DataGrid/types";
import { TableFilterFormState } from "components/Forms/TableFilterForm/reducer/reducer";
import { getOrderByObject } from "utils/function/orderByResults";
import { FormikHelpers } from "formik";
import { FE_ROUTES } from "utils/global/globalCostants";
import { uploadAttachments } from "services/attachments";
import downloadDocument from "utils/function/downloadDocument";

/* Get all announcements with filters and sorting */
export const getAnnouncementList = (
  announcementDispatch: React.Dispatch<AnnouncementAction>,
  orderByValue: string | null,
  paginationData: PaginationData
): void => {
  announcementDispatch({
    type: AnnouncementActionConstants.SET_ANNOUNCEMENT_IS_LOADING,
    payload: { isLoading: true },
  });

  const params = {
    filter: [
      {
        type: "eq",
        field: "isArchived",
        value: 0,
      },
      {
        type: "eq",
        field: "isDeleted",
        value: 0,
      },
    ].filter((o) => o),
    pageSize: paginationData.page_size,
    page: _.get(paginationData, "page", 1) === 0 ? 1 : paginationData.page,
  };

  params["order-by"] = getOrderByObject(orderByValue, "title", {
    field: "createdAt",
    direction: "desc",
  });

  const paramsSerializer = qs.stringify(params);

  standardClient({
    url: `${API.ANNOUNCEMENT}?${paramsSerializer}`,
    method: "GET",
  })
    .then((res: AxiosResponse<PaginateObject<AnnouncementObjectResponse>>) => {
      const annoucementValues = res.data._embedded.announcement;

      announcementDispatch({
        type: AnnouncementActionConstants.SET_ANNOUNCEMENT_LIST,
        payload: { announcementList: annoucementValues },
      });
      announcementDispatch({
        type: AnnouncementActionConstants.SET_ANNOUNCEMENT_IS_LOADING,
        payload: { isLoading: false },
      });
      announcementDispatch({
        type: AnnouncementActionConstants.SET_ANNOUNCEMENT_PAGE_PAGE_COUNT,
        payload: {
          page_count: res.data.page_count,
        },
      });
      announcementDispatch({
        type: AnnouncementActionConstants.SET_ANNOUNCEMENT_PAGE_NUMBER,
        payload: {
          pageNumber: res.data.page == 0 ? 1 : res.data.page,
        },
      });
      announcementDispatch({
        type: AnnouncementActionConstants.SET_ANNOUNCEMENT_PAGE_TOTAL_ITEMS,
        payload: {
          total_items: res.data.total_items,
        },
      });
    })
    .catch(() => {
      toast.error("Errore durante il caricamento della lista dei bandi");
    })
    .finally(() => {
      announcementDispatch({
        type: AnnouncementActionConstants.SET_ANNOUNCEMENT_IS_LOADING,
        payload: { isLoading: false },
      });
    });
};

/**
 * Get single announcement data by id.
 * @param announcementId
 */
export const getAnnouncementById = (
  announcementId: string,
  announcementDetailDispatch: React.Dispatch<AnnouncementDetailAction>,
  loading?: boolean,
  reloading?: boolean
): void => {
  if (loading) {
    announcementDetailDispatch({
      type: AnnouncementDetailActionConstants.SET_IS_LOADING,
      payload: { isLoading: true },
    });
  }
  if (reloading) {
    announcementDetailDispatch({
      type: AnnouncementDetailActionConstants.SET_IS_RELOADING,
      payload: { isReloading: true },
    });
  }
  standardClient({
    url: `${API.ANNOUNCEMENT}/${announcementId}`,
    method: "GET",
  })
    .then((response: AxiosResponse<Announcement>) => {
      announcementDetailDispatch({
        type: AnnouncementDetailActionConstants.SET_ANNOUNCEMENT,
        payload: { announcement: response.data },
      });
    })
    .catch(() => {
      toast.error("Errore durante il caricamento dei dettagli del bando");
    })
    .finally(() => {
      if (loading) {
        announcementDetailDispatch({
          type: AnnouncementDetailActionConstants.SET_IS_LOADING,
          payload: { isLoading: false },
        });
      }
      if (reloading) {
        announcementDetailDispatch({
          type: AnnouncementDetailActionConstants.SET_IS_RELOADING,
          payload: { isReloading: false },
        });
      }
    });
};

/* Get all announcements with filters and sorting */
export const getManagementAnnouncementList = (
  announcementManagementDispatch: React.Dispatch<AnnouncementManagementAction>,
  paginationData: PaginationData,
  values: TableFilterFormState,
  dataGridDispatch: React.Dispatch<DataGridAction>,
  archived?: boolean
): void => {
  dataGridDispatch({
    type: DataGridActionConstants.SET_IS_LOADING,
    payload: { isLoading: true },
  });

  const searchText = values.searchText || null;
  const orderBy = values.orderBy;

  const params = {
    filter: [
      searchText && {
        type: "like",
        field: "title",
        value: `%${searchText}%`,
      },
      archived && {
        type: "eq",
        field: "isArchived",
        value: 1,
      },
      !archived && {
        type: "eq",
        field: "isArchived",
        value: 0,
      },
      {
        type: "eq",
        field: "isDeleted",
        value: 0,
      },
    ].filter((o) => o),
    pageSize: paginationData.page_size,
    page: _.get(paginationData, "page", 1) === 0 ? 1 : paginationData.page,
  };

  archived
    ? (params["order-by"] = getOrderByObject(orderBy, "title", {
        field: "archivalDate",
        direction: "desc",
      }))
    : (params["order-by"] = getOrderByObject(orderBy, "title", {
        field: "createdAt",
        direction: "desc",
      }));

  const paramsSerializer = qs.stringify(params);
  standardClient({
    url: `${API.ANNOUNCEMENT}?${paramsSerializer}`,
    method: "GET",
  })
    .then(
      (response: AxiosResponse<PaginateObject<AnnouncementObjectResponse>>) => {
        const annoucementValues = response.data._embedded.announcement;
        announcementManagementDispatch({
          type: AnnouncementManagementActionConstants.SET_ANNOUNCEMENT_LIST,
          payload: { announcementList: annoucementValues },
        });
        setDataGridPaginator(response, dataGridDispatch);
      }
    )
    .catch(() => {
      toast.error("Errore durante il caricamento della lista dei bandi");
    })
    .finally(() => {
      dataGridDispatch({
        type: DataGridActionConstants.SET_IS_LOADING,
        payload: { isLoading: false },
      });
    });
};

/**
 * Set paginator for DataGrid.
 * @param response
 * @param dataGridDispatch
 * @returns
 */
const setDataGridPaginator = (
  response: AxiosResponse<PaginateObject<AnnouncementObjectResponse>>,
  dataGridDispatch: React.Dispatch<DataGridAction>
) => {
  dataGridDispatch({
    type: DataGridActionConstants.SET_TABLE_PAGE_PAGE_COUNT,
    payload: {
      page_count: response.data.page_count,
    },
  });
  dataGridDispatch({
    type: DataGridActionConstants.SET_TABLE_PAGE_NUMBER,
    payload: {
      pageNumber: response.data.page == 0 ? 1 : response.data.page,
    },
  });
  dataGridDispatch({
    type: DataGridActionConstants.SET_TABLE_PAGE_TOTAL_ITEMS,
    payload: {
      total_items: response.data.total_items,
    },
  });
  return dataGridDispatch;
};

/**
 * Updates an announcement.
 * @param content
 * @param formikHelpers
 * @param announcementDetailDispatch
 * @returns
 */
export const updateAnnouncement = (
  content: Partial<AnnouncementValuesTypes>,
  formikHelpers?: FormikHelpers<AnnouncementValuesTypes> | undefined,
  announcementDetailDispatch?: React.Dispatch<AnnouncementDetailAction>
): Promise<void> => {
  const { id, ...rest } = content;

  return (
    standardClient({
      url: `${API.ANNOUNCEMENT}/${id}`,
      method: "PUT",
      data: {
        ...rest,
      },
    })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((response: AxiosResponse<Announcement>) => {
        toast.success("Bando aggiornato correttamente");
        const announcementId = response.data.id;
        const loading = false;
        const reloading = true;
        if (
          content.attachmentsToUpload &&
          content.attachmentsToUpload.length > 0
        ) {
          uploadAttachments(content.attachmentsToUpload, announcementId).then(
            () => {
              if (announcementId && announcementDetailDispatch)
                getAnnouncementById(
                  announcementId,
                  announcementDetailDispatch,
                  loading,
                  reloading
                );
            }
          );
        } else {
          if (announcementId && announcementDetailDispatch)
            getAnnouncementById(
              announcementId,
              announcementDetailDispatch,
              loading,
              reloading
            );
        }
      })
      .catch((error: AxiosResponse<StandardResponseError>) => {
        const errors = _.get(error, "data.validation_messages", null);

        if (formikHelpers && errors) {
          // Set the errors in the Formik bag.
          _.forOwn(errors, (value, key) => {
            for (const i in value) {
              formikHelpers.setFieldError(key, value[i]);
              break;
            }
          });
        } else {
          toast.error("Errore durante l'aggiornamento del bando");
        }
      })
      .finally(() => {
        // Stop formik submission.
        formikHelpers?.setSubmitting(false);
      })
  );
};

/**
 * Create an announcement.
 * @param content
 * @param formikHelpers
 * @param navigate
 * @param announcementDetailDispatch
 * @returns
 */
export const createAnnouncement = (
  content: Partial<AnnouncementValuesTypes>,
  formikHelpers?: FormikHelpers<AnnouncementValuesTypes>,
  navigate?: NavigateFunction,
  announcementDetailDispatch?: React.Dispatch<AnnouncementDetailAction>
): Promise<void> =>
  standardClient({
    url: `${API.ANNOUNCEMENT}`,
    method: "POST",
    data: {
      ...content,
    },
  })
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .then((response: AxiosResponse<Announcement>) => {
      toast.success("Bando creato correttamente");
      const announcementId = response.data.id;
      if (
        content.attachmentsToUpload &&
        content.attachmentsToUpload.length > 0
      ) {
        uploadAttachments(content.attachmentsToUpload, announcementId);
      }
      if (navigate)
        navigate(`${FE_ROUTES.GESTIONE_BANDI}/edit/${response.data.id}`);
      const loading = false;
      const reloading = true;
      if (response.data.id && announcementDetailDispatch)
        getAnnouncementById(
          response.data.id,
          announcementDetailDispatch,
          loading,
          reloading
        );
    })
    .catch((error: AxiosResponse<StandardResponseError>) => {
      const errors = _.get(error, "data.validation_messages", null);

      if (formikHelpers && errors) {
        _.forOwn(errors, (value, key) => {
          for (const i in value) {
            formikHelpers.setFieldError(key, value[i]);
            break;
          }
        });
      } else {
        toast.error("Errore durante la creazione del bando");
      }
    })
    .finally(() => {
      if (formikHelpers) formikHelpers.setSubmitting(false);
    });

/**
 * Export the report file by the selected type.
 * @param content
 * @param formikHelpers
 * @param navigate
 * @param announcementDetailDispatch
 * @returns
 */
export const exportReport = (
  filters: FilterAnnouncementFormValues,
  exportTypeValue,
  announcementDispatch: React.Dispatch<AnnouncementAction>
) => {
  announcementDispatch({
    type: AnnouncementActionConstants.SET_ANNOUNCEMENT_GENERATING_EXPORT,
    payload: { generatingReport: true },
  });
  const payload = {
    filters: filters,
    exportType: "",
  };
  if (exportTypeValue === "excel") {
    payload.exportType = "announcement-excel-report";
  } else if (exportTypeValue === "csv") {
    payload.exportType = "announcement-csv-report";
  }

  standardClient({
    url: `${API.EXPORT_ANNOUNCEMENT_REPORT}`,
    method: "POST",
    responseType: "blob",
    data: { ...payload },
  })
    .then((res) => downloadDocument(res))
    .catch(() => toast.error("Errore durante la creazione del report"))
    .finally(() =>
      announcementDispatch({
        type: AnnouncementActionConstants.SET_ANNOUNCEMENT_GENERATING_EXPORT,
        payload: { generatingReport: false },
      })
    );
};

export const getFilteredAnnouncement = (
  announcementDispatch: React.Dispatch<AnnouncementAction>,
  filters: FilterAnnouncementFormValues,
  orderByValue: string | null,
  paginationData: PaginationData
): void => {
  announcementDispatch({
    type: AnnouncementActionConstants.SET_ANNOUNCEMENT_IS_LOADING,
    payload: { isLoading: true },
  });

  const orderByObj = getOrderByObject(orderByValue, "title", {
    field: "createdAt",
    direction: "desc",
  });

  standardClient({
    url: `${API.FILTER_ANNOUNCEMENT}`,
    method: "POST",
    data: {
      orderBy: orderByObj[0],
      paginationData,
      filters,
    },
  })
    .then((res: AxiosResponse<PaginateObject<AnnouncementObjectResponse>>) => {
      const annoucementValues = res.data._embedded.announcement;

      announcementDispatch({
        type: AnnouncementActionConstants.SET_ANNOUNCEMENT_LIST,
        payload: { announcementList: annoucementValues },
      });
      announcementDispatch({
        type: AnnouncementActionConstants.SET_ANNOUNCEMENT_IS_LOADING,
        payload: { isLoading: false },
      });
      announcementDispatch({
        type: AnnouncementActionConstants.SET_ANNOUNCEMENT_PAGE_PAGE_COUNT,
        payload: {
          page_count: res.data.page_count,
        },
      });
      announcementDispatch({
        type: AnnouncementActionConstants.SET_ANNOUNCEMENT_PAGE_NUMBER,
        payload: {
          pageNumber: res.data.page == 0 ? 1 : res.data.page,
        },
      });
      announcementDispatch({
        type: AnnouncementActionConstants.SET_ANNOUNCEMENT_PAGE_TOTAL_ITEMS,
        payload: {
          total_items: res.data.total_items,
        },
      });
    })
    .catch(() => {
      toast.error("Errore durante il caricamento della lista dei bandi");
    })
    .finally(() => {
      announcementDispatch({
        type: AnnouncementActionConstants.SET_ANNOUNCEMENT_IS_LOADING,
        payload: { isLoading: false },
      });
    });
};
