import React from "react";
import { Field, Form, SubmitButton } from "formik-semantic-ui-react";
import { ErrorMessage, Formik, FormikHelpers } from "formik";
import { Grid, Header } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import * as Yup from "yup";

import { PasswordResetRequest } from "services/passwordRecovery/types";
import { resetPassword } from "services/passwordRecovery";
import { PasswordResetProps } from "./types";
import { useSearchParams } from "react-router-dom";
import { PasswordInputFormik } from "components/PasswordInputFormik";

const PasswordResetForm: React.FC<PasswordResetProps> = ({ setResponse }) => {
  const [queryParams] = useSearchParams();

  /**
   * The initial value of the token comes from the URL parameters.
   */
  const initialValues: PasswordResetRequest = {
    token: queryParams.get("token"),
    plainPassword: "",
    plainPasswordCheck: "",
  };

  const onSubmit = (
    values: PasswordResetRequest,
    formikHelpers: FormikHelpers<PasswordResetRequest>
  ) => {
    resetPassword(values, formikHelpers, setResponse);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(value, formikHelpers) => onSubmit(value, formikHelpers)}
      validationSchema={Yup.object().shape({
        token: Yup.string().required().nullable(),
        plainPassword: Yup.string()
          .required("Password è un campo richiesto")
          .nullable()
          .min(8, "La password deve essere almeno di 8 caratteri")
          .matches(
            /[\'^£$%&*()}{@#~?!><>,|=_+-]/,
            "La password deve contenere almeno un carattere speciale"
          )
          .matches(/\d/, "La password deve contenere almeno un numero")
          .matches(
            /[a-z]/,
            "La password deve contenere almeno una lettera minuscola"
          )
          .matches(
            /[A-Z]/,
            "La password deve contenere almeno una lettera maiuscola"
          ),
        plainPasswordCheck: Yup.string()
          .required("Conferma password è un campo richiesto")
          .nullable()
          .min(8, "Conferma password deve essere almeno di 8 caratteri")
          .oneOf(
            [Yup.ref("plainPassword"), null],
            "Entrambe le password devono combaciare"
          ),
      })}
    >
      {({ setFieldValue, handleBlur }) => (
        <Form>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header as="h3" className="password-reset__title">
                  <FormattedMessage
                    id="passwordReset.title"
                    defaultMessage="Imposta la password"
                  />
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Field name="plainPassword">
                  {({ field }) => (
                    <PasswordInputFormik
                      placeholder={"Password*"}
                      label="Password*"
                      type="password"
                      setFieldValue={setFieldValue}
                      field={field}
                      onBlur={handleBlur}
                    />
                  )}
                </Field>
                <ErrorMessage name="plainPassword" component="span" />
              </Grid.Column>
              <Grid.Column>
                <Field name="plainPasswordCheck">
                  {({ field }) => (
                    <PasswordInputFormik
                      placeholder={"Conferma password*"}
                      label="Conferma password*"
                      type="password"
                      setFieldValue={setFieldValue}
                      field={field}
                      onBlur={handleBlur}
                    />
                  )}
                </Field>
                <ErrorMessage name="plainPasswordCheck" component="span" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="token--error--message">
              <ErrorMessage name="token" />
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <SubmitButton
                  className="login--button"
                  content={
                    <FormattedMessage
                      id="passwordReset.submit"
                      defaultMessage="Reimposta password"
                    />
                  }
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default PasswordResetForm;
