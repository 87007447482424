import React, { useEffect, useReducer, useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import { Grid, Header, Icon } from "semantic-ui-react";
import {
  announcementManagementReducer,
  initialAnnouncementManagementState,
} from "pages/AnnouncementManagement/reducer/reducer";
import DataGrid from "components/DataGrid";
import { DataGridActionConstants } from "components/DataGrid/reducer/actions";
import { AnnouncementFormatterParams } from "components/DataGrid/types";
import { FE_ROUTES } from "utils/global/globalCostants";
import { useNavigate, useLocation } from "react-router-dom";
import { getManagementAnnouncementList } from "services/announcement";
import TableFilterForm from "components/Forms/TableFilterForm";
import Tooltip from "@material-ui/core/Tooltip";
import { TableFilterContext } from "layout/StandardLayout";
import { TableFilterFormActionConstants } from "components/Forms/TableFilterForm/reducer/actions";
import { initialDataGridState } from "components/DataGrid/reducer/reducer";
import { TableFilterFormState } from "components/Forms/TableFilterForm/reducer/reducer";

export const defaultAnnouncementArchiveFilters: TableFilterFormState = {
  searchText: "",
  orderBy: "latestArchived",
  userType: null,
};

const AnnouncementManagement: React.FC = () => {
  const [state, dispatch] = useReducer(
    announcementManagementReducer,
    initialAnnouncementManagementState
  );

  const {
    tableFilterState,
    tableFilterDispatch,
    dataGridState,
    dataGridDispatch,
    route,
    setRoute,
  } = useContext(TableFilterContext);

  const [firstRender, setFirstRender] = useState<boolean>(true);

  /**
   * The hook to navigate thorught the application.
   */
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;

  const additionalOrderByOptions = [
    {
      text: (
        // eslint-disable-next-line react/react-in-jsx-scope
        <FormattedMessage
          id="orderBy.latestArchived"
          defaultMessage="Archiviati più recenti"
        />
      ),
      value: "latestArchived",
    },
    {
      text: (
        // eslint-disable-next-line react/react-in-jsx-scope
        <FormattedMessage
          id="orderBy.oldestArchived"
          defaultMessage="Archiviati meno recenti"
        />
      ),
      value: "oldestArchived",
    },
  ];

  useEffect(() => {
    const refPath = location.pathname.replaceAll("/", "");
    if (route != refPath) {
      tableFilterDispatch({
        type: TableFilterFormActionConstants.SET_FILTERS,
        payload: defaultAnnouncementArchiveFilters,
      });
      setRoute(refPath);
    } else {
      getManagementAnnouncementList(
        dispatch,
        dataGridState.paginationData,
        tableFilterState,
        dataGridDispatch,
        true
      );
    }
  }, []);

  /**
   * Gets announcement list on pagination data change.
   */
  useEffect(() => {
    // Get announcementManagement collection {
    if (!firstRender) {
      getManagementAnnouncementList(
        dispatch,
        dataGridState.paginationData,
        tableFilterState,
        dataGridDispatch,
        true
      );
    }
  }, [dataGridState.paginationData.page]);

  useEffect(() => {
    if (!firstRender) {
      if (
        dataGridState.paginationData.page !=
        initialDataGridState.paginationData.page
      )
        dataGridDispatch({
          type: DataGridActionConstants.RESET_PAGINATION,
        });
      else {
        getManagementAnnouncementList(
          dispatch,
          dataGridState.paginationData,
          tableFilterState,
          dataGridDispatch,
          true
        );
      }
    } else {
      setFirstRender(false);
    }
  }, [tableFilterState]);

  /**
   * Manages page selection change.
   * @param data
   */
  const handleSelectPage = (data: string | number) => {
    if (dataGridDispatch) {
      dataGridDispatch({
        type: DataGridActionConstants.SET_TABLE_PAGE_NUMBER,
        payload: {
          pageNumber: data,
        },
      });
    }
  };

  const resetPaginationAndFilters = () => {
    tableFilterDispatch({
      type: TableFilterFormActionConstants.SET_FILTERS,
      payload: defaultAnnouncementArchiveFilters,
    });
  };

  return (
    <>
      <div className="announcement-archive-container">
        <Helmet>
          <body className="announcement list " />
          <title>CliccaBandi.it - Archivio bandi</title>
          <meta name="description" content="gestione list page" />
        </Helmet>
        <div className="annoucement_management__content">
          <Header className="announcementArchiveHeader" dividing>
            <Grid>
              <Grid.Row className="first-row">
                <Grid.Column>
                  <h1>
                    <FormattedMessage
                      id="announcementArchive.title"
                      defaultMessage="Archivio bandi"
                    />
                  </h1>
                </Grid.Column>
                <Grid.Column className="buttons"></Grid.Column>
              </Grid.Row>
            </Grid>
            <TableFilterForm
              searchFieldPlaceHolder={
                <FormattedMessage
                  id="announcement.announcementTitle"
                  defaultMessage="Ricerca per titolo"
                />
              }
              defaultFilters={tableFilterState}
              resetPagination={resetPaginationAndFilters}
              additionalOrderByOptions={additionalOrderByOptions}
            />
          </Header>

          <div className="announcementArchiveSectionBody">
            <DataGrid
              isLoading={dataGridState.isLoading}
              elements={state.announcementList}
              columns={[
                {
                  key: "title",
                  name: "",
                  formatter: ({ data }: AnnouncementFormatterParams) => {
                    return (
                      <Tooltip
                        title={data.title ? data.title : ""}
                        classes={{
                          popper: "isGrey",
                        }}
                        arrow
                        placement="top"
                        enterDelay={800}
                      >
                        <div className="list_title">{data.title}</div>
                      </Tooltip>
                    );
                  },
                },
                {
                  key: "action",
                  name: "",
                  formatter: ({ data }: AnnouncementFormatterParams) => {
                    const announcementLink = `${FE_ROUTES.ARCHIVIO_BANDI}/edit/${data.id}`;
                    return (
                      <Tooltip
                        title="Modifica"
                        classes={{
                          popper: "isGrey",
                        }}
                        arrow
                      >
                        <div
                          onClick={() => {
                            navigate(announcementLink, {
                              state: { from: pathName },
                            });
                          }}
                        >
                          <Icon name="pencil alternate" />
                        </div>
                      </Tooltip>
                    );
                  },
                },
              ]}
              totalItems={state.announcementList.length}
              page={dataGridState.paginationData.page}
              onPageSelect={handleSelectPage}
              paginate
              pageSize={dataGridState.paginationData.page_size}
              pageCount={dataGridState.paginationData.page_count}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AnnouncementManagement;
