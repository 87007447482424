import React from "react";
import { Form, Input, SubmitButton } from "formik-semantic-ui-react";
import { ErrorMessage, Formik, FormikHelpers } from "formik";
import { Grid, Header } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import * as Yup from "yup";
import { PasswordRecoveryRequest } from "services/passwordRecovery/types";
import { requestRecovery } from "services/passwordRecovery";
import { PasswordRecoveryFormProps } from "./types";

const PasswordRecoveryForm: React.FC<PasswordRecoveryFormProps> = ({
  setResponse,
}) => {
  const initialValues: PasswordRecoveryRequest = {
    email: "",
  };

  const intl = useIntl();

  const onSubmit = (
    values: PasswordRecoveryRequest,
    formikHelpers: FormikHelpers<PasswordRecoveryRequest>
  ) => requestRecovery(values, formikHelpers, setResponse);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        email: Yup.string().required("Email è un campo richiesto").nullable(),
      })}
    >
      <Form className="password-recovery__form" autocomplete="off">
        <Grid padded textAlign="left">
          <Grid.Row>
            <Grid.Column>
              <Header as="h3" className="password-recovery__title--h3">
                <FormattedMessage
                  id="recoverPassword.title"
                  defaultMessage="Recupero password"
                />
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Input
                name="email"
                placeholder={intl.formatMessage({
                  id: "app.email",
                  defaultMessage: "email",
                })}
                label={
                  <FormattedMessage id="app.email" defaultMessage="Email" />
                }
              />
              <ErrorMessage name="email" component="span" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign="center">
              <SubmitButton
                className="button--submit"
                content={
                  <div>
                    <FormattedMessage
                      id="recoverPassword.submit"
                      defaultMessage="Recupera password"
                    />
                  </div>
                }
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </Formik>
  );
};

export default PasswordRecoveryForm;
