import _ from "lodash";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { Helmet } from "react-helmet";
import { Grid, Header, Tab } from "semantic-ui-react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { createUser, getUserById, updateUser } from "services/user/index";
import {
  userProfileReducer,
  UserProfileState,
  initialUserProfileState,
} from "./reducer/reducer";
import {
  UserAction,
  UserActionConstants,
  UserPageActionConstants,
} from "pages/User/reducer/actions";
import { FormikHelpers } from "formik";
import { TableFilterContext } from "layout/StandardLayout";

// Types
import { User, UserPayload } from "services/user/types";

// Components
import UserProfileForm from "components/Forms/UserProfileForm";
import { isThatRole } from "utils/function/acl";
import { ROLES } from "utils/global/globalCostants";
import { AppContext } from "pages/App";

export const UserProfileContext = createContext<{
  state: UserProfileState;
  dispatch: React.Dispatch<UserAction>;
}>({
  state: initialUserProfileState,
  dispatch: () => null,
});

const UserProfile: React.FC = () => {
  /** Get params */
  const { action, id } = useParams();

  /** Get navigate to redirect to user list after creating user */
  const navigate = useNavigate();
  /**
   * The react-intl object.
   */
  const intl = useIntl();

  /** Gets the reducer to receive GET request result */
  const [state, dispatch] = useReducer(
    userProfileReducer,
    initialUserProfileState
  );
  const { tableFilterState } = useContext(TableFilterContext);

  const { state: appState } = useContext(AppContext);
  /**
   * Method used to get user values filtered by given user id with an async request.
   */
  const getuserValues = () => {
    // Get the user
    if (id) {
      getUserById(id, dispatch);
    }
  };

  /**
   * Reads user values at startup.
   */
  useEffect(() => {
    if (
      tableFilterState.userType === "active" ||
      tableFilterState.userType === "blocked"
    ) {
      dispatch({
        type: UserActionConstants.SET_USER_ACTIVE,
        payload: {
          userActive: true,
        },
      });
    }
    if (tableFilterState.userType === "blocked") {
      dispatch({
        type: UserActionConstants.SET_USER_BLOCKED,
        payload: {
          userBlocked: true,
        },
      });
    }
    getuserValues();
  }, []);

  /**
   * Set the initial values from the request state.
   * It helps as we need to transform complex fields.
   */
  const initialValues = useMemo<User>(() => {
    const transformData = {
      id: state.user.id,
      displayName: null,
      firstName: state.user.firstName,
      lastName: state.user.lastName,
      email: state.user.email,
      role: state.user.role,
      username: state.user.username,
      tenant: state.user.tenant,
      isBlocked: state.user.isBlocked,
      active: state.user.active,
    };
    return transformData;
  }, [state.user]);

  /**
   * Method used to manage the submit action from userProfileForm component.
   * @param values user data.
   */
  const onSubmit = (values: User, formikHelpers: FormikHelpers<User>) => {
    // Clean data
    const data: UserPayload = cleanData(values);

    switch (action) {
      case UserPageActionConstants.MODIFY:
        // Update the user
        if (id) {
          updateUser(data, id, formikHelpers);
        }
        break;
      case UserPageActionConstants.NEW:
        // Create the new user
        createUser(data, formikHelpers, navigate);
        break;
    }
  };

  /**
   * Method used to clean the data before sending the request to backend services.
   * @param values user data.
   */
  const cleanData = (values: User): UserPayload => {
    let tenant: string | null = null;
    if (values.tenant?.value && _.isString(values.tenant?.value))
      tenant = values.tenant?.value;

    const payload: UserPayload = {
      id: values.id,
      displayName: values.displayName,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      role: values.role,
      username: values.email,
      isBlocked: values.isBlocked,
      active: values.active,
      tenant: tenant,
    };

    if (isThatRole(ROLES.BANK_ADMIN, appState) && _.isNull(values.tenant)) {
      if (appState.mySelf?.tenant) {
        payload.tenant = appState.mySelf?.tenant.applicationName;
      }
    }

    return payload;
  };

  return (
    <UserProfileContext.Provider value={{ state, dispatch }}>
      <div>
        <Helmet>
          <title>
            {"Cliccabandi.it - " +
              intl.formatMessage({
                id: `profile.title`,
                defaultMessage: "Profilo utente",
              })}
          </title>
          <meta name="description" content="user profile page" />
        </Helmet>
        <div className="user-container">
          <>
            <div>
              <Header className="userHeader" dividing>
                <Grid>
                  <Grid.Row className="first-row">
                    <Grid.Column>
                      <Header>
                        <h1>
                          <FormattedMessage
                            id="profile.title"
                            defaultMessage="Profilo utente"
                          />
                        </h1>
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Header>
              <div className="userSectionBody">
                <Tab.Pane>
                  <UserProfileForm
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    mode={action}
                    userId={id}
                  />
                </Tab.Pane>
              </div>
            </div>
          </>
        </div>
      </div>
    </UserProfileContext.Provider>
  );
};

export default UserProfile;
