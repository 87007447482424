import _, { forEach } from "lodash";
import standardClient from "services/client/standardRequestClient";
import qs from "qs";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import React from "react";
import { UserAction, UserActionConstants } from "pages/User/reducer/actions";

// Constants
import { API } from "utils/global/backendRoutes";

// Types
import { StandardResponseError } from "services/client/types";
import {
  RoleResponse,
  TenantObjectResponse,
  TenantResponse,
  User,
  UserResponse,
  UserPayload,
} from "services/user/types";
import { UserObjectResponse, RoleObjectResponse } from "./types";
import {
  DataGridAction,
  DataGridActionConstants,
} from "components/DataGrid/reducer/actions";
import { PaginationData } from "components/DataGrid/types";
import { FormikHelpers } from "formik";
import { PaginateObject } from "utils/types/paginateObject";
import { TableFilterFormState } from "components/Forms/TableFilterForm/reducer/reducer";
import { getOrderByObject } from "utils/function/orderByResults";
import {
  FirstLoginFormValues,
  FirstLoginResponse,
} from "components/Forms/FirstLoginForm/types";
import {
  FirstLoginAction,
  FirstLoginActionConstants,
} from "pages/FirstLogin/reducer/actions";
import { FE_ROUTES } from "utils/global/globalCostants";
import { SelectValueTextObject } from "utils/types";
import { NavigateFunction } from "react-router-dom";

/**
 * Get single user by id.
 * @param userId
 * @param dispatch
 */
export const getUserById = (
  userId: string,
  dispatch: React.Dispatch<UserAction>
): void => {
  standardClient({
    url: `${API.USERS}/${userId}`,
    method: "GET",
  })
    .then((response: AxiosResponse<UserResponse>) => {
      const user: User = {
        id: response.data.id,
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        displayName: response.data.displayName,
        email: response.data.email,
        role: response.data.role?.length === 1 ? response.data.role[0] : null,
        username: response.data.username,
        isBlocked: response.data.isBlocked,
        active: response.data.active,
        tenant: response.data.tenant
          ? {
              value: response.data.tenant.id,
              text: response.data.tenant.applicationName,
            }
          : null,
      };
      dispatch({
        type: UserActionConstants.SET_USER,
        payload: { user: user },
      });
    })
    .catch(() => {
      toast.error("Errore durante il caricamento delle informazioni utente");
    });
};

/**
 * Gets user list with filters applied if any.
 * @param values Filters values.
 * @param paginationData Pagination data.
 * @param dispatch Dispatch to manage callback results.
 */
export const getUserList = (
  values: TableFilterFormState,
  paginationData: PaginationData,
  dataGridDispatch: React.Dispatch<DataGridAction>,
  dispatch: React.Dispatch<UserAction>
): void => {
  const searchText = values.searchText || null;
  const orderBy = values.orderBy;
  const params = {
    filter: [
      // searchText && {
      //   type: "like",
      //   field: "displayName",
      //   value: `%${searchText}%`,
      // },
      values.userType &&
        values.userType === "active" && {
          type: "andx",
          conditions: [
            {
              type: "eq",
              field: "isBlocked",
              value: 0,
              where: "and",
            },
            {
              type: "eq",
              field: "active",
              value: 1,
              where: "and",
            },
          ],
        },
      values.userType &&
        values.userType === "blocked" && {
          type: "eq",
          field: "isBlocked",
          value: 1,
        },
      values.userType &&
        values.userType === "inactive" && {
          type: "eq",
          field: "active",
          value: 0,
        },
    ].filter((o) => o),
    pageSize: paginationData.page_size,
    page: _.get(paginationData, "page", 1) === 0 ? 1 : paginationData.page,
  };

  if (searchText) {
    const searchList = searchText.split(" ");
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let searchTextFilter: any = {};
    if (searchList.length === 1) {
      searchTextFilter = {
        type: "like",
        field: "displayName",
        value: `%${searchText}%`,
      };
    } else {
      searchTextFilter["type"] = "andx";
      searchTextFilter["conditions"] = [];
      forEach(searchList, (word) => {
        const condition = {
          type: "like",
          field: "displayName",
          value: `%${word}%`,
          where: "and",
        };
        searchTextFilter["conditions"].push(condition);
      });
    }
    if (_.has(params, "filter")) {
      params["filter"].push(searchTextFilter);
    }
  }

  params["order-by"] = getOrderByObject(orderBy, "displayName", {
    field: "displayName",
    direction: "asc",
  });

  // Convert params to string
  const paramsSerializer = qs.stringify(params);

  dataGridDispatch({
    type: DataGridActionConstants.SET_IS_LOADING,
    payload: {
      isLoading: true,
    },
  });

  standardClient({
    url: `${API.USERS}?${paramsSerializer}`,
    method: "GET",
  })
    .then((response: AxiosResponse<PaginateObject<UserObjectResponse>>) => {
      // Dispatch the notification for user dictionary
      dispatch({
        type: UserActionConstants.SET_USER_DICTIONARY,
        payload: { userList: response.data._embedded.user },
      });
      setDataGridPaginator(response, dataGridDispatch);
      dataGridDispatch({
        type: DataGridActionConstants.SET_IS_LOADING,
        payload: {
          isLoading: false,
        },
      });
    })
    .catch(() => {
      toast.error("Errore durante il caricamento degli utenti");
      dataGridDispatch({
        type: DataGridActionConstants.SET_IS_LOADING,
        payload: {
          isLoading: false,
        },
      });
    });
};

/**
 * Create a new user.
 * @param values User values
 * @param formikHelpers Formik state helpers.
 */
export const createUser = (
  values: UserPayload,
  formikHelpers: FormikHelpers<User>,
  navigate: NavigateFunction
): void => {
  standardClient({
    url: `${API.USER_REGISTRATION}`,
    method: "POST",
    data: { ...values },
  })
    .then(() => {
      navigate(`${FE_ROUTES.UTENTI}`, {
        replace: true,
      });
      toast.success("Utente creato correttamente");
    })
    .catch((error: AxiosResponse<StandardResponseError>) => {
      const errors = _.get(error, "data.validation_messages", null);
      if (errors) {
        // Set the errors in the Formik bag.
        _.forOwn(errors, (value, key) => {
          for (const i in value) {
            formikHelpers.setFieldError(key, value[i]);
            break;
          }
        });
      } else {
        toast.error("Errore durante il salvataggio dell'utente");
      }
    })
    .finally(() => {
      formikHelpers.setSubmitting(false);
    });
};

/**
 * Update an existing user.
 * @param values User field values.
 * @param id User id.
 * @param formikHelpers Formik state helpers.
 */
export const updateUser = (
  values: Partial<UserPayload>,
  id: string,
  formikHelpers: FormikHelpers<User>,
  updateValues?: boolean,
  dispatch?: React.Dispatch<UserAction>
): void => {
  standardClient({
    url: `${API.USERS}/${id}`,
    method: "PUT",
    data: { ...values, role: values?.role?.id },
  })
    .then(() => {
      toast.success("Utente salvato correttamente");
      // The response is okay, so back to the response page.
      if (updateValues && dispatch) {
        getUserById(id, dispatch);
      }
    })
    .catch((error: AxiosResponse<StandardResponseError>) => {
      const errors = _.get(error.data, "validation_messages", null);

      if (errors) {
        // Set the errors in the Formik bag.
        _.forOwn(errors, (value, key) => {
          for (const i in value) {
            formikHelpers.setFieldError(key, value[i]);
            break;
          }
        });
      } else {
        toast.error("Errore durante il salvataggio dell'utente");
      }
    })
    .finally(() => {
      if (formikHelpers) formikHelpers.setSubmitting(false);
    });
};

/**
 * Patch an existing user.
 * @param values User field values.
 * @param id User id.
 * @param formikHelpers Formik state helpers.
 */
export const patchUser = (
  values: Partial<User>,
  id: string,
  updateValues?: boolean,
  dispatch?: React.Dispatch<UserAction>
): void => {
  standardClient({
    url: `${API.USERS}/${id}`,
    method: "PATCH",
    data: { ...values },
  })
    .then(() => {
      toast.success("Utente salvato correttamente");
      if (updateValues && dispatch) {
        getUserById(id, dispatch);
      }
    })
    .catch(() => {
      toast.error("Errore durante il salvataggio dell'utente");
    });
};

/**
 * Set paginator for DataGrid.
 * @param response
 * @param dataGridDispatch
 * @returns
 */
const setDataGridPaginator = (
  response: AxiosResponse<PaginateObject<UserObjectResponse>>,
  dataGridDispatch: React.Dispatch<DataGridAction>
) => {
  dataGridDispatch({
    type: DataGridActionConstants.SET_TABLE_PAGE_PAGE_COUNT,
    payload: {
      page_count: response.data.page_count,
    },
  });
  dataGridDispatch({
    type: DataGridActionConstants.SET_TABLE_PAGE_NUMBER,
    payload: {
      pageNumber: response.data.page == 0 ? 1 : response.data.page,
    },
  });
  dataGridDispatch({
    type: DataGridActionConstants.SET_TABLE_PAGE_TOTAL_ITEMS,
    payload: {
      total_items: response.data.total_items,
    },
  });
  return dataGridDispatch;
};

/**
 * Gets roles list.
 * @param dispatch Dispatch to manage callback results.
 */
export const getRolesList = (dispatch: React.Dispatch<UserAction>): void => {
  standardClient({
    url: `${API.ROLES}`,
    method: "GET",
  })
    .then((response: AxiosResponse<PaginateObject<RoleObjectResponse>>) => {
      const roles = response.data._embedded.role.reduce(
        (result: Array<RoleResponse>, role: RoleResponse) => {
          if (role.id) {
            const objectRole = { roleId: role.roleId, id: role.id };
            result.push(objectRole);
          }
          return result;
        },
        []
      );
      dispatch({
        type: UserActionConstants.SET_ROLE_LIST,
        payload: { roles: roles },
      });
    })
    .catch(() => {
      console.log("Errore durante il caricamento dei ruoli");
    });
};

export const userFirstLogin = (
  values: FirstLoginFormValues,
  navigate,
  dispatch: React.Dispatch<FirstLoginAction>
): void => {
  dispatch({
    type: FirstLoginActionConstants.SET_IS_LOADING,
    payload: { isLoading: true },
  });
  standardClient({
    url: `${API.ACTIVATE_USER}`,
    method: "POST",
    data: values,
  })
    .then((response: AxiosResponse<FirstLoginResponse>) => {
      dispatch({
        type: FirstLoginActionConstants.SET_ACTIVATION_STATUS,
        payload: { status: response.data.response },
      });
      navigate(FE_ROUTES.LOGIN);
    })
    .catch(() => {
      dispatch({
        type: FirstLoginActionConstants.SET_IS_LOADING,
        payload: { isLoading: false },
      });
      dispatch({
        type: FirstLoginActionConstants.SET_ACTIVATION_STATUS,
        payload: { status: false },
      });
      toast.error("Errore durante l'attivazione dell'utente");
    });
};

export const getApplicationTenenatList = (
  dispatch: React.Dispatch<UserAction>
): void => {
  standardClient({
    url: `${API.TENANT}`,
    method: "GET",
  })
    .then((response: AxiosResponse<PaginateObject<TenantObjectResponse>>) => {
      const applicationTenantOptions = response.data._embedded.tenant.reduce(
        (
          result: Array<SelectValueTextObject>,
          applicationTenantOption: TenantResponse
        ) => {
          if (applicationTenantOption.id) {
            const objectRole = {
              text: applicationTenantOption.applicationName,
              value: applicationTenantOption.id,
            };
            result.push(objectRole);
          }
          return result;
        },
        []
      );
      dispatch({
        type: UserActionConstants.SET_APPLICATION_TENANT_LIST,
        payload: { applicationTenantOptions },
      });
    })
    .catch(() => {
      console.log("Errore durante il caricamento della lista delle società");
    });
};
