import React from "react";
import { Navigate, useLocation } from "react-router-dom";

// Types
import { CheckRoleProps } from "./types";
import { isThatRole } from "utils/function/acl";
import _ from "lodash";

const CheckRole: React.FC<CheckRoleProps> = ({ children, allowedRoles }) => {
  const location = useLocation();
  const isUserAllowed = _.some(allowedRoles, (role) => isThatRole(role));

  /** If the user does not possess one of the allowed roles,
   * redirect him/her to the landing page
   */
  if (!isUserAllowed) {
    return <Navigate to="/" state={{ from: location }} replace={true} />;
  }

  return children;
};

export default CheckRole;
