import React, { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";

import { LangProviderProps, LangProviderState } from "./types";
import defaultMessages from "../../assets/lang/en.json";
import { DEFAULT_LOCALE } from "utils/global/globalCostants";

/**
 * The LangProvider is meant to inject the application messages based on the current language.
 * In the first versione, the language comes from the browser settings, but it will be replaced
 * from the one saved in the logged user profile.
 *
 * There are several issues to deal on the scalability of the {lang}.json file.
 * Here some ideas:
 * - Add a loader (the website may not be ready until all the assets are loaded)
 * - Render the App component without translation (it takes the default language, that could be loaded statically)
 * @param param0
 * @returns
 */
const LangProvider: React.FC<LangProviderProps> = ({ children }) => {
  const [state, setState] = useState<LangProviderState>({
    language: navigator.language,
  });

  /*
   * Load asynchronously the current language messages from the compiled file.
   * TODO: It could be NOT production-ready since loading external assets in production could lead to some issues.
   */
  const loadLocale = async () => {
    const messageByLanguage = require.context(
      "../../assets/lang/",
      false,
      /\.json$/
    );

    let messages = defaultMessages;

    if (state.language !== DEFAULT_LOCALE) {
      try {
        messages = messageByLanguage(`./${state.language}.json`);
      } catch (err) {}
    }

    setState({
      ...state,
      messages,
    });
  };

  /**
   * This effect is called at the first rendering.
   */
  useEffect(() => {
    loadLocale();
  }, []);

  /*
   * Load the localizated messages every time the navigator language changes.
   * TODO: Bind the user profile too.
   */
  useEffect(() => {
    if (navigator.language !== state.language) {
      setState({
        ...state,
        language: navigator.language,
      });

      loadLocale();
    }
  }, [navigator.language]);

  if (!state.messages) {
    return null;
  }

  return (
    <IntlProvider
      locale={state.language}
      messages={state.messages}
      defaultLocale={DEFAULT_LOCALE}
    >
      {children}
    </IntlProvider>
  );
};

export default LangProvider;
