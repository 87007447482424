import { MenuItem } from "pages/App/types";
import React, { useContext, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Menu, Image, Icon } from "semantic-ui-react";
import {
  LOGO_BIANCO_ALA,
  LOGO_BIANCO_KPMG,
  LOGO_BIANCO_NSA,
  LOGO_NERO_ALA,
  LOGO_NERO_KPMG,
  LOGO_NERO_NSA,
} from "utils/global/tenantConstants";
import { AppContext } from "pages/App";
import { appActionConstants } from "pages/App/reducer/actions";

const Footer: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const footerItems: Array<MenuItem> = [];

  useEffect(() => {
    if (state.mySelf?.tenant) {
      const sponsor: string[] = [];

      switch (state.mySelf.tenant.subdomain) {
        case "bancavalsabbina":
          sponsor.push(LOGO_NERO_KPMG, LOGO_NERO_NSA, LOGO_NERO_ALA);
          break;
        default:
          sponsor.push(LOGO_BIANCO_KPMG, LOGO_BIANCO_NSA, LOGO_BIANCO_ALA);
      }

      if (state.mySelf.tenant.isPrivacyEnable) {
        footerItems.unshift({
          id: "privacy-policy",
          to: "https://www.iubenda.com/privacy-policy/96261823?ifr=true&height=650&newmarkup=yes&an=no",
          rel: "noopener noreferrer",
          title: (
            <div className="ui text" data-toggle="tooltip">
              Privacy policy
            </div>
          ),
        });
      }

      dispatch({
        type: appActionConstants.SET_FOOTER_STATE,
        payload: {
          footerState: {
            iconList: [
              { name: "linkedin", url: state.mySelf.tenant.linkedinUrl },
              { name: "youtube", url: state.mySelf.tenant.youTubeUrl },
              { name: "twitter", url: state.mySelf.tenant.twitterUrl },
            ],
            copyright: {
              client: state.mySelf.tenant.applicationName,
              text: state.mySelf.tenant.footerDescription,
            },
            sponsor: sponsor,
            items: [...footerItems],
          },
        },
      });
    }
  }, [state.mySelf?.tenant]);

  return (
    <div className="footer">
      <div className="footer-policies">
        {state.footerState.items.map((footerItem) => (
          <Menu.Item
            href={footerItem.to}
            target="_blank"
            rel={footerItem.rel}
            key={footerItem.id}
            name={footerItem.id}
          >
            {footerItem.title}
          </Menu.Item>
        ))}
      </div>
      <div className="right">
        <div className="top">
          <div className="powered-by">
            <FormattedMessage
              id="footer.poweredBy"
              defaultMessage="Powered by"
            />
            {state.footerState.sponsor.map((sponsorLogo, key) => (
              <Image src={sponsorLogo} key={key} />
            ))}
          </div>
          <div className="icons">
            {state.footerState.iconList.map((icon, key) =>
              icon.url ? (
                <Menu.Item href={icon.url} target="_blank" key={key}>
                  <Icon name={icon.name} />
                </Menu.Item>
              ) : (
                /* <></> doesn't support key prop and I wanted to suppress the child unique key warning */
                <React.Fragment key={key}></React.Fragment>
              )
            )}
          </div>
        </div>
        <div className="copyrights">
          <FormattedMessage
            id="footer.copyrightsYear"
            defaultMessage="© 2022"
          />
          <strong>
            {" "}
            <FormattedMessage
              id="footer.copyrightsBank"
              defaultMessage={state.footerState.copyright.client}
            />
          </strong>
          <FormattedMessage
            id="footer.copyrightsDetails"
            defaultMessage={state.footerState.copyright.text}
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
