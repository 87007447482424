import React, { useContext } from "react";
import { Grid, Button, Icon } from "semantic-ui-react";
import {
  Form,
  Input,
  Select,
  Field,
  SubmitButton,
} from "formik-semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { Formik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import { TableFilterFormActionConstants } from "./reducer/actions";
import { TableFilterContext } from "layout/StandardLayout";
import { TableFilterFormState } from "./reducer/reducer";

// Types
import { TableFilterFormProps } from "./types";
import { orderByOptions } from "utils/function/announcement";

const TableFilterForm: React.FC<TableFilterFormProps> = ({
  defaultFilters,
  resetPagination,
  searchFieldPlaceHolder,
  additionalOrderByOptions,
  UserTypeFilter,
}) => {
  const { tableFilterDispatch, dataGridState } = useContext(TableFilterContext);
  /**
   * The initial values.
   */
  const initialFormValues: TableFilterFormState = {
    searchText: defaultFilters.searchText,
    orderBy: defaultFilters.orderBy,
    userType: defaultFilters.userType,
  };

  /**
   * @param resetForm
   * custom handleReset to programmatically reset the form
   * and make sure initial form values will always be the default ones
   */
  const handleResetFun = (resetForm) => {
    resetForm({ values: initialFormValues });
    resetPagination();
  };

  const definitiveOrderByOptions = additionalOrderByOptions
    ? _.concat(orderByOptions, additionalOrderByOptions)
    : orderByOptions;

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialFormValues}
        onSubmit={(values) => {
          tableFilterDispatch({
            type: TableFilterFormActionConstants.SET_FILTERS,
            payload: values,
          });
        }}
        validationSchema={Yup.object().shape({
          searchText: Yup.string().nullable(),
        })}
      >
        {({ resetForm, setFieldValue }) => (
          <Form>
            <Grid padded>
              <Grid.Row>
                <Grid.Column>
                  {searchFieldPlaceHolder}
                  <Field name="searchText">
                    {({ field }) => (
                      <Input
                        name="searchText"
                        placeholder="Cerca..."
                        onChange={(event, { value }) => {
                          setFieldValue(field.name, value);
                        }}
                        onKeyPress={(event) => {
                          if (event.charCode === 13) {
                            tableFilterDispatch({
                              type: TableFilterFormActionConstants.SET_SEARCH_TEXT,
                              payload: {
                                searchText: event.target.value,
                              },
                            });
                            event.preventDefault();
                          }
                        }}
                      />
                    )}
                  </Field>
                </Grid.Column>
                <Grid.Column className="orderBy">
                  <FormattedMessage
                    id="userfilter.orderBy"
                    defaultMessage="Ordina per..."
                  />
                  <Select
                    name="orderBy"
                    options={definitiveOrderByOptions}
                    clearable
                    onChange={(event, { value }) => {
                      tableFilterDispatch({
                        type: TableFilterFormActionConstants.SET_ORDER_BY,
                        payload: {
                          orderBy: _.isString(value) ? value : null,
                        },
                      });
                    }}
                  />
                </Grid.Column>
                {UserTypeFilter}
                <Grid.Column className="button-column">
                  <SubmitButton
                    className="button--primary--negative"
                    content={
                      <FormattedMessage
                        id="userfilter.apply"
                        defaultMessage="Applica"
                      />
                    }
                    loading={dataGridState.isLoading}
                  />
                  <Button
                    type="button"
                    className="button--underline button--reset"
                    onClick={() => handleResetFun(resetForm)}
                  >
                    <Icon name="redo" />
                    <div className="button--text">
                      <FormattedMessage
                        id="filterSidebar.reset"
                        defaultMessage="Reset"
                      />
                    </div>
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default TableFilterForm;
