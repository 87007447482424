import { DataGridAction, DataGridActionConstants } from "./actions";
import { PaginationData } from "../types";

export type DataGridState = {
  isLoading: boolean;
  paginationData: PaginationData;
};

export const initialDataGridState: DataGridState = {
  isLoading: false,
  paginationData: {
    page: 1,
    page_count: 0,
    page_size: 5,
    total_items: 0,
  },
};

export function dataGridReducer(
  state: DataGridState,
  action: DataGridAction
): DataGridState {
  const { type } = action;

  switch (type) {
    case DataGridActionConstants.SET_TABLE_PAGE_NUMBER:
      return {
        ...state,
        paginationData: {
          ...state.paginationData,
          page: action.payload.pageNumber,
        },
      };
    case DataGridActionConstants.SET_TABLE_PAGE_TOTAL_ITEMS: {
      return {
        ...state,
        paginationData: {
          ...state.paginationData,
          total_items: action.payload.total_items,
        },
      };
    }
    case DataGridActionConstants.SET_TABLE_PAGE_PAGE_COUNT: {
      return {
        ...state,
        paginationData: {
          ...state.paginationData,
          page_count: action.payload.page_count,
        },
      };
    }
    case DataGridActionConstants.SET_IS_LOADING: {
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    }
    case DataGridActionConstants.RESET_PAGINATION: {
      return {
        ...state,
        paginationData: initialDataGridState.paginationData,
      };
    }
    default:
      return state;
  }
}
