import { Announcement } from "pages/Announcement/types";

export enum AnnouncementManagementActionConstants {
  SET_ANNOUNCEMENT_LIST = "AnnoucementManagement/SET_ANNOUNCEMENT_LIST",
  SET_IS_LOADING = "AnnoucementManagement/SET_IS_LOADING",
}

export enum AnnouncementManagementPageActionConstants {
  VIEW = "view",
  MODIFY = "edit",
  NEW = "new",
}

export type AnnouncementManagementAction =
  | {
      type: AnnouncementManagementActionConstants.SET_ANNOUNCEMENT_LIST;
      payload: { announcementList: Array<Announcement> };
    }
  | {
      type: AnnouncementManagementActionConstants.SET_IS_LOADING;
      payload: { isLoading: boolean };
    };
