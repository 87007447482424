import { Nullable, SelectValueTextObject } from "utils/types";

export type ContactType = {
  firstName: string | undefined;
  lastName: Nullable<string> | undefined;
  email: string | undefined;
  support: string | number;
  message: string;
};

export const supportOptions: Array<SelectValueTextObject> = [
  {
    value: "aiuto_tecnico",
    text: "Aiuto tecnico",
  },
  {
    value: "info_bandi",
    text: "Informazioni sui bandi",
  },
  {
    value: "contatta_amministratore",
    text: "Contattare l'amministratore",
  },
  {
    value: "trovato_errore",
    text: "Ho trovato un errore",
  },
  {
    value: "altro",
    text: "Altro",
  },
];
