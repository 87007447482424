// General application configuration
export const CONFIG = {
  OFFICE_LOGIN_ACTIVE: false,
};

// Routes of application
export const FE_ROUTES = {
  HOMEPAGE: "/homepage",
  LOGIN: "/login",
  REGISTER: "/register",
  FIRST_LOGIN: "/first-login",
  PASSWORD_RECOVERY: "password-recovery",
  MAIN_ROUTE: "/",
  PASSWORD_RESET: "/password-reset",
  CUSTOMER: "/",
  ELENCO_BANDI: "/elenco-bandi",
  UTENTI: "/utenti",
  GESTIONE_BANDI: "/gestione-bandi",
  ARCHIVIO_BANDI: "/archivio-bandi",
  GESTIONE_FILTRI: "/gestione-filtri",
  CONTACT: "/contatti",
  HYBRIDAUTH: "/hybrid-auth",
};

// Define language settings
export const DEFAULT_LOCALE = "it";

// Width and height of topbar and sidebar
export const HEIGHT_TOPBAR = "4rem";
export const WIDTH_SIDEBAR = "16rem";

// Variable constant in local storage definition
export const LOCAL_STORAGE = {
  ACCESS_TOKEN: "accessToken",
  EXPIRES_IN: "expiresIn",
  PROVIDER: "provider",
  REFRESH_TOKEN: "refreshToken",
  SCOPE: "scope",
  TOKEN_TYPE: "tokenType",
  BREADCRUMBS: "breadcrumbs",
};

// Custom event definition
export const CUSTOM_EVENT = {
  LOGOUT: "custom.event.logout",
};

export enum ROLES {
  SUPERVISOR = "ROLE_SUPERVISOR",
  ADMIN = "ROLE_ADMIN",
  BANK_OPERATOR = "ROLE_BANK_OPERATOR",
  BANK_ADMIN = "ROLE_BANK_ADMIN",
  BANK_CLIENT = "ROLE_BANK_CLIENT",
  USER = "ROLE_USER",
  EDITOR = "ROLE_EDITOR",
}

export enum ANNOUNCEMENT_STATUS {
  APERTO = "aperto",
  CHIUSO = "chiuso",
  IN_PROGRAMMAZIONE = "in_programmazione",
}
