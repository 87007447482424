import React, { useContext } from "react";
import { Field, Form, SubmitButton } from "formik-semantic-ui-react";
import { ErrorMessage, Formik } from "formik";
import { Grid } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import * as Yup from "yup";
import { PasswordInputFormik } from "components/PasswordInputFormik";
import { AppContext } from "pages/App";
/** Types **/
import { FirstLoginFormProps } from "./types";

const FirstLoginForm: React.FC<FirstLoginFormProps> = ({ onSubmit }) => {
  const { state } = useContext(AppContext);
  return (
    <Formik
      initialValues={{
        token: null,
        plainPassword: "",
        confirmPlainPassword: "",
      }}
      onSubmit={(value) => onSubmit(value)}
      validationSchema={Yup.object().shape({
        plainPassword: Yup.string()
          .required("Password è un campo richiesto")
          .nullable()
          .min(8, "La password deve essere almeno di 8 caratteri")
          .matches(
            /[\'^£$%&*()}{@#~?!><>,|=_+-]/,
            "La password deve contenere almeno un carattere speciale"
          )
          .matches(/\d/, "La password deve contenere almeno un numero")
          .matches(
            /[a-z]/,
            "La password deve contenere almeno una lettera minuscola"
          )
          .matches(
            /[A-Z]/,
            "La password deve contenere almeno una lettera maiuscola"
          ),
        confirmPlainPassword: Yup.string()
          .required("Conferma password è un campo richiesto")
          .nullable()
          .min(8, "Conferma password deve essere almeno di 8 caratteri")
          .oneOf(
            [Yup.ref("plainPassword"), null],
            "Entrambe le password devono combaciare"
          ),
      })}
    >
      {({ setFieldValue, handleBlur }) => (
        <Form>
          <Grid className="first-login-form" autocomplete="off">
            <Grid.Row columns={2}>
              <Grid.Column className="register-column-password">
                <Field name="plainPassword">
                  {({ field }) => (
                    <PasswordInputFormik
                      placeholder={"Password*"}
                      label="Password*"
                      type="password"
                      setFieldValue={setFieldValue}
                      field={field}
                      onBlur={handleBlur}
                    />
                  )}
                </Field>
                <ErrorMessage name="plainPassword" />
              </Grid.Column>
              <Grid.Column className="register-column-confirm-password">
                <Field name="confirmPlainPassword">
                  {({ field }) => (
                    <PasswordInputFormik
                      placeholder={"Conferma password*"}
                      label="Conferma password*"
                      type="password"
                      setFieldValue={setFieldValue}
                      field={field}
                      onBlur={handleBlur}
                    />
                  )}
                </Field>
                <ErrorMessage name="confirmPlainPassword" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <SubmitButton
                  className="login--button"
                  content={
                    <FormattedMessage
                      id="login.activate"
                      defaultMessage="Attiva"
                    />
                  }
                  loading={state.isUserLoading}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default FirstLoginForm;
