import { AxiosResponse } from "axios";
import React from "react";
import { toast } from "react-toastify";
import standardClient from "services/client/standardRequestClient";
import { API } from "utils/global/backendRoutes";
import { PaginateObject } from "utils/types/paginateObject";
import _, { forEach } from "lodash";
import {
  FilterEditAction,
  FilterEditActionConstants,
} from "pages/FilterEdit/reducer/actions";
import { FilterTooltipResponseObj } from "./type";
import {
  FilterTooltipType,
  TooltipFormValuesType,
} from "components/TooltipEdit/types";
import { FormikHelpers } from "formik";
import {
  FilterAnnouncementAction,
  FilterAnnouncementActionConstants,
} from "components/Forms/FilterAnnouncementForm/reducer/actions";

export const getFilterTooltips = (
  filterReference?: string,
  filterEditDispatch?: React.Dispatch<FilterEditAction>,
  filterAnnouncementFormDispatch?: React.Dispatch<FilterAnnouncementAction>
): Promise<void> => {
  return standardClient({
    url: `${API.FILTER_TOOLTIPS}`,
    method: "GET",
  }).then(
    ({ data }: AxiosResponse<PaginateObject<FilterTooltipResponseObj>>) => {
      const tooltips = data._embedded.filter_tooltips;
      if (filterReference) {
        const tooltipFound = _.find(tooltips, [
          "filterReference",
          filterReference,
        ]);
        if (tooltipFound && filterEditDispatch)
          filterEditDispatch({
            type: FilterEditActionConstants.SET_FILTER_TOOLTIP,
            payload: { tooltip: tooltipFound },
          });
      }
      if (filterAnnouncementFormDispatch) {
        const tooltipObj = {};
        forEach(tooltips, (tooltip) => {
          if (tooltip.filterReference) {
            const key = _.join(
              _.map(tooltip.filterReference.split("-"), (t, index) => {
                if (index > 0) return _.upperFirst(t);
                return t;
              }),
              ""
            );
            tooltipObj[key] = tooltip.content;
          }
        });
        filterAnnouncementFormDispatch({
          type: FilterAnnouncementActionConstants.SET_FILTER_TOOLTIPS,
          payload: { tooltips: tooltipObj },
        });
      }
    }
  );
};

export const updateFilterTooltip = (
  payload: FilterTooltipType,
  formikHelpers: FormikHelpers<TooltipFormValuesType>,
  filterEditDispatch: React.Dispatch<FilterEditAction>
): Promise<void> => {
  const { id, content } = payload;
  return standardClient({
    url: `${API.FILTER_TOOLTIPS}/${id}`,
    method: "PUT",
    data: {
      content,
    },
  })
    .then(({ data }: AxiosResponse<FilterTooltipType>) => {
      toast.success("Tooltip aggiornato.");
      filterEditDispatch({
        type: FilterEditActionConstants.SET_FILTER_TOOLTIP,
        payload: { tooltip: data },
      });
    })
    .catch(() => {
      toast.error("Errore durante l'aggiornamento del tooltip.");
    })
    .finally(() => {
      formikHelpers.setSubmitting(false);
      filterEditDispatch({
        type: FilterEditActionConstants.SET_MODAL_OPEN,
        payload: { modalOpen: false },
      });
    });
};

export const createFilterTooltip = (
  payload: FilterTooltipType,
  formikHelpers: FormikHelpers<TooltipFormValuesType>,
  filterEditDispatch: React.Dispatch<FilterEditAction>
): Promise<void> => {
  const { content, filterReference } = payload;
  return standardClient({
    url: `${API.FILTER_TOOLTIPS}`,
    method: "POST",
    data: {
      content,
      filterReference,
    },
  })
    .then(({ data }: AxiosResponse<FilterTooltipType>) => {
      toast.success("Tooltip creato.");
      filterEditDispatch({
        type: FilterEditActionConstants.SET_FILTER_TOOLTIP,
        payload: { tooltip: data },
      });
    })
    .catch(() => {
      toast.error("Errore durante la creazione del tooltip.");
    })
    .finally(() => {
      formikHelpers.setSubmitting(false);
      filterEditDispatch({
        type: FilterEditActionConstants.SET_MODAL_OPEN,
        payload: { modalOpen: false },
      });
    });
};

export const deleteFilterTooltip = (
  id: string,
  filterEditDispatch: React.Dispatch<FilterEditAction>
): Promise<void> => {
  filterEditDispatch({
    type: FilterEditActionConstants.SET_TOOLTIP_LOADING,
    payload: { tooltipLoading: true },
  });
  return standardClient({
    url: `${API.FILTER_TOOLTIPS}/${id}`,
    method: "DELETE",
  })
    .then(() => {
      toast.success("Tooltip eliminato.");
    })
    .catch(() => {
      toast.error("Errore durante l'eliminazione del tooltip.");
    })
    .finally(() => {
      const emptyTooltip: FilterTooltipType = {
        content: null,
        id: null,
        filterReference: null,
      };
      filterEditDispatch({
        type: FilterEditActionConstants.SET_FILTER_TOOLTIP,
        payload: { tooltip: emptyTooltip },
      });
      filterEditDispatch({
        type: FilterEditActionConstants.SET_TOOLTIP_LOADING,
        payload: { tooltipLoading: false },
      });
      filterEditDispatch({
        type: FilterEditActionConstants.SET_MODAL_OPEN,
        payload: { modalOpen: false },
      });
    });
};
